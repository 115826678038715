import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SurveyImageTestState {
  isImageTest: { [key: string]: boolean };
}

export const defaultSurveyImageTestState: SurveyImageTestState = {
  isImageTest: {},
};

export const surveyImageTestSlice = createSlice({
  initialState: defaultSurveyImageTestState,
  name: 'surveyImageTestState',
  reducers: {
    clearSurveyImageTestState() {
      return defaultSurveyImageTestState;
    },
    setSurveyImageTest(state, { payload: { surveyId, isImageTest } }: PayloadAction<{ surveyId: string, isImageTest: boolean }>) {
      if (!state.isImageTest) {
        state.isImageTest = {};
      }

      state.isImageTest[surveyId] = isImageTest;
    },
  },
});

export const {
  clearSurveyImageTestState,
  setSurveyImageTest,
} = surveyImageTestSlice.actions;

export default surveyImageTestSlice.reducer;
