import {invisiblyApi} from "../api/invisibly-api";
import {Dma, IDmaJSON} from "../../services/questionnaire/questionnaire.types";
import {InvisiblyApiResponse} from "../../services/api/api-response.types";
import QuestionnaireService from "../../services/questionnaire/questionnaire.service";

const questionnaireEndpoints = invisiblyApi.injectEndpoints({
  endpoints: (builder) => ({
    getDMAList: builder.query<Dma[], void>({
      query: () => ({
        method: 'get',
        url: 'questionnaire/dma',
      }),
      transformResponse(response: InvisiblyApiResponse<IDmaJSON[]>) {
        return QuestionnaireService.parseDmaList(response.item);
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetDMAListQuery } = questionnaireEndpoints;
export { questionnaireEndpoints };
