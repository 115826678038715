import { createSlice } from '@reduxjs/toolkit';
import {
  initAppleSignIn, initEmailSignUp, initGoogleSignIn, initEmailSignIn, userForgotPassword,
} from './login.async-thunks';
import { defaultLoginState } from './login.initial-state';

export const loginSlice = createSlice({
  initialState: defaultLoginState,
  name: 'login',
  reducers: {
    resetForgotPasswordRequest(state) {
      state.forgotPasswordError = '';
      state.forgotPasswordLoading = false;
      state.forgotPasswordSuccess = false;
    },
    resetNewLogInState(state) {
      state.forgotPasswordError = '';
      state.forgotPasswordLoading = false;
      state.forgotPasswordSuccess = false;
      state.userSignUpError = '';
      state.userSignUpLoading = false;
      state.userSignUpSuccess = false;
    },
    resetSignInErrorAction(state) {
      state.userSignUpError = '';
    },
  },
  // eslint-disable-next-line sort-keys
  extraReducers: (builder) => {
    //apple-signin
    builder.addCase(initAppleSignIn.pending, (state) => {
      state.userSignUpLoading = true;
      state.userSignUpSuccess = false;
    });
    builder.addCase(initAppleSignIn.fulfilled, (state, action) => {
      state.userSignUpLoading = false;
      state.userSignUpSuccess = true;
    });
    builder.addCase(initAppleSignIn.rejected, (state) => {
      state.userSignUpLoading = false;
    });
    //google-signin
    builder.addCase(initGoogleSignIn.pending, (state) => {
      state.userSignUpLoading = true;
    });
    builder.addCase(initGoogleSignIn.fulfilled, (state, action) => {
      state.userSignUpLoading = false;
    });
    builder.addCase(initGoogleSignIn.rejected, (state, action) => {
      if (action.payload) {
        state.userSignUpError = action.payload;
      }

      state.userSignUpLoading = false;
      state.userSignUpSuccess = false;
    });
    //sign-in
    builder.addCase(initEmailSignIn.pending, (state) => {
      state.userSignUpError = '';
      state.userSignUpLoading = true;
    });
    builder.addCase(initEmailSignIn.fulfilled, (state) => {
      state.userSignUpLoading = false;
      state.userSignUpSuccess = true;
    });
    builder.addCase(initEmailSignIn.rejected, (state, action) => {
      if (action.payload) {
        state.userSignUpError = action.payload;
      }

      state.userSignUpLoading = false;
    });
    //sign-up
    builder.addCase(initEmailSignUp.pending, (state) => {
      state.userSignUpLoading = true;
      state.userSignUpSuccess = false;
    });
    builder.addCase(initEmailSignUp.fulfilled, (state) => {
      state.userSignUpLoading = false;
      state.userSignUpSuccess = true;
    });
    builder.addCase(initEmailSignUp.rejected, (state) => {
      state.userSignUpLoading = false;
      state.userSignUpSuccess = false;
    });
    // forgetPassword
    builder.addCase(userForgotPassword.pending, (state) => {
      state.forgotPasswordError = '';
      state.forgotPasswordLoading = true;
      state.forgotPasswordSuccess = false;
    });
    builder.addCase(userForgotPassword.fulfilled, (state) => {
      state.forgotPasswordError = '';
      state.forgotPasswordLoading = false;
      state.forgotPasswordSuccess = true;
    });
    builder.addCase(userForgotPassword.rejected, (state, action) => {
      state.forgotPasswordError = action.payload as string;
      state.forgotPasswordLoading = false;
      state.forgotPasswordSuccess = false;
    });
  },
});

export const {
  resetForgotPasswordRequest,
  resetNewLogInState,
  resetSignInErrorAction,
} = loginSlice.actions;

export default loginSlice.reducer;
