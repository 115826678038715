import {
  AnswerSource,
  AnswerStats,
  SurveyAudienceCriteria,
  SurveyAudienceCriteriaKey, SurveyQuestionType,
} from "../../redux/survey/survey.types";
import {ReactElement, useMemo} from "react";
import DemographicsTile from "./demographics-tile.component";
import ExpandableTitle from "./expandable-title";
import {CombinedQuestionStats} from "../../pages/view-survey/view-survey.types";

export interface DemographicsListProps {
  readonly question: CombinedQuestionStats;
  readonly answers: AnswerStats[];
  readonly criteria?: SurveyAudienceCriteria;
  readonly sourceFilter: AnswerSource;
}

export interface DemographicsSection {
  readonly title: SurveyAudienceCriteriaKey | 'Location';
  readonly values: string[];
}

const DemographicsList = ({ answers, criteria, question, sourceFilter }: DemographicsListProps): ReactElement => {
  const { activeCriteria, isFreeform } = useMemo(() => {
    let activeC: DemographicsSection[] = [];
    const freeform = question.questionType === SurveyQuestionType.Freeform;
    if (!freeform && criteria) {
      activeC = Object.entries(criteria).map(([key, value]) => {
        return { title: key as SurveyAudienceCriteriaKey, values: value as string[] };
      }).filter((c) => c.values.length > 0);
    }

    return {
      activeCriteria: activeC,
      isFreeform: freeform,
    };
  }, [answers, criteria, question]);

  const renderList = (): ReactElement => {
    return (
      <ul className="px-4 pb-4">
        {activeCriteria.map((crit) => (
          <DemographicsTile
            key={`${question.title} - ${crit.title}`}
            criteria={crit}
            question={question}
            answers={answers}
            sourceFilter={sourceFilter}
          />
        ))}
      </ul>
    );
  };

  const renderEmptyState = (): ReactElement => {
    const emptyText = isFreeform ? 'Demographic breakdown not available for freeform responses.' : 'Select a filter on the right to see demographics.';
    return (
      <div className="flex flex-row justify-center px-4 pb-4 text-gray-500 text-sm" >
        {emptyText}
      </div>
    );
  };

  return (
    <ExpandableTitle
      initialIsExpanded={criteria !== undefined}
      title='Demographics'
    >
      {activeCriteria.length ? renderList() : renderEmptyState()}
    </ExpandableTitle>
  );
};

export default DemographicsList;
