import { useGenerateAiSurveyMutation } from "../redux/ai/ai.endpoints";
import { useGetSurveyQuery } from "../redux/survey/survey.endpoints";
import { useEffect, useState } from "react";
import { SurveyStatus } from "../redux/survey/survey.types";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AnalyticEventsEnum } from "../services/analytics/analytic-event-enum";
import AnalyticsManager from "../services/analytics/analytics.service";
import { GenerateSurveyFormValues } from "../pages/generate-survey/generate-survey.types";
import { showNotification } from "../redux/notification/notification.slice";
import { asyncTimeout } from "../helpers/async-timeout";

const SUMMARY_GENERATION_INTERVAL = 5000;
const MANUAL_TIMEOUT_LENGTH = 45000;
const useGenerateSurvey = () => {
  const [generateAiSurvey, { data: generatedSurvey, isError: isGenerationError }] = useGenerateAiSurveyMutation();
  const generatedId = generatedSurvey?.extSurveyId ?? '';
  const { data: fetchedSurvey } = useGetSurveyQuery({ ext_survey_id: generatedId }, { skip: !generatedId, pollingInterval: SUMMARY_GENERATION_INTERVAL });
  const [isGenerating, setIsGenerating] = useState(false);
  const [checkTimeout, setCheckTimeout] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    isGenerationError && setIsGenerating(false);
  }, [isGenerationError]);

  useEffect(() => {
    if (!checkTimeout || !isGenerating) {
      return;
    }

    dispatch(showNotification({ theme: 'warning', summary: 'Something went wrong. Please try again.' }));
    setIsGenerating(false);
    setCheckTimeout(false);
  }, [checkTimeout]);

  useEffect(() => {
    fetchedSurvey?.surveyStatus === SurveyStatus.Draft && navigate(`/create-survey?ext-survey-id=${fetchedSurvey.extSurveyId}&generated=true`);
  }, [fetchedSurvey]);

  // eslint-disable-next-line @typescript-eslint/space-before-function-paren
  const createSurvey = async (data: GenerateSurveyFormValues) => {
    AnalyticsManager.trackEvent(AnalyticEventsEnum.RequestGenerateSurvey);
    setIsGenerating(true);
    void generateAiSurvey(data);

    await asyncTimeout(MANUAL_TIMEOUT_LENGTH);
    setCheckTimeout(true);
  };

  return { generateSurvey: createSurvey, isGenerating };
};

export default useGenerateSurvey;

