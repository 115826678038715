import { ReactElement } from "react";
import { ReactComponent as CreatePencil } from "../../../assets/icons/create-pencil.svg";
import { ReactComponent as Loading } from "../../../assets/icons/loading.svg";
import { isQA } from "../../../helpers/env.helpers";
import { useSurveyMediaPlan } from "../survey-action-plan/useSurveyMediaPlan";
import { SurveyStatus } from "../../../redux/survey/survey.types";

export interface CreateMediaPlanButtonProps {
  readonly extSurveyId: string;
  readonly surveyStatus?: SurveyStatus;
}

const CreateMediaPlanButton = ({ extSurveyId, surveyStatus }: CreateMediaPlanButtonProps): ReactElement | null => {
  const { canCreatePlan, createMediaPlan, isLoadingPlan } = useSurveyMediaPlan(extSurveyId, surveyStatus);

  if (!isQA() || !canCreatePlan) {
    return null;
  }

  const handleCreation = (): void => {
    if (isLoadingPlan) {
      return;
    }

    void createMediaPlan();
  };

  return (
    <button
      className="sm-max:hidden mr-2 gap-2 flex items-center rounded-3xl bg-white lg-max:p-2 px-6 py-2 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      onClick={handleCreation}
    >
      {isLoadingPlan ? <Loading /> : <CreatePencil fill="#191818" />}
      {/* <img */}
      {/*   className='mr-2 lg-max:m-0' */}
      {/*   src={isLoadingPlan ? loading : createPencil} */}
      {/*   alt='share survey icon' */}
      {/*   draggable={false} */}
      {/* /> */}
      <span className="lg-max:hidden">
        Media plan
      </span>
    </button>
  );
};

export default CreateMediaPlanButton;
