const v4Pattern = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
/*
* Removes v4 uuids from urls
*/
export function removeUUIDsFromUrl(url: string): string {
  return url
    .split('/')
    .map((part) => {
      if (v4Pattern.test(part)) {
        return 'uuid';
      }
      return part;
    })
    .join('/');
}

export function functionLogger<TReturnType>(originalFunction: (...args: any[]) => TReturnType, defaultReturn?: any): (...args: any[]) => TReturnType | string {
  return function(...args: any[]): TReturnType {
    let parameters: IArguments;

    try {
      // eslint-disable-next-line prefer-rest-params
      parameters = arguments;
      // @ts-expect-error - expected override
      return originalFunction.apply(this, arguments);
    } catch (exception) {
      // Sentry.withScope((scope) => {
      //   scope.setExtras({
      //     methods: originalFunction.name,
      //     extraData: {
      //       parameters,
      //     },
      //   });
      //   Sentry.captureException(exception);
      // });
      // @ts-expect-error - expected override
      return defaultReturn && exception?.message;
    }
  };
}

//  Example
// function originalMethod(name: string): string {
//     console.log(name);
//
//     return name;
// }
//
// const decoratedFoo: (name: string) => string = TryCatchLogger<string>(originalMethod);
// decoratedFoo('bob');

// export function asyncFunctionLogger<TReturnType>(originalFunction: (...args: any[]) => Promise<TReturnType>, defaultReturn?: any): (...args: any[]) => any | string {
//   return async function(...args: any[]): Promise<TReturnType> {
//     const modifiedMethod = async() => {
//       let parameters: IArguments;
//       let result: () => void;
//
//       try {
//         parameters = args;
//         result = await originalFunction.apply(this, args);
//       } catch (exception) {
//         // Sentry.withScope((scope) => {
//         //   scope.setTag('method', originalFunction.name);
//         //   scope.setExtras({
//         //     extraData: {
//         //       parameters,
//         //     },
//         //   });
//         //   Sentry.captureException(exception);
//         // });
//         return defaultReturn && exception?.message;
//       }
//
//       return result;
//     };
//
//     return modifiedMethod();
//   };
// }
