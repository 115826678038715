import { Audience, AudienceJSON } from "../../../redux/admin/audience/audience.types";


interface IAudienceParsingService {
  readonly parseListAudience: (json: AudienceJSON) => Audience
}

class AudienceParsingService implements IAudienceParsingService {
  static instance: AudienceParsingService;

  public static getInstance(): IAudienceParsingService {
    if (!AudienceParsingService.instance) {
      AudienceParsingService.instance = new AudienceParsingService();
    }

    return AudienceParsingService.instance;
  }

  parseListAudience({ id, created_at, total_users, criteria, created_by, title }: AudienceJSON): Audience {
    return {
      id,
      createdAt: created_at,
      totalUsers: total_users,
      criteria,
      createdBy: created_by,
      title,
    };
  }
}

export default AudienceParsingService.getInstance();
