import { InputText } from "primereact/inputtext";
import { MultiSelect } from 'primereact/multiselect';
import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { ApiStatus } from '../../services/api/api-response.types';
import { Dialog } from 'primereact/dialog';
import { SurveyAudienceCriteria } from "../../redux/admin/audience/audience.types";
import { useCreateAdminSurveyAudienceMutation } from "../../redux/admin/audience/audience.endpoints";
import { Panel } from "primereact/panel";
import {useSetAdminPageTitle} from "../../hooks/useSetPageTitle";


function AudienceCreateScreen() {
  useSetAdminPageTitle('Create audience');
  const [selectedPolitical, setSelectedPolitical] = useState([]);
  const political = [
    { name: 'Democrat' },
    { name: 'Republican' },
    { name: 'Independent' },
    { name: 'Other' },
  ];

  const [selectedRegistered, setSelectedRegistered] = useState([]);
  const yesNo = [
    { name: 'Yes' },
    { name: 'No' },
  ];

  const [selectedGender, setSelectedGender] = useState([]);
  const gender = [
    { name: 'Male' },
    { name: 'Female' },
    { name: 'Non-Binary' },
    { name: 'Other' },
    { name: 'Prefer not to say' },
  ];
  const [selectedEducation, setSelectedEducation] = useState([]);
  const education = [
    { name: 'High school diploma' },
    { name: 'Some college no degree' },
    { name: 'Associate\'s degree' },
    { name: 'Bachelor\'s degree' },
    { name: 'Master\'s degree' },
    { name: 'Doctoral degree' },
  ];
  const [selectedEmployment, setSelectedEmployment] = useState([]);
  const employment = [
    { name: 'Employed full time' },
    { name: 'Employed part-time' },
    { name: 'Self employed' },
    { name: 'Unemployed' },
    { name: 'Retired' },
    { name: 'Student' },
    { name: 'Stay at home parent' },
  ];
  const [selectedIncome, setSelectedIncome] = useState([]);
  const income = [
    { name: 'Less than $25,000' },
    { name: '$25,000-$49,999' },
    { name: '$50,000-$74,999' },
    { name: '$75,000-$99,999' },
    { name: '$100,000-$150,000' },
    { name: '$150,000-$200,000' },
    { name: '$200,000 - $299,000' },
    { name: '$300,000 or more' },
  ];

  const [selectedChildren, setSelectedChildren] = useState([]);
  const children = [
    { name: 'None' },
    { name: 'One' },
    { name: 'Two' },
    { name: 'Three' },
    { name: 'Four or more' },
  ];

  const [selectedPets, setSelectedPets] = useState([]);
  const pets = [
    { name: 'No' },
    { name: 'Dog' },
    { name: 'Cat' },
    { name: 'Fish' },
    { name: 'Bird' },
    { name: 'Reptile' },
    { name: 'Other' },
  ];

  const [selectedEthnicity, setSelectedEthnicity] = useState([]);
  const ethnicity = [
    { name: 'White' },
    { name: 'Black or African American' },
    { name: 'Asian or South-Asian' },
    { name: 'Arab' },
    { name: 'Multiracial' },
    { name: 'Native American/American Indian' },
    { name: 'Hispanic or Latino' },
    { name: 'Other' },
  ];

  const [selectedMarital, setSelectedMarital] = useState([]);
  const marital = [
    { name: 'Single' },
    { name: 'Married' },
    { name: 'Divorced' },
    { name: 'Domestic partner' },
    { name: 'Widowed' },
  ];
  const [selectedOrigin, setSelectedOrigin] = useState([]);
  const origin = [
    { name: 'United States' },
    { name: 'Canada' },
    { name: 'Mexico' },
    { name: 'India' },
    { name: 'China' },
    { name: 'Europe' },
    { name: 'Other' },
  ];
  const [selectedAge, setSelectedAge] = useState([]);
  const age = [
    { name: '18-24' },
    { name: '25-34' },
    { name: '35-44' },
    { name: '45-54' },
    { name: '55-64' },
    { name: '65+' },
  ];

  const [title, setTitle] = useState('');
  const [createSurveyAudience, { isSuccess, error }] = useCreateAdminSurveyAudienceMutation();
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (isSuccess) {
      setDialogTitle('Success');
      setDialogMessage('Survey Audience Created');
      setVisible(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      setDialogTitle('Error');
      setDialogMessage((error as ApiStatus).status.status_message);
      setVisible(true);
    }
  }, [error]);

  function createAudience(): void {
    const data: SurveyAudienceCriteria = {
      'Education': selectedEducation.map((x) => x['name']),
      'Employment': selectedEmployment.map((x) => x['name']),
      'Household income': selectedIncome.map((x) => x['name']),
      'Marital status': selectedMarital.map((x) => x['name']),
      'Children': selectedChildren.map((x) => x['name']),
      'Pets': selectedPets.map((x) => x['name']),
      'Ethnicity': selectedEthnicity.map((x) => x['name']),
      'Place of origin': selectedOrigin.map((x) => x['name']),
      'Political affiliation': selectedPolitical.map((x) => x['name']),
      'Registered to vote': selectedRegistered.map((x) => x['name']),
      'Gender': selectedGender.map((x) => x['name']),
      'Age Group': selectedAge.map((x)=> x['name']),
    };
    Object.keys(data).forEach(key => {
      if (data[key as keyof SurveyAudienceCriteria].length === 0) {
        delete data[key as keyof SurveyAudienceCriteria];
      }
    });
    void createSurveyAudience({ criteria: data, title: title });
  }


  return (
    <div>
      <div className="justify-content-center flex grid flex-col flex-wrap w-1/2 mx-auto">
        <div className="col p-8">
          <Panel
            header="Create Audience"
            className='rounded-lg drop-shadow-lg content-center'
          >
            <div className="p-4">
              <InputText
                id="name"
                className="border-2 rounded-md p-2 w-full"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              {/* <label htmlFor="name">Title</label> */}
            </div>

            <div className="col-1 p-4">
              <MultiSelect
                placeholder="Political Party"
                multiple
                selectAllLabel="All"
                value={selectedPolitical}
                onChange={(e) => setSelectedPolitical(e.value)}
                options={political}
                optionLabel="name"
                className="md:w-14rem w-full border-b-2 rounded-none"
              />
              <MultiSelect
                placeholder="Registered to Vote"
                multiple
                selectAllLabel="All"
                value={selectedRegistered}
                onChange={(e) => setSelectedRegistered(e.value)}
                options={yesNo}
                optionLabel="name"
                className="md:w-14rem w-full border-b-2 rounded-none"
              />
              <MultiSelect
                placeholder="Gender"
                multiple
                selectAllLabel="All"
                value={selectedGender}
                onChange={(e) => setSelectedGender(e.value)}
                options={gender}
                optionLabel="name"
                className="md:w-14rem w-full border-b-2 rounded-none"
              />
              <MultiSelect
                placeholder="Education"
                multiple
                selectAllLabel="All"
                value={selectedEducation}
                onChange={(e) => setSelectedEducation(e.value)}
                options={education}
                optionLabel="name"
                className="md:w-14rem w-full border-b-2 rounded-none"
              />
              <MultiSelect
                placeholder="Employment"
                multiple
                selectAllLabel="All"
                value={selectedEmployment}
                onChange={(e) => setSelectedEmployment(e.value)}
                options={employment}
                optionLabel="name"
                className="md:w-14rem w-full border-b-2 rounded-none"
              />
              <MultiSelect
                placeholder="Income"
                multiple
                selectAllLabel="All"
                value={selectedIncome}
                onChange={(e) => setSelectedIncome(e.value)}
                options={income}
                optionLabel="name"
                className="md:w-14rem w-full border-b-2 rounded-none"
              />
              <MultiSelect
                placeholder="Children"
                multiple
                selectAllLabel="All"
                value={selectedChildren}
                onChange={(e) => setSelectedChildren(e.value)}
                options={children}
                optionLabel="name"
                className="md:w-14rem w-full border-b-2 rounded-none"
              />
              <MultiSelect
                placeholder="Pets"
                multiple
                selectAllLabel="All"
                value={selectedPets}
                onChange={(e) => setSelectedPets(e.value)}
                options={pets}
                optionLabel="name"
                className="md:w-14rem w-full border-b-2 rounded-none"
              />
              <MultiSelect
                placeholder="Ethnicity"
                multiple
                selectAllLabel="All"
                value={selectedEthnicity}
                onChange={(e) => setSelectedEthnicity(e.value)}
                options={ethnicity}
                optionLabel="name"
                className="md:w-14rem w-full border-b-2 rounded-none"
              />
              <MultiSelect
                placeholder="Marital"
                multiple
                selectAllLabel="All"
                value={selectedMarital}
                onChange={(e) => setSelectedMarital(e.value)}
                options={marital}
                optionLabel="name"
                className="md:w-14rem w-full border-b-2 rounded-none"
              />
              <MultiSelect
                placeholder="Origin"
                multiple
                selectAllLabel="All"
                value={selectedOrigin}
                onChange={(e) => setSelectedOrigin(e.value)}
                options={origin}
                optionLabel="name"
                className="md:w-14rem w-full border-b-2 rounded-none"
              />
              <MultiSelect
                placeholder="Age"
                multiple
                selectAllLabel="All"
                value={selectedAge}
                onChange={(e) => setSelectedAge(e.value)}
                options={age}
                optionLabel="name"
                className="w-full mb-8 md:w-14rem"
              />
              <Button
                label='Create'
                className='border-2 p-2 w-full bg-blue-400 text-white rounded-md'
                onClick={createAudience}
              />
            </div>
          </Panel>
        </div>

      </div>
      <Dialog
        header={dialogTitle}
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
      >
        <p>
          {dialogMessage}
        </p>
      </Dialog>
    </div>
  );
}

export default AudienceCreateScreen;
