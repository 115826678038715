import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/redux-store';
import { removeNotification } from '../../redux/notification/notification.slice';
import { Button } from 'primereact/button';
import { IMessage } from "../../redux/notification/notification.types";
import { ReactComponent as SuccessCheckIcon } from '../../assets/icons/success_check.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/error_exclamation_point.svg';
import { ReactComponent as ActionRequiredIcon } from '../../assets/icons/action_required_exclamation_point.svg';
import { ReactComponent as ReviewIcon } from '../../assets/icons/whiteLock.svg';

export const B2BMessage = () => {
  const messages = useSelector((state: RootState) => state.notificationState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (messages) {
      messages.messages.forEach(message => {
        if (!message.sticky) {
          setTimeout(() => {
            dispatch(removeNotification(message));
          }, message.life ? message.life : 3000);
        }
      });
    }
  });

  const hideMessage = (message: IMessage) => {
    dispatch(removeNotification(message));
  };

  const getTheme = (message: IMessage) => {
    switch (message.theme) {
      case 'warning':
        return 'bg-red-500 text-white';
      case 'success':
        return 'bg-green-800 text-white';
      case 'info':
        return 'bg-orange-500 text-white';
      case 'review':
        return 'bg-status-purple text-white';
    }
  };

  const getIcon = (message: IMessage) => {
    switch (message.theme) {
      case 'warning':
        return <WarningIcon />;
      case 'success':
        return <SuccessCheckIcon />;
      case 'review':
        return <ReviewIcon />;
      case 'info':
        return <ActionRequiredIcon />;
    }
  };

  const getTitle = (message: IMessage) => {
    if(message.title) {
      return message.title;
    }
    switch (message.theme) {
      case 'warning':
        return 'Error occurred';
      case 'success':
        return 'Success';
      case 'info':
        return 'Action Required';
    }
  };

  return (
    <div className="fixed inset-x-0 top-0 z-[1105] mt-5 flex flex-col items-center space-y-4">
      {messages.messages.map((msg, index) => (
        <div
          key={index}
          className={`${getTheme(msg)} w-5/6 rounded-3xl p-3 text-center shadow-lg`}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <div className="ml-2 flex items-center justify-center rounded-2xl align-middle">
                { getIcon(msg) }
              </div>
              <div className='flex flex-col'>
                <span className="text-left text-lg font-semibold">
                  {getTitle(msg)}
                </span>
                {msg.summary.split('\n').map((line, index) => (
                  <span
                    key={index}
                    className="text-sm text-left font-extralight"
                  >
                    {line}
                  </span>
                ))}
              </div>
            </div>
            <Button
              icon="pi pi-times"
              className='mr-2 text-2xl font-semibold'
              rounded
              text
              onClick={() => hideMessage(msg)}
            />
          </div>
        </div>
      ))}
    </div>

  );
};


