import { ReactComponent as ChatSmileIcon } from '../../assets/icons/chat-smile.svg';
import { ReactComponent as CreatePencilIcon } from '../../assets/icons/create-pencil.svg';
import { ReactComponent as ImageIcon } from '../../assets/icons/image.svg';

export interface SurveyCreationCardProps {
  readonly title: string;
  readonly description: string;
  readonly icon: JSX.Element;
  readonly onClick: () => void;
}
type SurveyCreationCardPropsWithoutClick = Omit<SurveyCreationCardProps, 'onClick'>;

const iconColor = '#112FB7';
export const AI_GENERATED_PROPS: SurveyCreationCardPropsWithoutClick = {
  title: 'Generate a survey for me',
  description: 'Share context about a problem, solution, or audience and we will help you craft a survey.',
  icon: <ChatSmileIcon stroke={iconColor} />,
};

export const USER_CREATED_PROPS: SurveyCreationCardPropsWithoutClick = {
  title: 'Create a survey',
  description: 'Input your own questions, select an audience, and start gathering insights.',
  icon: <CreatePencilIcon fill={iconColor} />,
};

export const IMAGE_TEST_PROPS: SurveyCreationCardPropsWithoutClick = {
  title: 'Run image tests',
  description: 'Upload product images, screens, and A/B test your images to learn what your customers desire.',
  icon: <ImageIcon className='fill-[#112fb7] opacity-1' />,
};

const SurveyCreationCard = ({ description, icon, onClick, title }: SurveyCreationCardProps) => {
  return (
    <div
      className="flex flex-row p-6 gap-4 rounded-lg bg-custom-blue-25 lg:max-w-[400px] cursor-pointer"
      onClick={onClick}
    >
      <div className="flex flex-col justify-start">
        <div className="size-5 mt-1">
          {icon}
        </div>
      </div>
      <div className="flex flex-col gap-2 text-custom-blue-700">
        <div className="text-lg">
          {title}
        </div>
        <div className="text-sm">
          {description}
        </div>
      </div>
    </div>
  );
};

export default SurveyCreationCard;
