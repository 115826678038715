import {DashboardMenubar} from "../../components/menubar/menubar";
import {useSetPageTitle} from "../../hooks/useSetPageTitle";
import {BackButton} from "../../components/backbutton/backbutton";
import {useNavigate} from "react-router-dom";
import GenerateSurveyForm from "./generate-survey-form";
import {GenerateSurveyFormValues} from "./generate-survey.types";
import useGenerateSurvey from "../../hooks/useGenerateSurvey";
import ValidationStepsModal from "../../components/moderation-dialog/moderation-dialog";
import {GENERATION_INTERVAL, GENERATION_LOADING_STEPS, GENERATION_LOADING_TITLE} from "./generate-survey.constants";

const GenerateSurveyScreen = () => {
  useSetPageTitle('Generate Survey');
  const navigate = useNavigate();
  const { generateSurvey, isGenerating } = useGenerateSurvey();

  const onBackClick = () => {
    navigate('/dashboard');
  };

  const onFormSubmission = (values: GenerateSurveyFormValues) => {
    void generateSurvey(values);
  };

  return (
    <div>
      <DashboardMenubar />
      <div className="flex-column flex justify-center p-0 md:p-6">
        <div className="flex flex-row sm-max:flex-col items-start gap-4">
          <div className="ml-2 md:ml-0">
            <BackButton
              theme="light"
              onClick={onBackClick}
            />
          </div>
          <div
            className="flex flex-col items-center justify-start py-8 pl-4 md:pl-8 pr-10 md:pr-20 rounded-t-2xl md:rounded-2xl bg-white md:shadow-md"
          >
            <div className="text-3xl font-light">
              Generate a survey
            </div>
            <GenerateSurveyForm
              isLoading={false}
              onSubmit={onFormSubmission}
            />
          </div>
        </div>
      </div>
      <ValidationStepsModal
        isModalOpen={isGenerating}
        handleCloseModal={() => {}}
        showFooter={false}
        steps={GENERATION_LOADING_STEPS}
        title={GENERATION_LOADING_TITLE}
        updateInterval={GENERATION_INTERVAL}
        windowSize={6}
      />
    </div>
  );
};

export default GenerateSurveyScreen;
