/* eslint-disable complexity,@typescript-eslint/no-unsafe-argument,@typescript-eslint/ban-ts-comment */
/* eslint-disable max-statements */
/* eslint-disable camelcase */
import authApiService from './auth.api-service';
import { RootState } from '../../redux/redux-store';
import axios from 'axios';
import ErrorLogService from '../error-log/error-log.service';
import ReduxService from '../../redux/redux.service';
import AsyncStorageService, { AsyncStorageKeys } from '../storage/async-storage.service';
import { v4 as uuid } from 'uuid';
import { asyncLogger } from '../error-log/decorators/async-logger.decorator';
import { clearUserState } from '../../redux/user/user.slice';
import { ISignInRequest, ISignUpRequest, ISocialLoginRequest } from './auth.types';
import { User } from '../user/user.types';

import { ServerResponseEnum } from '../api/invisibly-api.constants';
import { fernetEncrypt } from './auth.helpers';

interface IAuthService {
  readonly checkDeviceIdLinked: (deviceId: string) => Promise<boolean>;
  readonly createNewAnonymousUser: (deviceId: string) => Promise<User | null>;
  readonly forgotPassword: (email: string) => Promise<void>;
  readonly initUserAuthInvalidInterceptor: (callBackAction: () => void) => void;
  readonly linkDeviceIdToUser: (userId: string) => Promise<void>;
  readonly resetPassword: (password: string, token: string) => Promise<string | null>;
  readonly signIn: (email: string, password: string) => Promise<User>;
  readonly signInWithGoogle: (idToken: string) => Promise<User>;
  readonly signInWithApple: (idToken: string) => Promise<User>;
  readonly signUp: (email: string, password: string, state: RootState) => Promise<User>;
}

class AuthService implements IAuthService {
  private static instance: AuthService | undefined;
  private static hasInitAuthInvalidInterceptor = false;

  static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }

    return AuthService.instance;
  }

  public initUserAuthInvalidInterceptor(callBackAction: () => void): void {
    if (AuthService.hasInitAuthInvalidInterceptor) {
      return;
    }

    AuthService.hasInitAuthInvalidInterceptor = true;
    axios.interceptors.response.use(
      response => {
        const code = response.data?.status?.status_code ?? '';
        const tokenMissingOrExpired = code === ServerResponseEnum.AUTH_NOT_PROVIDED;
        const tokenPermissionError = code === ServerResponseEnum.TOKEN_PERMISSION_ERROR;
        if (tokenMissingOrExpired || tokenPermissionError) {
          ErrorLogService.logError(new Error('Authentication not provided'), 'app', 'interceptor');
          // TODO: re add and dispatch action instead
          const { userState } = ReduxService.Store.getState();

          if (!userState.anonymousUser && !userState.signOutInProgress) {
            const callbackToken = tokenPermissionError ? '' : userState.jwt;
            this.handleInterceptorRejection(callBackAction, callbackToken);
          }
        }

        return response;
      }
    );
  }

  private handleInterceptorRejection(callBackAction: () => void, token: string): void {
    if (token) {
      callBackAction();
    } else {
      ReduxService.Store.dispatch(clearUserState());
    }
  }

  public async checkDeviceIdLinked(deviceId: string): Promise<boolean> {
    const { isAnonymous, userExists } = await authApiService.checkDeviceTokenAccountExists(deviceId);

    return userExists && !isAnonymous;
  }

  public async createNewAnonymousUser(deviceId: string): Promise<User | null> {
    return await authApiService.createAnonymousUser(deviceId);
  }

  @asyncLogger()
  public async linkDeviceIdToUser(userId: string): Promise<void> {
    // eslint-disable-next-line prefer-const
    let [deviceId, deviceUserIdsLinked] = await AsyncStorageService.localStorage.retrieveMultiData(
      [AsyncStorageKeys.deviceId, AsyncStorageKeys.deviceUserIdLinked]
    );

    if (!deviceId) {
      deviceId = uuid();
      await AsyncStorageService.localStorage.storeData(AsyncStorageKeys.deviceId, deviceId);
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (deviceUserIdsLinked?.includes(userId)) {
      return;
    }

    await authApiService.linkDeviceIdToUserId(deviceId);

    const data = deviceUserIdsLinked ? [...deviceUserIdsLinked, userId] : [userId];

    await AsyncStorageService.localStorage.storeData(AsyncStorageKeys.deviceUserIdLinked, data);
  }

  public async signIn(email: string, password: string): Promise<User> {
    const deviceId = await AsyncStorageService.localStorage.retrieveData<string>(AsyncStorageKeys.deviceId);
    const details: ISignInRequest = {
      device_id: deviceId ? fernetEncrypt(deviceId) : '',
      email,
      password,
      platform: 'web',
      service: 0,
      user_access_type: 'b2b',
    };

    return await authApiService.signIn(details);
  }

  public async signInWithGoogle(idToken: string): Promise<User> {
    const deviceId = await AsyncStorageService.localStorage.retrieveData<string>(AsyncStorageKeys.deviceId);
    // @ts-expect-error not implementing all fields
    const details: ISocialLoginRequest = {
      device_id: deviceId ? fernetEncrypt(deviceId) : '',
      platform: 'web',
      service: 7,
      token: idToken,
      user_access_type: 'b2b',
    };

    return await authApiService.socialLogin(details);
  }

  public async signInWithApple(idToken: string): Promise<User> {
    const deviceId = await AsyncStorageService.localStorage.retrieveData<string>(AsyncStorageKeys.deviceId);
    // @ts-expect-error not implementing all fields
    const details: ISocialLoginRequest = {
      device_id: deviceId ? fernetEncrypt(deviceId) : '',
      platform: 'web',
      service: 3,
      token: idToken,
      user_access_type: 'b2b',
    };
    return await authApiService.socialLogin(details);
  }

  public async signUp(email: string, password: string, state: RootState): Promise<User> {
    const deviceId = await AsyncStorageService.localStorage.retrieveData<string>(AsyncStorageKeys.deviceId);
    const details: ISignUpRequest = {
      consent: true,
      email,
      password,
      platform: 'web',
      plugin_install: 0,
      service: 0,
      user_access_type: 'b2b',
    };

    if (deviceId) {
      const isDeviceLinked = await this.checkDeviceIdLinked(deviceId);

      if (!isDeviceLinked) {
        details.device_id = fernetEncrypt(deviceId);
        details.anonymous_user_signup = state.userState.anonymousUser;
      }
    }

    const userResponse = await authApiService.signUp(details);

    return userResponse;
  }

  public async forgotPassword(email: string): Promise<void> {
    await authApiService.forgotPassword(email);
  }

  public async resetPassword(password: string, token: string): Promise<string | null> {
    return await authApiService.resetPassword(password, token);
  }

  public async signOut(): Promise<void> {
    await authApiService.signOut();
  }
}

export default AuthService.getInstance();
