import React from 'react';
import { useField } from 'formik';

export interface InputProps {
  id: string;
  label?: string;
  name: string;
  type?: string;
  placeholder?: string;
  className?: string;
  field?: any;
  required?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const Input: React.FC<InputProps> = ({ label, ...props }) => {
  const [field, meta] = useField(props.name);

  return (
    <>
      {label && (
        <label
          htmlFor={props.name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <input
        {...field}
        {...props}
        className={`w-full border-b border-gray-300 p-2 focus-visible:outline-0 ${props.className}`}
      />
      {meta.touched && meta.error ? (
        <div className="mt-1 text-sm text-red-500">
          {meta.error}
        </div>
      ) : null}
    </>
  );
};

export default Input;
