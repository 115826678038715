import {OrderList, OrderListChangeEvent} from 'primereact/orderlist';
import React, { useEffect, useRef, useState } from 'react';
import sort from '../../assets/icons/sort.svg';
import drag from '../../assets/icons/drag.svg';
import './question-sort.css';
import { useGetSurveyQuery, useUpdateQuestionOrderMutation } from '../../redux/survey/survey.endpoints';
import { useDispatch } from 'react-redux';
import { setSaveStateWithDelay } from '../../redux/survey/survey-status.utils';
import { showNotification } from '../../redux/notification/notification.slice';
import {Question} from "../../redux/survey/survey.types";
import {isEqual} from "lodash";
import {isQuestionList} from "../../services/surveys/surveys.helpers";

interface QuestionSortProps {
  extSurveyId: string
}

const QuestionSort: React.FC<QuestionSortProps> = ({ extSurveyId }) => {
  const { data } = useGetSurveyQuery(
    { ext_survey_id: extSurveyId },
    { skip: !extSurveyId }
  );
  const [showOrderList, setShowOrderList] = useState<boolean>(false);
  const [updateQuestionOrder] = useUpdateQuestionOrderMutation();
  const [questions, setQuestions] = useState<Question[]>([]);
  const orderListRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.questions && data.questions.length > 0) {
      const orderedQuestions = [...data.questions].sort((a, b) => a.questionOrder - b.questionOrder);
      setQuestions(orderedQuestions);
    } else {
      setQuestions([]);
    }
  }, [data]);

  useEffect(() => {
    if (showOrderList) {
      const questionArray = questions.map((question) => question.id.toString());
      updateQuestionOrder({ questionArray, ext_survey_id: extSurveyId });
      setSaveStateWithDelay(dispatch);
    }
  }, [questions]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (orderListRef.current && !orderListRef.current.contains(event.target as Node)) {
        setShowOrderList(false);
      }
    };

    if (showOrderList) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showOrderList]);

  const handleClick = () => {
    const hasSorting = Boolean(data?.questions.some(q => q.answers.some(a => a.answerBranch !== null || a.isFinalQuestion)));
    if (hasSorting && !showOrderList) {
      dispatch(showNotification({ summary: "Sorting the questions will clear all answer branching within the survey 'Jump to' or 'End Survey'", title: "Warning", theme: "info" }));
    }
    setShowOrderList(!showOrderList);
  };

  const itemTemplate = (item: Question) => {
    return (
      <div className="product-item flex items-center justify-between rounded-lg border border-gray-300 p-2 hover:border-gray-400 hover:bg-gray-100">
        <img
          src={drag}
          alt="Drag"
          className="size-4 mr-2"
          draggable={false}
        />
        <div className="grow overflow-hidden">
          <div className="product-name max-w-28 truncate md:max-w-60">
            {item.title != "Untitled_Question" ? item.title : ""}
          </div>
        </div>
      </div>
    );
  };

  const onListChange = (event: OrderListChangeEvent) => {
    if (!isQuestionList(event.value)) {
      return;
    }

    const previousIds = questions.map(({ id }) => id);
    const updatedIds = event.value.map(({ id }) => id);
    if (isEqual(previousIds, updatedIds)) {
      return;
    }

    updateQuestionOrder({ ext_survey_id: extSurveyId, questionArray: updatedIds });
  };

  return (
    <div className='relative'>
      <div className="relative group">
        <button
          onClick={handleClick}
          className='px-2 py-1 rounded hover:bg-gray-200'
        >
          <img
            src={sort}
            alt="Sort"
            className="size-5"
            draggable={false}
          />
        </button>
        <div className="absolute right-0 bottom-0 translate-x-full translate-y-full hidden group-hover:block bg-gray-200 text-gray-700 text-xs rounded py-1 px-2 whitespace-nowrap">
          Sort questions
        </div>
      </div>
      {showOrderList ? (
        <div
          ref={orderListRef}
          className="absolute right-0 top-8 z-10 rounded-lg border border-gray-300 shadow-xl"
        >
          <OrderList
            dataKey="id"
            value={questions}
            onChange={onListChange}
            itemTemplate={itemTemplate}
            header="Question order"
            dragdrop
          />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default QuestionSort;
