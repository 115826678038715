// surveyStatusUtils.ts
import { SurveyStatus } from '../../redux/survey/survey.types';

export const displaySurveyStatus = {
  [SurveyStatus.Draft]: 'Draft',
  [SurveyStatus.Complete]: 'Complete',
  [SurveyStatus.Live]: 'Live',
  [SurveyStatus.Review]: 'In Review',
  [SurveyStatus.Rejected]: 'Rejected',
  [SurveyStatus.Building]: 'Building',
};

export const computeColor = (surveyStatus: SurveyStatus | string) => {
  switch (surveyStatus) {
    case SurveyStatus.Draft:
      return { bg: 'bg-status-gray', text: 'text-status-gray' };
    case SurveyStatus.Complete:
      return { bg: 'bg-status-green', text: 'text-status-green' };
    case SurveyStatus.Live:
      return { bg: 'bg-status-blue', text: 'text-status-blue' };
    case SurveyStatus.Rejected:
      return { bg: 'bg-status-red', text: 'text-status-red' };
    case SurveyStatus.Review:
      return { bg: 'bg-status-purple', text: 'text-status-purple' };
    default:
      return { bg: 'bg-status-gray', text: 'text-status-gray' };
  }
};
