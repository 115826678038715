import { B2BUser } from '../user/user.types';
import { AnalyticEventsEnum } from './analytic-event-enum';
import AnalyticsManager from './analytics.service';

const {
  EditCompanyName, EditCompanySize, EditFirstName, EditLastName, EditRole,
} = AnalyticEventsEnum;
const { trackEvent } = AnalyticsManager;

export function handleEditProfileEvent(newValues: B2BUser, previousValues: B2BUser): void {
  const { company_name, company_size, role, first_name, last_name } = newValues;
  const { company_name: prevCompanyName, company_size: prevCompanySize, role: prevRole, first_name: prevFirstName, last_name: prevLastName } = previousValues;

  company_name !== prevCompanyName && trackEvent(EditCompanyName, { company_name: company_name });
  company_size !== prevCompanySize && trackEvent(EditCompanySize, { company_size: company_size });
  role !== prevRole && trackEvent(EditRole);
  first_name !== prevFirstName && trackEvent(EditFirstName);
  last_name !== prevLastName && trackEvent(EditLastName);
}