import React from 'react';
import loading from '../../assets/icons/loading.svg';
import preview from '../../assets/icons/preview.svg';
import share from '../../assets/icons/share.svg';
// import invoice from '../../assets/icons/invoice.svg';
// import download from '../../assets/icons/download.svg';
// import clone from '../../assets/icons/clone.svg';

interface SurveyMenuProps {
  viewScreen: boolean;
  handleDownload?: () => void;
  handleViewInvoice?: () => void;
  handlePreviewClick?: () => void;
  handleShare?: () => void;
  isShareLoading?: boolean;
  onCopyLinkClick?: () => void;
  onDeleteClick?: () => void;
  onDuplicateClick: () => void;
  surveyCard?: boolean;
}

const SurveyMenu: React.FC<SurveyMenuProps> = ({ surveyCard = false, viewScreen, handleShare, isShareLoading, handleDownload, handleViewInvoice, handlePreviewClick, onCopyLinkClick, onDeleteClick, onDuplicateClick }) => {
  return (
    <div className={`absolute right-0 ${surveyCard ? 'top-3' : 'top-8'} w-40 rounded-md bg-white shadow-lg z-50`}>
      <div className="py-0">
        {viewScreen ? (
          <div>
            {/* Needs implementation

            <button
              onClick={handleDownload}
              className="sm:hidden flex w-full items-center justify-between px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
            >
              Download
              <img src={download} alt="" className="w-3" draggable={false}/>
            </button>
            <button
              onClick={handleViewInvoice}
              className="sm:hidden  flex w-full items-center justify-between px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
            >
              Invoice
              <img src={invoice} alt="" className="w-3" draggable={false} />
            </button> */}
            <button
              onClick={handlePreviewClick}
              className="sm:hidden  flex w-full items-center justify-between px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
            >
              {"Preview"}
              <img
                src={preview}
                alt=""
                className="w-3.5 flex items-center justify-center"
                draggable={false}
              />
            </button>
            {handleShare ? (
              <button
                onClick={handleShare}
                className="sm:hidden  flex w-full items-center justify-between px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
              >
                {"Share survey"}
                <img
                  src={isShareLoading ? loading : share}
                  alt=""
                  className="w-3.5 flex items-center justify-center"
                  draggable={false}
                />
              </button>
            ) : null}
          </div>
        )
          : (
            <div>
              {onCopyLinkClick && (
                <button
                  onClick={onCopyLinkClick}
                  className="flex w-full items-center justify-between px-4 py-2 text-left text-sm text-gray-700 hover:bg-red-100"
                >
                  Copy link
                </button>
              )}
              {/* Needs implementation
            <button
              onClick={onDuplicateClick}
              className="flex w-full items-center justify-between px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
            >
              Duplicate
              <img src={clone} alt="" className="w-4 flex items-center justify-center" draggable={false}/>
            </button> */}
              {onDeleteClick && (
                <button
                  onClick={onDeleteClick}
                  className="flex w-full items-center justify-between px-4 py-2 text-left text-sm text-gray-700 hover:bg-red-100"
                >
                  Delete
                </button>
              )}
            </div>
          )}
      </div>
    </div>
  );
};

export default SurveyMenu;
