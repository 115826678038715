import { useEffect, useRef, useState } from 'react';
import { useGetSurveyAudienceQuery, useGetSurveyQuery } from '../../redux/survey/survey.endpoints';
import { Attribute, convertToAttributes } from './criteria-settings';
import FirebaseService from "../../services/firebase/firebase.service";
import { SurveySampleSizes } from "../../services/firebase/firebase.types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/redux-store";
import { setSurveyTargetingToggle } from "../../redux/survey/survey-targeting-toggle.slice";
import TargetAiSampleSizeRadioButton from './target-ai-sample-size';
import TargetHumanSampleSizeRadioButton from './target-human-sample-size';
import CriteriaSelection from './criteria-selection';

interface CriteriaCardProps {
  extSurveyId: string;
}

const CriteriaCard = ({ extSurveyId }: CriteriaCardProps) => {
  const config = FirebaseService.getRemoteConfigJSON<SurveySampleSizes>('SAMPLE_SIZES');
  const targetingConfig = FirebaseService.getRemoteConfigBool('SURVEYS_DEFAULT_TARGETED');
  const { human, ai } = config || { human: [], ai: [] };
  const { data: fullSurvey } = useGetSurveyQuery(
    { ext_survey_id: extSurveyId },
    { skip: !extSurveyId }
  );
  const { data } = useGetSurveyAudienceQuery({ id: fullSurvey?.audience ?? '' }, { skip: !fullSurvey?.audience });
  const [criteriaAttributes, setCriteriaAttributes] = useState<Attribute[]>();
  const isTargeted = useSelector((state: RootState) => state.surveyToggleState.isTargeted[extSurveyId] ?? targetingConfig);
  const isFirstDataFetch = useRef(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!data) {
      return;
    }

    setCriteriaAttributes(convertToAttributes(data.criteria));

    if (isFirstDataFetch.current && Object.keys(data.criteria).length) {
      dispatch(setSurveyTargetingToggle({ surveyId: extSurveyId, isTargeted: true }));
    }

    if (isFirstDataFetch.current) {
      isFirstDataFetch.current = false;
    }
  }, [data]);

  return (
    <div>
      <div className="mb-4 text-2xl">
        Target
      </div>
      <div className="rounded-lg bg-white p-8 shadow-md">
        <div className="flex flex-col md:flex-row md:space-x-6">
          <div className="w-full">
            <TargetHumanSampleSizeRadioButton
              activeSampleSize={String(fullSurvey?.maxCompletes) ?? '0'}
              extSurveyId={extSurveyId}
              surveyTitle={fullSurvey?.title ?? ''}
              sampleSizes={human}
              zeroDisabled={String(fullSurvey?.maxAICompletes) === '0'}
            />
            <TargetAiSampleSizeRadioButton
              activeSampleSize={String(fullSurvey?.maxAICompletes) ?? '0'}
              extSurveyId={extSurveyId}
              surveyTitle={fullSurvey?.title ?? ''}
              sampleSizes={ai}
              zeroDisabled={String(fullSurvey?.maxCompletes) === '0'}
            />
          </div>
        </div>
        <CriteriaSelection
          audienceId={fullSurvey?.audience ?? ''}
          criteria={criteriaAttributes}
          extSurveyId={extSurveyId}
          isTargeted={isTargeted}
        />
      </div>
    </div>
  );
};

export default CriteriaCard;
