import {loadStripe, Stripe} from "@stripe/stripe-js";
import {stripePublicKey} from "../../constants/config";

class StripeService {
  static instance: StripeService;

  public static getInstance(): StripeService {
    if (!StripeService.instance) {
      StripeService.instance = new StripeService();
    }
    return StripeService.instance;
  }

  public initStripe = async(): Promise<Stripe | null> => {
    return loadStripe(stripePublicKey);
  };
}

export default StripeService.getInstance();
