import { ReactElement } from 'react';
import {en} from "../../../locales/en";
import {Button} from "primereact/button";

export type ContinueState = 'hidden' | 'visible' | 'disabled';
export interface SurveyAnswerFooterProps {
  readonly activeIndex: number;
  readonly continueState?: ContinueState;
  readonly isLoading: boolean;
  readonly totalQuestions: number;
  readonly onPressContinue: () => void;
}

const SurveyPreviewFooter = ({ continueState = 'hidden', isLoading, onPressContinue, activeIndex, totalQuestions }: SurveyAnswerFooterProps): ReactElement => {
  function getRemainingQuestionsText(): string {
    const { questionLeft, questionsLeft, lastQuestion } = en.surveyPreview;
    const remainingQuestions = totalQuestions - activeIndex - 1;

    if (remainingQuestions <= 0) {
      return lastQuestion;
    }

    return `${remainingQuestions} ${remainingQuestions > 1 ? questionsLeft : questionLeft}`;
  }

  function renderProgressBar(): ReactElement {
    const fillPercent = ((activeIndex + 1) / totalQuestions) * 100;

    return (
      <div className='h-2.5 w-full rounded-xl bg-gray-300'>
        <div
          className={`h-2.5 rounded-xl bg-black`}
          style={{ width: `${fillPercent}%` }}
        />
      </div>
    );
  }

  function renderContinueButton(): ReactElement | null {
    if (continueState === 'hidden') {
      return null;
    }

    return (
      <Button
        disabled={continueState === 'disabled'}
        className='w-full bg-black text-white rounded-full py-2 text-center disabled:bg-gray-500'
        label={en.surveyPreview.continue}
        onClick={onPressContinue}
        loading={isLoading}
      />
    );
  }

  return (
    <div className='flex flex-col gap-3'>
      <div className='flex gap-6 px-6'>
        <div className='flex grow flex-col items-center justify-center gap-2 pt-2.5'>
          {renderProgressBar()}
          <span className='text-xs text-gray-700'>
            {getRemainingQuestionsText()}
          </span>
        </div>
      </div>
      {renderContinueButton()}
    </div>
  );
};

export default SurveyPreviewFooter;
