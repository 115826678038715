import { TextProps } from "./title";

export const SubTitle = ({
  bold = false,
  className = '',
  color = 'text-gray-400',
  text,
}: TextProps) => {
  const boldClass = bold ? 'font-bold' : '';
  return (
    <span className={`text-xs leading-4 ${boldClass} ${className} ${color}`}>
      {text}
    </span>
  );
};