/* eslint-disable camelcase */
import { invisiblyApi } from '../../api/invisibly-api';
import {FetchSurveyAudienceListData, FetchSurveyAudienceListApiResponse} from "../../../services/admin/audience/audience.types";
import SurveyService from "../../../services/admin/audience/audience-parsing.service";
import {InvisiblyApiResponse} from "../../../services/api/api-response.types";
import {SurveyAudience} from "./audience.types";

const audienceAdminEndpoints = invisiblyApi.injectEndpoints({
  endpoints: (builder) => ({
    createAdminSurveyAudience: builder.mutation<void, SurveyAudience>({
      query: (data) => ({
        data,
        url: 'admin/surveys/audiences',
        method: 'post',
      }),
    }),
    getAdminSurveyAudiences: builder.query<FetchSurveyAudienceListData, { page?: number, page_size?: number }>({
      query: (data) => ({
        method: 'get',
        params: { page: data.page, page_size: data?.page_size},
        url: 'admin/surveys/audiences',
      }),
      transformResponse(response: InvisiblyApiResponse<FetchSurveyAudienceListApiResponse>) {
        return{
          audiences: response.item.items.map(a => SurveyService.parseListAudience(a)),
          totalObjects: response.item.total_objects,
          totalPages: response.item.total_pages,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useCreateAdminSurveyAudienceMutation,
  useGetAdminSurveyAudiencesQuery,
} = audienceAdminEndpoints;
