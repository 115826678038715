import React, { useEffect, useRef, useState } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import './nested-dropdown.css';
import { isEqual } from "lodash";
import { B2BButton } from "../../b2bbutton/b2bbutton";
import AnalyticsManager from '../../../services/analytics/analytics.service';
import { AnalyticEventsEnum } from '../../../services/analytics/analytic-event-enum';

interface Attribute {
  label: string;
  value: string;
  options: { label: string; value: any }[];
}

interface Props {
  index: number;
  isActive: boolean;
  filter?: boolean;
  selectedItems: string[];
  attribute: Attribute;
  handleMultiSelectChange: (chosenAttribute: Attribute, index: number) => void;
}

const MultiSelectComponent: React.FC<Props> = ({
  index,
  isActive,
  filter = true,
  selectedItems,
  attribute,
  handleMultiSelectChange,
}) => {
  const previousSelectedItems = useRef<string[]>(selectedItems);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(selectedItems);
  const showSave = !isEqual(selectedOptions, previousSelectedItems.current);

  useEffect(() => {
    if (selectedItems && !isEqual(selectedItems, selectedOptions)) {
      setSelectedOptions(selectedItems);
      previousSelectedItems.current = selectedItems;
    }
  }, [selectedItems]);

  const convertValuesToOptions = (values: string[]): { label: string; value: string }[] => {
    return values.map(value => {
      const option = attribute.options.find(opt => opt.value === value);
      return { label: option ? option.label : 'Unknown', value };
    });
  };

  const handleSelectChange = (e: { value: any }) => {
    const { value } = e;
    setSelectedOptions(value);
  };

  const onSave = () => {
    const convertedOptions = convertValuesToOptions(selectedOptions);
    const updatedAttribute: Attribute = {
      ...attribute,
      options: convertedOptions,
    };
    handleMultiSelectChange(updatedAttribute, index);
    AnalyticsManager.trackEvent(AnalyticEventsEnum.SaveCriteria);
  };

  const renderSaveButton = () => {
    if (!showSave) {
      return null;
    }

    return (
      <div className="flex px-6 py-4 justify-center w-full">
        <B2BButton
          label={'Apply criteria'}
          onClick={onSave}
          expand
          outline
        />
      </div>
    );
  };

  return (
    <div className="multi-select-container h-0">
      <MultiSelect
        options={attribute.options}
        value={selectedOptions}
        onChange={handleSelectChange}
        filter={filter}
        placeholder="Select"
        appendTo={document.body}
        overlayVisible={isActive}
        className="pointer-events-none h-0 opacity-0"
        panelFooterTemplate={renderSaveButton}
      />
    </div>
  );
};

export default MultiSelectComponent;
