import {useEffect} from "react";

export const useSetPageTitle = (title: string) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

export const useSetAdminPageTitle = (title: string) => {
  useSetPageTitle(`${title} - Admin`);
};
