import { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { Panel } from 'primereact/panel';
import { useCreateAdminSurveyMutation } from "../../redux/admin/survey/survey.endpoints";
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Nullable } from 'primereact/ts-helpers';
import { useSetAdminPageTitle } from "../../hooks/useSetPageTitle";
import { useGetAdminSurveyAudiencesQuery } from "../../redux/admin/audience/audience.endpoints";
import { isNaN } from "lodash";
import { CSVSurveyData } from "../../redux/admin/survey/survey.types";


function SurveyCreateCsvScreen() {
  useSetAdminPageTitle('CSV survey');
  const [title, setTitle] = useState<string>('');
  const [points, setPoints] = useState<string>('');
  const [csv, setCsv] = useState<File>();
  const [image, setImage] = useState<File>();
  const [submitCsv, { isSuccess, error }] = useCreateAdminSurveyMutation();
  const [visible, setVisible] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [startDate, setStartDate] = useState<Nullable<Date>>(null);
  const [endDate, setEndDate] = useState<Nullable<Date>>(null);
  const [audienceId, setAudienceId] = useState<string>('');
  const { data: audienceData } = useGetAdminSurveyAudiencesQuery({ page: 1, page_size: 200 }, { refetchOnMountOrArgChange: true });
  const audienceOptions = audienceData?.audiences.map(a => ({ label: a.title, value: a.id })) || [];
  const [maxHumansString, setMaxHumansString] = useState<string>('');
  const [maxAIString, setMaxAIString] = useState<string>('');

  const categories = ['You', 'Entertainment', 'Tech', 'Community', 'Business', 'Lifestyle', 'Food & Drink', 'Professional', 'Retail', 'Law & Politics', 'Animals', 'Parenting', 'Home & Garden', 'Auto', 'Product Feedback'];

  useEffect(() => {
    if (isSuccess) {
      setDialogTitle('Success');
      setDialogMessage('Survey created!');
      setVisible(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      console.log(error);
      setDialogTitle('Error');
      setVisible(true);
    }
  }, [error]);

  function createSurvey(): void {
    if (!csv) {
      return;
    }

    const formData = new FormData();
    let maxHumans: string | undefined = undefined;
    const maxHumansBase = Number(maxHumansString);
    if (maxHumansString.length && !isNaN(maxHumansBase) && maxHumansBase >= 0) {
      maxHumans = maxHumansBase.toString();
    }
    let maxAI = 0;
    const maxAIBase = Number(maxAIString);
    if (!isNaN(maxAIBase) && maxAIBase > 0) {
      maxAI = maxAIBase;
    }

    const surveyData: CSVSurveyData = {
      title,
      points,
      category,
      ai_max_completes: maxAI.toString(),
      audience: audienceId !== undefined ? audienceId : undefined,
      start_date: startDate == null ? '' : startDate.toISOString(),
      end_date: endDate == null ? '' : endDate.toISOString(),
      file: csv,
      survey_status: 'live',
    };

    if (image) {
      surveyData.image_file = image;
    }

    if (maxHumans) {
      surveyData.max_completes = maxHumans;
    }

    Object.entries(surveyData).forEach(entry => {
      formData.append(entry[0], entry[1]);
    });

    void submitCsv(formData);
  }

  return (
    <div>
      <div className="justify-content-center flex flex-col flex-wrap w-1/2 mx-auto">
        <div className="col p-8">
          <Panel
            header="Create Survey"
            className='rounded-lg drop-shadow-lg content-center'
          >

            <div className="col-1 m-2">
              <InputText
                className='xm-1 border-2 rounded-md p-2 w-full'
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="col-1 m-2">
              <InputText
                className='xm-1 border-2 rounded-md p-2 w-full'
                placeholder="Points"
                value={points}
                onChange={(e) => setPoints(e.target.value)}
              />
            </div>
            <div className="col-1 m-2">
              <InputText
                className='xm-1 border-2 rounded-md p-2 w-full'
                placeholder="Human Response Limit (blank for unlimited)"
                value={maxHumansString}
                onChange={(e) => setMaxHumansString(e.target.value)}
              />
            </div>
            <div className="col-1 m-2">
              <InputText
                className='xm-1 border-2 rounded-md p-2 w-full'
                placeholder="AI Response Limit (blank for 0)"
                value={maxAIString}
                onChange={(e) => setMaxAIString(e.target.value)}
              />
            </div>
            <div className="col-1 m-2">
              <Dropdown
                className='xm-1 border-2 rounded-md p-2 w-full'
                value={category}
                onChange={(e) => setCategory(e.value)}
                options={categories}
                placeholder="Select a Category"
              />
            </div>
            <div className="col-1 m-2">
              <Dropdown
                className='xm-1 border-2 rounded-md p-2 w-full'
                filter
                showFilterClear
                value={audienceId}
                onChange={(e) => setAudienceId(e.value)}
                options={audienceOptions}
                placeholder="Select an Audience"
              />
            </div>
            <div className="col m-2">
              <FileUpload
                mode="basic"
                onSelect={(e) => setCsv(e.files[0])}
                chooseLabel="CSV"
                className='border-2 p-2 w-full rounded-md'
                accept="text/csv"
              />
            </div>
            <div className="col m-2">
              <FileUpload
                mode="basic"
                onSelect={(e) => setImage(e.files[0])}
                chooseLabel="Image"
                className='border-2 p-2 w-full rounded-md'
                accept="image/*"
                maxFileSize={1000000}
              />
            </div>
            <div className="col m-2">
              <Calendar
                placeholder='Start Date'
                showTime
                hourFormat="12"
                onChange={(e) => setStartDate(e.value)}
                className='border-2 p-2 w-full rounded-md'
                hideOnDateTimeSelect={true}
              />
            </div>

            <div className="col m-2">
              <Calendar
                placeholder='End Date'
                showTime
                hourFormat="12"
                onChange={(e) => setEndDate(e.value)}
                className='border-2 p-2 w-full rounded-md'
                hideOnDateTimeSelect={true}
              />
            </div>
            <div className="col m-2">
              <Button
                disabled={!csv}
                label='Create'
                className='border-2 p-2 w-full bg-blue-400 text-white rounded-md'
                onClick={createSurvey}
              />
            </div>
          </Panel>
          <Dialog
            header={dialogTitle}
            visible={visible}
            style={{ width: '50vw' }}
            onHide={() => setVisible(false)}
          >
            <p className="m-0">
              {dialogMessage}
            </p>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default SurveyCreateCsvScreen;
