import { SurveyGrid, SurveyGridProps } from "../layouts/dashboard-surveys/layout-dashboard-surveys";
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import { SurveyDisplayStatus } from "../../redux/survey/survey.types";
import { SURVEY_GRID_PAGE_SIZE } from "../../services/surveys/survey.constants";
import { template } from '../paginator/paginator-template';
import { useState } from "react";


interface SurveySectionProps {
  title: string,
  surveys: SurveyGridProps['cards'],
  totalRecords: number;
  displayStatus: SurveyDisplayStatus;
  currentPage: number;
  pageSize: number;
  onPageChange: (event: PaginatorPageChangeEvent) => void;
  isEmpty: boolean;
}

export const SurveySection = ({title, surveys, totalRecords, displayStatus, currentPage, pageSize, onPageChange, isEmpty }: SurveySectionProps) =>{
  const [firstPage, setFirstPage] = useState((currentPage - 1) * pageSize);
  const [customRows, setCustomRows] = useState(pageSize);

  const onCustomPageChange = (event: PaginatorPageChangeEvent) => {
    setFirstPage(event.first);
    setCustomRows(event.rows);
    onPageChange(event);
  };

  const topMargin = displayStatus === SurveyDisplayStatus.Live ? 'mt-4' : 'mt-14';

  return (
    <div className={`${topMargin}`}>
      <SurveyGrid
        cards={surveys}
        title={title}
        displayStatus={displayStatus}
        isEmpty={isEmpty}
        currentPage={currentPage}
      />
      {totalRecords > SURVEY_GRID_PAGE_SIZE && (
        <div className="flex justify-start">
          <Paginator
            first={firstPage}
            rows={customRows}
            totalRecords={totalRecords}
            onPageChange={onCustomPageChange}
            className='bg-inherit'
            template={template}
          />
        </div>
      )}
    </div>
  );
};
