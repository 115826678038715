import { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { websiteUrl } from "../constants/config";
import { SurveyShareButtonProps } from "../pages/view-survey/components/share-survey-button.component";
import { showNotification } from "../redux/notification/notification.slice";
import { useShareSurveyResultsMutation } from "../redux/survey/survey.endpoints";
import { SurveyStatus } from "../redux/survey/survey.types";
import { AnalyticEventsEnum } from "../services/analytics/analytic-event-enum";
import AnalyticsManager from "../services/analytics/analytics.service";
import { useCopy } from "./useCopy";

const copySuccessMessage = 'Survey link copied.';

interface UseShareSurveyReturn {
  readonly canShare: boolean;
  readonly handleShare: () => void;
  readonly isLoading: boolean;
}

const useShareSurvey = ({ surveyId, surveyStatus }: SurveyShareButtonProps): UseShareSurveyReturn => {
  const shareUrl = useRef('');
  const canShare = surveyStatus === SurveyStatus.Live || surveyStatus === SurveyStatus.Complete;
  const [shareSurvey, { data, isError, isLoading }] = useShareSurveyResultsMutation();
  const { toClipboard } = useCopy();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data && !shareUrl.current) {
      shareUrl.current = createUrl(data);
    }

    shareUrl.current && void toClipboard(shareUrl.current, copySuccessMessage);
  }, [data]);

  useEffect(() => {
    isError && dispatch(showNotification({ theme: 'warning', summary: 'Something went wrong. Please try again.' }));
  }, [isError]);

  function handleShare(): void {
    AnalyticsManager.trackEvent(AnalyticEventsEnum.ShareSurveyClicked);
    if (shareUrl.current) {
      toClipboard(shareUrl.current, copySuccessMessage);
    } else {
      surveyId && void shareSurvey(surveyId);
    }
  }

  function createUrl(shareId: string): string {
    return `${websiteUrl}/share-survey?share-id=${shareId}`;
  }

  return {
    canShare,
    handleShare,
    isLoading,
  };
};

export default useShareSurvey;
