export enum APITagType {
  AdminSurvey = 'admin-survey',
  BrandOffer = 'brand-offer',
  MagazineTitle = 'magazine-title',
  SuspensionReason = 'suspension-reason',
  YodleeBankAccount = 'yodlee-bank-account',
  YodleeToken = 'yodlee-link-token'
}

export type TagProviderResult = {
  readonly id: string;
  readonly type: APITagType;
};

export type TagInvalidator = APITagType | { id: string, type: APITagType };
