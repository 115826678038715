const dropdownStylesFull = {
  control: (provided: any) => ({
    ...provided,
    border: 'none',
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    padding: '7px 15px',
    cursor: 'pointer',
    '&:hover': {
      borderBottomColor: 'none',
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#9DA3AE',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: '100%',
    overflow: 'hidden',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: 'black',
    padding: '10px 25px',
    backgroundColor: state.isSelected ? '#F2F2F2' : 'white',
    '&:hover': {
      backgroundColor: '#F2F2F2',
    },
  }),
};

export default dropdownStylesFull;
