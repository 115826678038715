import {Card} from "primereact/card";
import QuestionHeader from "../question-header/question-header";
import {RadioButtonGrid, RadioButtonGridProps, RadioButtonGridType} from "../radio-button/radio-button";
import {AnswerInput} from "../answer-input/answer-input";
import {InputText} from 'primereact/inputtext';
import QuestionImageUpload from "../image-upload/question-image-upload/question-image-upload";
import {B2BButton} from "../b2bbutton/b2bbutton";
import {useEffect, useMemo, useState} from "react";
import {
  useCreateAnswerMutation,
  useDeleteImageMutation,
  useDeleteQuestionMutation,
  useGetSurveyQuery,
  useUpdateQuestionMutation,
} from "../../redux/survey/survey.endpoints";
import {InputSwitch, InputSwitchChangeEvent} from "primereact/inputswitch";
import {
  Question,
  QuestionUpdateData,
  SaveStatusEnum,
  ShuffleMode,
  SurveyQuestionType,
  SurveyStatus,
} from "../../redux/survey/survey.types";
import plusIcon from '../../assets/icons/Plus-black.svg';
import {useDispatch, useSelector} from "react-redux";
import {setSaveStateWithDelay} from "../../redux/survey/survey-status.utils";
import {setSaveState} from "../../redux/survey/survey-status.slice";
import '../image-upload/question-image-upload/question-image-upload.css';
import AnalyticsManager from '../../services/analytics/analytics.service';
import {AnalyticEventsEnum} from '../../services/analytics/analytic-event-enum';
import {booleanToShuffleMode, shuffleModeToBoolean} from "../../services/surveys/surveys.helpers";
import {MAX_ANSWER_COUNT} from "../../pages/view-survey/view-survey.constants";
import TooltipIcon from "../tooltip-icon/tooltip-icon.component";
import { RootState } from "../../redux/redux-store";
import FirebaseService from "../../services/firebase/firebase.service";
import {CreateSurveyCopy} from "../../pages/create-survey/create-survey.types";

const {
  AddSurveyAnswer, EnterSurveyQuestion, SelectAnswerTypeMulti, SelectAnswerTypeSingle, TapDeleteQuestion,
  ToggleAnswerImageOff, ToggleAnswerImageOn, ToggleRandomizeOff, ToggleRandomizeOn,
} = AnalyticEventsEnum;
const { trackEvent } = AnalyticsManager;

interface QuestionCardProps {
  extSurveyId: string;
  id: string;
}

export const QuestionCard = ({ extSurveyId, id }: QuestionCardProps) => {
  const { rationaleToggle } = FirebaseService.getRemoteConfigJSON<CreateSurveyCopy>('CREATE_SURVEY_COPY');
  const allowFreeform = FirebaseService.getRemoteConfigBool('ALLOW_FREEFORM') ?? false;
  const { data } = useGetSurveyQuery(
    { ext_survey_id: extSurveyId },
    { skip: !extSurveyId }
  );

  const answerTypeButtons = [
    { label: 'Single choice', value: SurveyQuestionType.SingleSelect },
    { label: 'Multiple choice', value: SurveyQuestionType.MultiSelect },
  ];
  if (allowFreeform) {
    answerTypeButtons.push({ label: "Freeform response", value: SurveyQuestionType.Freeform });
  }

  const answerTypeProps: RadioButtonGridProps = {
    extSurveyId: extSurveyId,
    title: 'Types of Answers',
    buttons: answerTypeButtons,
    type: RadioButtonGridType.AnswerType,
  };
  const dispatch = useDispatch();
  const initialIsImageAnswers = useSelector((state: RootState) => Boolean(state.surveyImageTestState?.isImageTest?.[extSurveyId]) ?? false);
  const [question, setQuestion] = useState<Question | undefined>(undefined);
  const [answerType, setAnswerType] = useState<string>('');
  const [updateQuestion, { isSuccess: updateQuestionSuccess }] = useUpdateQuestionMutation();
  const [createAnswer, { isSuccess: createSuccess }] = useCreateAnswerMutation();
  const [deleteQuestion, { isSuccess: deleteQuestionSuccess }] = useDeleteQuestionMutation();
  const [deleteImage] = useDeleteImageMutation();
  const [questionText, setQuestionText] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isImageAnswers, setIsImageAnswers] = useState<boolean>(initialIsImageAnswers);
  const [shuffleMode, setShuffleMode] = useState<ShuffleMode>('random');
  const [allowCustomResponse, setAllowCustomResponse] = useState(false);
  const isFreeform = answerType === SurveyQuestionType.Freeform;

  const { canAddAnswer, addAnswerText } = useMemo(() => {
    if (!data || data.surveyStatus === SurveyStatus.Review) {
      return {canAddAnswer: false, addAnswerText: ''};
    }

    const answerCount = question?.answers?.length || 0;
    const answersRemaining = MAX_ANSWER_COUNT - answerCount;
    if (answersRemaining <= 0) {
      return {canAddAnswer: false, addAnswerText: ''};
    }

    const answerString = answersRemaining === 1 ? 'answer' : 'answers';
    return { canAddAnswer: true, addAnswerText: `Add answer (${MAX_ANSWER_COUNT - answerCount} ${answerString} left)` };
  }, [data, question]);
  useEffect(() => {
    const updatedQuestion = data?.questions.find(q => q.id === id);

    if (!updatedQuestion) {
      return;
    }

    const allImageUrlsNull = updatedQuestion.answers.every(answer => answer.imageUrl === null);
    setIsImageAnswers(isImageAnswers || !allImageUrlsNull);
    setQuestion(updatedQuestion);
    setQuestionText(updatedQuestion.title !== "Untitled_Question" ? updatedQuestion.title || "" : "");
    setAnswerType(updatedQuestion.type);
    setShuffleMode(updatedQuestion.shuffle);
    setAllowCustomResponse(updatedQuestion.hasFeedback);
  }, [data, id]);

  useEffect(() => {
    const showSuccess = updateQuestionSuccess || createSuccess || deleteQuestionSuccess;
    showSuccess && setSaveStateWithDelay(dispatch);
  }, [updateQuestionSuccess, createSuccess, deleteQuestionSuccess]);

  const validateQuestionText = (value: string) => {
    if (value.trim().length < 2) {
      setError('Question must be at least 2 characters long');
      return false;
    }

    setError('');
    return true;
  };

  const internalQuestionUpdate = ({ questionType, shuffleAnswers, hasFeedback }: { questionType?: SurveyQuestionType, shuffleAnswers?: ShuffleMode, hasFeedback?: boolean }) => {
    if (!question) {
      return;
    }

    const finalTitle = questionText.trim().length === 0 ? "Untitled_Question" : questionText;
    const updateData: QuestionUpdateData = {
      ext_survey_id: extSurveyId,
      survey: extSurveyId,
      title: finalTitle,
      question_id: question.id,
      question_order: question.questionOrder,
      shuffle: shuffleAnswers ?? question.shuffle,
      has_feedback: hasFeedback,
    };

    if (questionType) {
      updateData.type = questionType;
    }

    void updateQuestion(updateData);
  };

  const handleAnswerTypeChange = (value: string) => {
    const selectedValue = value as SurveyQuestionType;
    selectedValue === SurveyQuestionType.SingleSelect ? trackEvent(SelectAnswerTypeSingle) : trackEvent(SelectAnswerTypeMulti);
    internalQuestionUpdate({ questionType: selectedValue });
  };

  const handleQuestionTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSaveState({ status: SaveStatusEnum.Syncing }));
    const value = e.target.value;
    setQuestionText(value);
    validateQuestionText(value);
  };

  const handleQuestionTextBlur = () => {
    trackEvent(EnterSurveyQuestion);
    internalQuestionUpdate({});
  };

  const handleDelete = () => {
    if (!question) return;
    trackEvent(TapDeleteQuestion);
    void deleteQuestion({ ext_survey_id: extSurveyId, question_id: question.id || '' });
  };

  const handleAddOption = () => {
    if (question?.answers?.length || 0 < 8) {
      trackEvent(AddSurveyAnswer);
      void createAnswerCall();
    }
  };

  const createAnswerCall = async() => {
    if (!question) return;
    const initialAnswerData = {
      title: "Untitled_Answer",
      survey: extSurveyId,
      survey_question: question.id || "",
      ext_survey_id: extSurveyId,
    };

    void createAnswer(initialAnswerData);
  };
  const handleIsImageAnswersToggle = async() => {
    isImageAnswers ? trackEvent(ToggleAnswerImageOff) : trackEvent(ToggleAnswerImageOn);
    if (isImageAnswers) {
      for (const answer of question?.answers || []) {
        if (answer.imageUrl) {
          await handleImageRemove(answer.id);
        }
      }
    }
    setIsImageAnswers(!isImageAnswers);
  };

  const handleImageRemove = async(answerId: string) => {
    try {
      await deleteImage({ image_id: answerId, ext_survey_id: extSurveyId, type: "answer" }).unwrap();
    } catch (error) {
      console.error('Error deleting answer image:', error);
    }
  };

  const handleShuffleChange = ({ value }: InputSwitchChangeEvent) => {
    const newShuffleMode = booleanToShuffleMode(value);

    if (newShuffleMode === shuffleMode) {
      return;
    }

    value ? trackEvent(ToggleRandomizeOn) : trackEvent(ToggleRandomizeOff);
    internalQuestionUpdate({ shuffleAnswers: newShuffleMode });
  };

  const handleCustomResponseToggle = ({ value }: InputSwitchChangeEvent) => {
    // connect this to the api later
    if (value === allowCustomResponse) {
      return;
    }

    internalQuestionUpdate({ hasFeedback: value });
  };

  const renderOptionToggles = () => {
    if (data?.surveyStatus === SurveyStatus.Review) {
      return null;
    }

    const isShuffleOn = shuffleModeToBoolean(shuffleMode);

    return (
      <div>
        <div className="flex items-center">
          <div className="upload_question_image flex items-center">
            <InputSwitch
              checked={isShuffleOn}
              onChange={handleShuffleChange}
              className={`custom-switch ${isShuffleOn ? 'p-inputswitch-checked' : ''}`}
            />
          </div>
          <span className="mr-2">
            Randomize answer order
          </span>
          <TooltipIcon
            targetClass="shuffle-toggle"
            message="Shuffle the order of answer choices for each respondent to reduce bias."
          />
        </div>
        <div className="flex items-center">
          <div className="upload_question_image flex items-center">
            <InputSwitch
              checked={isImageAnswers}
              onChange={handleIsImageAnswersToggle}
              className={`custom-switch ${isImageAnswers ? 'p-inputswitch-checked' : ''}`}
            />
          </div>
          <span className="mr-2">
            Answer image(s)
          </span>
          <TooltipIcon
            targetClass="image-toggle"
            message="Attach an image to an individual answer choice."
          />
        </div>
        <div className="flex items-center">
          <div className="upload_question_image flex items-center">
            <InputSwitch
              checked={allowCustomResponse}
              onChange={handleCustomResponseToggle}
              className={`custom-switch ${allowCustomResponse ? 'p-inputswitch-checked' : ''}`}
            />
          </div>
          <span className="mr-2">
            { rationaleToggle }
          </span>
          <TooltipIcon
            targetClass="custom-response-toggle"
            message="Include a follow-up text box for respondents to provide reasoning for their selection."
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <Card className="rounded-lg mb-5">
        <QuestionHeader
          extSurveyId={extSurveyId}
          number={(question?.questionOrder || 0) + 1}
          onDelete={handleDelete}
          onDuplicate={() => {
          }}
        />
        <div className="px-8">
          <div className="mb-6">
            <InputText
              value={questionText}
              placeholder="Enter your question here"
              className={`w-full rounded-lg ${error && "border-red-500"} border border-gray-300 p-2`}
              onChange={handleQuestionTextChange}
              onBlur={handleQuestionTextBlur}
              disabled={data?.surveyStatus === SurveyStatus.Review}
            />
            {error && (
              <p className="mt-2 text-red-500 text-sm">
                {error}
              </p>
            )}
          </div>

          <QuestionImageUpload
            questionId={question?.id || ""}
            extSurveyId={extSurveyId}
            imageSrc={question?.imageUrl || null}
          />
          <RadioButtonGrid
            {...answerTypeProps}
            onClick={handleAnswerTypeChange}
            initialValue={answerType}
            id={id}
          />
          { !isFreeform && (
            <>
              <div className="mt-4 mb-2 flex flex-row items-center justify-between">
                <div className="font-semibold">
                  Settings
                </div>
              </div>
              {renderOptionToggles()}
              <div className="mt-4 mb-2 flex flex-row items-center justify-between">
                <div className="font-semibold">
                  Answers
                </div>
              </div>
              {question?.answers?.map((ans, index) => (
                <AnswerInput
                  key={index}
                  index={index}
                  extSurveyId={extSurveyId}
                  questionId={question.id || ''}
                  answerId={ans.id}
                  isImage={isImageAnswers}
                  className="shadow-sm"
                  isMultiSelect={answerType === SurveyQuestionType.MultiSelect}
                />
              ))}
              {canAddAnswer && (
                <div className="mt-3">
                  <B2BButton
                    icon={plusIcon}
                    label={addAnswerText}
                    theme='light'
                    size='regular-thin'
                    onClick={handleAddOption}
                  />
                </div>
              )
              }
            </>
          )}
        </div>
      </Card>
    </div>
  );
};
