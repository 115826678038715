import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateAdminSurveyBulkMutation } from "../../redux/admin/survey/survey.endpoints";
import { InputTextarea } from 'primereact/inputtextarea';
import { useSetAdminPageTitle } from "../../hooks/useSetPageTitle";
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';

interface Survey {
  ext_survey_id: string,
  title: string,
}

interface SurveyError {
  title: string,
  error: string,
}

function SurveyCreateScreen() {
  useSetAdminPageTitle('Create survey in bulk');

  const [surveyData, setSurveyData] = useState<string>('');
  const [submitBulkSurveyCreation, { data, isSuccess, error }] = useCreateAdminSurveyBulkMutation();
  const [surveyResults, setSurveyResults] = useState<Survey[]>([]);
  const [surveyError, setSurveyError] = useState<SurveyError[]>([]);

  const nav = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      console.log('survey created successfully');
      setSurveyData('');
    }
  }, [isSuccess]);

  useEffect(() => {
    error && console.log('survey creation error: ', error);
  }, [error]);

  useEffect(() => {
    if (isSuccess && data) {
      if (data.item.errors.length) {
        const errors = data.item.errors.map((error: any) => {
          return {
            title: error.title,
            error: error.error,
          };
        });
        setSurveyError(errors);
      }

      if (data.item.surveys.length) {
        const surveys = data.item.surveys.map((survey: Survey) => {
          return {
            ext_survey_id: survey.ext_survey_id,
            title: survey.title,
          };
        });
        setSurveyResults(surveys);
      }
    }
  }, [data]);

  function createSurvey(): void {
    const formData = new FormData();
    formData.append('data', surveyData);
    void submitBulkSurveyCreation(formData);
  }

  return (
    <div>
      <div className="justify-content-center flex grid flex-col flex-wrap p-4">
        <div className="col p-8">
          <Panel
            header="Bulk Create Surveys"
            className='rounded-xl drop-shadow-lg content-center w-[70%] mx-auto'
          >
            <div className="col-1 m-1">
              <InputTextarea
                className='xm-1 border-2 rounded-md p-2 w-full h-28'
                placeholder="Title"
                value={surveyData}
                onChange={(e) => setSurveyData(e.target.value)}
              />
            </div>
            <div className="col m-2">
              <Button
                label='Create'
                className='border-2 p-2 w-full bg-blue-400 text-white rounded-md'
                onClick={createSurvey}
              />
            </div>
            {surveyResults.length > 0 && (
              <div className="col m-2">
                <Panel
                  header='Surveys created successfully'
                  className='border rounded-md mb-2'
                >
                  {surveyResults.map((survey: Survey, index: number) => (
                    <div
                      key={index}
                      className="m-2"
                    >
                      {survey.title}
                      {' '}
                      -
                      {survey.ext_survey_id}
                    </div>
                  ))}
                </Panel>
              </div>
            )}
            {surveyError.length > 0 && (
              <div className="col m-2">
                <Panel
                  header='Survey creating errors'
                  className='border rounded-md mb-2'
                >
                  {surveyError.map((error: SurveyError, index: number) => (
                    <div
                      key={index}
                      className="m-2"
                    >
                      {error.error}
                    </div>
                  ))}
                </Panel>
              </div>
            )}
          </Panel>
        </div>
      </div>
    </div>
  );
}

export default SurveyCreateScreen;
