import errorParsingService from '../error-parsing/error-parsing.service';
import { showNotification } from '../../redux/notification/notification.slice';
import { Dispatch } from 'redux';
import { UsableError } from '../error-parsing/error-parsing.types';
import { ServerResponseEnum } from '../api/invisibly-api.constants';


interface INotificationHandlingService {
  readonly handleSuccess: <T>(data: T, dispatch: Dispatch, message: string) => T;
  readonly handleError: (error: any, dispatch: Dispatch, message: string) => UsableError;
}

class NotificationHandlingService implements INotificationHandlingService {
  private static instance: NotificationHandlingService | undefined;

  static getInstance(): NotificationHandlingService {
    if (!NotificationHandlingService.instance) {
      NotificationHandlingService.instance = new NotificationHandlingService();
    }

    return NotificationHandlingService.instance;
  }

  public handleSuccess<T>(data: T, dispatch: Dispatch, message: string ): T {
    dispatch(showNotification({summary: message, theme: 'success'}));

    return data;
  }

  public handleError(error: any, dispatch: Dispatch, message?: string): UsableError {
    const usableError = errorParsingService.getUsableError(error);

    if (Object.values(ServerResponseEnum).includes(usableError.code)) {
      dispatch(showNotification({summary: usableError.message, theme: 'info'}));
    } else {
      dispatch(showNotification({summary: message ?? usableError.message, theme: 'warning'}));
    }

    return usableError;
  }
}

export default NotificationHandlingService.getInstance();
