import { APITagType, TagInvalidator, TagProviderResult } from './invisibly-api.types';
import {ServerResponseEnum} from "../../services/api/invisibly-api.constants";

export const createTagProviderArray = <T>(idKey: keyof T, tagType: APITagType, data?: T[]): TagProviderResult[] => {
  const input = data?.filter(item => item !== undefined) ?? [];

  return input.map(item => {
    return {
      id: item[idKey] as string,
      type: tagType,
    };
  }).concat({
    id: 'LIST',
    type: tagType,
  });
};

export const createTagInvalidationArray = (tagType: APITagType): TagInvalidator[] => {
  return [
    tagType,
    {
      id: 'LIST',
      type: tagType,
    },
  ];
};

export const isAuthError = (statusCode: ServerResponseEnum): boolean => {
  return statusCode === ServerResponseEnum.AUTH_NOT_PROVIDED || statusCode === ServerResponseEnum.TOKEN_PERMISSION_ERROR || statusCode === ServerResponseEnum.INVALID_JWT_FORMAT;
};
