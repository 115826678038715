import {useCreateMediaPlanMutation, useGetMediaPlanQuery} from "../../../redux/survey/media-plan.endpoints";
import {SurveyMediaPlanStatus} from "../../../redux/survey/media-plan.types";
import {useEffect, useState} from "react";
import {SurveyStatus} from "../../../redux/survey/survey.types";

const SURVEY_MEDIA_PLAN_REFRESH_INTERVAL = 15000;
export const useSurveyMediaPlan = (extSurveyId: string, surveyStatus?: SurveyStatus) => {
  const [createCall, { isLoading: isCreationLoading }] = useCreateMediaPlanMutation();
  const [pollingInterval, setPollingInterval] = useState<number | undefined>(undefined);
  const { data: planData, error: planError, isLoading: isGetLoading } = useGetMediaPlanQuery({ext_survey_id: extSurveyId}, { pollingInterval, skip: surveyStatus !== SurveyStatus.Complete});
  const [canCreatePlan, setCanCreatePlan] = useState(false);
  const isLoadingPlan = isCreationLoading || isGetLoading || planData?.mediaPlanStatus === SurveyMediaPlanStatus.Building;

  useEffect(() => {
    handleGetPlanResponse();
  }, [planData, planError]);

  const handleGetPlanResponse = () => {
    if (planError) {
      setCanCreatePlan(true);
      return;
    }

    if (!planData) {
      return;
    }

    setCanCreatePlan(false);
    setPollingInterval(planData.mediaPlanStatus === SurveyMediaPlanStatus.Building ? SURVEY_MEDIA_PLAN_REFRESH_INTERVAL : undefined);
  };

  const createMediaPlan = () => {
    setCanCreatePlan(false);
    void createCall({ ext_survey_id: extSurveyId });
  };

  return { canCreatePlan, createMediaPlan, isLoadingPlan, mediaPlan: planData?.rawMediaPlan };
};
