import * as Yup from 'yup';
import {InputSectionProps} from "./generate-survey.types";

const FORM_SECTIONS: InputSectionProps[] = [
  {
    title: 'Goal',
    subtitle: 'What problem, topic area, or key questions are you looking to explore with this survey?',
    placeholder: 'Exploring budgeting habits: “How often do you budget?” “What tools do you use for tracking expenses?”',
    field: 'problem',
  },
  {
    title: 'Solution',
    subtitle: 'Are there specific outcomes, products, or services you’d like to assess or learn about?',
    placeholder: 'We want to assess how often people use budgeting apps and their satisfaction levels.',
    field: 'solution',
  },
  {
    title: 'Audience',
    subtitle: 'Who is the ideal respondent for this survey? Describe the target personas or demographics.',
    placeholder: 'Adults aged 25-45, living in urban areas.',
    field: 'audience',
  },
];

const GENERATE_SURVEY_SCHEMA = Yup.object({
  problem: Yup.string()
    .required('Goal is required'),
  solution: Yup.string(),
  audience: Yup.string(),
});

const GENERATION_LOADING_STEPS = [
  'Assembling AI council',
  'Constructing chain of thought',
  'Adding snazz to title',
  'Generating questions',
  'Increasing pizzazz levels',
  'Building audience',
  'Preparing editor',
];
const GENERATION_LOADING_TITLE = 'Generating survey based on your inputs.';
const GENERATION_INTERVAL = 5000;

export { FORM_SECTIONS, GENERATE_SURVEY_SCHEMA, GENERATION_LOADING_STEPS, GENERATION_LOADING_TITLE, GENERATION_INTERVAL };
