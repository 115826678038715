import { Form, Formik } from "formik";
import { resetPasswordSchema } from "../../../constants/validation.constants";
import { B2BButton } from "../../b2bbutton/b2bbutton";
import Input from "../../input/input";


interface ResetPasswordFormProps {
  onSubmit: (values: {password: string, passwordCheck: string}) => Promise<any> | void;
}

const ResetPasswordForm = ({onSubmit}: ResetPasswordFormProps) => {

  const initialValues = {
    password: '',
    passwordCheck: '',
  };

  const handleSubmit = async(values:any, actions:any) => {
    try {
      await onSubmit(values);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={resetPasswordSchema}
      onSubmit={handleSubmit}
      validateOnBlur={true}
    >
      {({isValid, dirty, isSubmitting}) => (
        <Form>
          <div className="mb-4">
            <Input
              id="password"
              placeholder="New password"
              name="password"
              type="password"
            />
          </div>
          <div className="mb-4">
            <Input
              id="passwordCheck"
              placeholder="Re-enter new password"
              name="passwordCheck"
              type="password"
            />
          </div>
          <B2BButton
            disable={!isValid || !dirty}
            expand={true}
            label="Reset password"
            theme="dark"
            isLoading={isSubmitting}
            onClick={() => {}}
            type="submit"
          />
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;