import {GenerateSurveyFormProps, GenerateSurveyFormValues, InputSectionProps} from "./generate-survey.types";
import { useFormik} from "formik";
import {FORM_SECTIONS, GENERATE_SURVEY_SCHEMA} from "./generate-survey.constants";
import {B2BButton} from "../../components/b2bbutton/b2bbutton";
import {InputTextarea} from "primereact/inputtextarea";

const GenerateSurveyForm = ({ isLoading, onSubmit }: GenerateSurveyFormProps) => {
  const initialValues: GenerateSurveyFormValues = {
    problem: '',
    solution: '',
    audience: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onSubmit(values);
    },
    validateOnBlur: true,
    validationSchema: GENERATE_SURVEY_SCHEMA,
  });

  const renderInputSection = (index: number, { field, subtitle, placeholder, title}: InputSectionProps) => {
    return (
      <div
        className="flex flex-row gap-4 mt-8"
        key={index}
      >
        <div className="flex flex-col" >
          <div className="flex justify-center items-center border border-gray-light-10 size-8 rounded-full" >
            {index + 1}
          </div>
        </div>
        <div className="flex flex-col grow gap-2 mt-[5px]">
          <div className="text-base font-bold text-black">
            {title}
          </div>
          <div className="text-sm text-status-gray min-w-0 max-w-[400px] text-wrap">
            {subtitle}
          </div>
          <InputTextarea
            className="w-full border-gray-light-10 border rounded-lg p-2 h-[88px] shadow-sm lg:w-[400px] resize-none text-sm"
            id={field}
            name={field}
            placeholder={placeholder}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[field]}
          />
          {formik.touched[field] && formik.errors[field] ? (
            <div className="text-red-500 text-sm mt-1 min-w-0 max-w-[400px] text-wrap">
              {formik.errors[field]}
            </div>
          ) : null
          }
        </div>
      </div>
    );
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {FORM_SECTIONS.map((section, index) => renderInputSection(index, section))}
      <div className="mt-8 ml-12">
        <B2BButton
          label={'Generate survey'}
          isLoading={isLoading}
          disable={!formik.isValid || !formik.dirty || isLoading}
          expand
          theme="dark"
          type="submit"
        />
      </div>
    </form>
  );
};

export default GenerateSurveyForm;
