import {invisiblyApi} from "../api/invisibly-api";
import {Survey, SurveyJSON} from "../survey/survey.types";
import {GenerateSurveyFormValues} from "../../pages/generate-survey/generate-survey.types";
import {InvisiblyApiResponse} from "../../services/api/api-response.types";
import SurveyService from "../../services/surveys/survey.service";
import {surveyEndpoints} from "../survey/survey.endpoints";

const aiEndpoints = invisiblyApi.injectEndpoints({
  endpoints: (builder) => ({
    generateAiSurvey: builder.mutation<Survey, GenerateSurveyFormValues>({
      query: (data) => ({
        data: data,
        method: 'post',
        url: 'ai/b2b/survey/create',
      }),
      transformResponse(response: InvisiblyApiResponse<SurveyJSON>) {
        return SurveyService.parseListSurvey(response.item);
      },
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const ext_survey_id = data.extSurveyId;

          dispatch(
            surveyEndpoints.util.updateQueryData('getSurvey', { ext_survey_id }, (draft) => {
              Object.assign(draft, data);
            })
          );
        } catch (error) {
          console.error('Error generating survey:', error);
        }
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGenerateAiSurveyMutation } = aiEndpoints;
export { aiEndpoints };
