interface ProfileMenuProps {
  onProfileClick: () => void;
  onLogoutClick: () => void;
}

const ProfileMenu = ({ onProfileClick, onLogoutClick }: ProfileMenuProps) => {
  return (
    <div className="absolute right-0 top-5 w-40 rounded-md bg-white shadow-lg">
      <div className="py-1">
        <button
          onClick={onProfileClick}
          className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
        >
          Profile
        </button>
        <button
          onClick={onLogoutClick}
          className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
        >
          Log out
        </button>
      </div>
    </div>
  );
};

export default ProfileMenu;
