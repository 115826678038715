import { SurveyAudienceCriteria } from "../../redux/survey/survey.types";
import {toCityCase} from "../../helpers/string.helpers";

export interface AttributeOption {
  label: string;
  value: any;
}
export interface Attribute {
  label: string;
  value: string;
  options: AttributeOption[];
}
export const convertToAttributes = (criteria: SurveyAudienceCriteria): Attribute[] => {
  return Object.entries(criteria)
    .filter(([key]) => key !== 'id' && key !== 'totalUsers')
    .map(([label, values]) => ({
      label,
      value: label,
      options: Array.isArray(values)
        ? values.map((value: string) => ({ label: value, value }))
        : [{ label: values as string, value: values }],
    }));
};

export const generateOptionsString = (options: AttributeOption[], attributeValue: string, attributeOverride: Attribute | undefined = undefined): string => {
  return options
    .map(({ value }) => value)
    .map(option => attributeValue === 'DMA' ? attributeOverride?.options.find(opt => opt.value === option)?.label ?? toCityCase(option) : option)
    .join(', ');
};

export interface AttributeResult {
  value: string;
  options: { value: any; count: number[] }[];
}
export const localAttributes: Attribute[] = [
  {
    label: 'Education',
    value: 'Education',
    options: [
      { label: 'High school diploma', value: 'High school diploma' },
      { label: 'Some college no degree', value: 'Some college no degree' },
      { label: 'Associate\'s Degree', value: 'Associate\'s degree' },
      { label: 'Bachelor\'s Degree', value: 'Bachelor\'s degree' },
      { label: 'Master\'s Degree', value: 'Master\'s degree' },
      { label: 'Doctoral Degree', value: 'Doctoral degree' },

    ],
  },
  {
    label: 'Employment',
    value: 'Employment',
    options: [
      { label: 'Employed full time', value: 'Employed full time' },
      { label: 'Employed part-time', value: 'Employed part-time' },
      { label: 'Self employed', value: 'Self employed' },
      { label: 'Unemployed', value: 'Unemployed' },
      { label: 'Retired', value: 'Retired' },
      { label: 'Student', value: 'Student' },
      { label: 'Stay at home parent', value: 'Stay at home parent' },
    ],
  },
  {
    label: 'Household income',
    value: 'Household income',
    options: [
      { label: 'Less than $25,000', value: 'Less than $25,000' },
      { label: '$25,000 - $49,999', value: '$25,000-$49,999' },
      { label: '$50,000 - $74,999', value: '$50,000-$74,999' },
      { label: '$75,000 - $99,999', value: '$75,000-$99,999' },
      { label: '$100,000 - $150,000', value: '$100,000-$150,000' },
      { label: '$150,000 - $200,000', value: '$150,000-$200,000' },
      { label: '$200,000 - $299,000', value: '$200,000 - $299,000' },
      { label: '$300,000 or more', value: '$300,000 or more' },

    ],
  },
  {
    label: 'Marital status',
    value: 'Marital status',
    options: [
      { label: 'Single', value: 'Single' },
      { label: 'Married', value: 'Married' },
      { label: 'Divorced', value: 'Divorced' },
      { label: 'Domestic partner', value: 'Domestic partner' },
      { label: 'Widowed', value: 'Widowed' },
    ],
  },
  {
    label: 'Children',
    value: 'Children',
    options: [
      { label: 'None', value: 'None' },
      { label: 'One', value: 'One' },
      { label: 'Two', value: 'Two' },
      { label: 'Three', value: 'Three' },
      { label: 'Four or more', value: 'Four or more' },
    ],
  },
  {
    label: 'Pets',
    value: 'Pets',
    options: [
      { label: 'No', value: 'No' },
      { label: 'Dog', value: 'Dog' },
      { label: 'Cat', value: 'Cat' },
      { label: 'Fish', value: 'Fish' },
      { label: 'Bird', value: 'Bird' },
      { label: 'Reptile', value: 'Reptile' },
      { label: 'Other', value: 'Other' },
    ],
  },
  {
    label: 'Ethnicity',
    value: 'Ethnicity',
    options: [
      { label: 'White', value: 'White' },
      { label: 'Black or African American', value: 'Black or African American' },
      { label: 'Asian or South-Asian', value: 'Asian or South-Asian' },
      { label: 'Arab', value: 'Arab' },
      { label: 'Multiracial', value: 'Multiracial' },
      { label: 'Native American/American Indian', value: 'Native American/American Indian' },
      { label: 'Hispanic or Latino', value: 'Hispanic or Latino' },
      { label: 'Other', value: 'Other' },
    ],
  },
  // Hidden at the request of our overlord in ENG-4523
  // {
  //   label: 'Place of origin',
  //   value: 'Place of origin',
  //   options: [
  //     { label: 'United States', value: 'United States' },
  //     { label: 'Canada', value: 'Canada' },
  //     { label: 'Mexico', value: 'Mexico' },
  //     { label: 'India', value: 'India' },
  //     { label: 'China', value: 'China' },
  //     { label: 'Europe', value: 'Europe' },
  //     { label: 'Other', value: 'Other' },
  //   ],
  // },
  {
    label: 'Political affiliation',
    value: 'Political affiliation',
    options: [
      { label: 'Democrat', value: 'Democrat' },
      { label: 'Republican', value: 'Republican' },
      { label: 'Independent', value: 'Independent' },
      { label: 'Other', value: 'Other' },
    ],
  },
  {
    label: 'Registered to vote',
    value: 'Registered to vote',
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ],
  },
  {
    label: 'Gender',
    value: 'Gender',
    options: [
      { label: 'Male', value: 'Male' },
      { label: 'Female', value: 'Female' },
      { label: 'Non-Binary', value: 'Non-Binary' },
      { label: 'Other', value: 'Other' },
      { label: 'Prefer not to say', value: 'Prefer not to say' },
    ],
  },
  {
    label: 'Age Group',
    value: 'Age Group',
    options: [
      { label: '18-24', value: '18-24' },
      { label: '25-34', value: '25-34' },
      { label: '35-44', value: '35-44' },
      { label: '45-54', value: '45-54' },
      { label: '55-64', value: '55-64' },
      { label: '65+', value: '65+' },
    ],
  },
];
