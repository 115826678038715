import {ApiStatus, ApiStatusData, HttpResponse} from '../services/api/api-response.types';
import { ServerResponseEnum } from '../services/api/invisibly-api.constants';

export function isValidObject(value: unknown): value is any {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

export function isApiStatusData(response: unknown): response is ApiStatusData {
  return isValidObject(response) && 'status_code' in response && 'status_message' in response && 'status_type' in response;
}

export function isInvisiblyResponse(response: HttpResponse<any> | ApiStatus | unknown): response is ApiStatus {
  return isValidObject(response) && 'status' in response && isApiStatusData(response.status);
}

export function checkIsNetworkCallSuccess(response: HttpResponse<any> | ApiStatus, successCodes: number[] = []): boolean {
  if (isInvisiblyResponse(response)) {
    return response.status.status_code === ServerResponseEnum.SUCCESS_CODE || successCodes.includes(response.status.status_code);
  }

  const nestedStatusCode = (response as HttpResponse<any>)?.status.status_code;

  return nestedStatusCode === ServerResponseEnum.SUCCESS_CODE || successCodes.includes(nestedStatusCode);
}
