import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { en } from './locales/en';

// @ts-expect-error something
i18n.use(LanguageDetector).use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
  },
  debug: false,
  fallbackLng: 'en', // Default language
  interpolation: {
    escapeValue: false, // React already safes from xss
  },
  detection: {
    order: ['queryString', 'cookie'],
    cache: ['cookie'],
  },
});

export default i18n;
