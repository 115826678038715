import { useEffect, useState, useCallback, useRef } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import Input from "../../input/input";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from '../../../redux/redux-store';
import { DropdownInput } from '../../dropdown/dropdown';
import { roles, companySizes } from '../dropdown-settings';
import { ProfileAvatar } from '../../avatar/profile-avatar';
import { FadeLoader } from 'react-spinners';
import { B2BUser } from '../../../services/user/user.types';
import { debounce } from 'lodash';
import { editUserProfile } from "../../../redux/user/user.async-thunks";
import { userProfileSchema } from '../../../constants/validation.constants';
import { setSaveStateWithDelay } from '../../../redux/survey/survey-status.utils';
import { SaveStatusEnum } from '../../../redux/survey/survey.types';
import SaveStatus from '../../save-status/save-status';
import { setSaveState } from '../../../redux/survey/survey-status.slice';
import { handleEditProfileEvent } from '../../../services/analytics/analytics.helpers';

const UpdateForm = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state: RootState) => state.userState);

  const initialValues = {
    firstName: userState.firstName || '',
    lastName: userState.lastName || '',
    role: userState.role || '',
    companyName: userState.companyName || '',
    companySize: userState.companySize || '',
    setupProfile: userState.setupProfile || true,
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(false);
  }, [userState]);

  const debouncedEditUserProfile = useCallback(
    debounce((values: B2BUser, isValid: boolean) => {
      if (isValid && userState.extUserId) {
        // @ts-expect-error not sure about this one
        dispatch(editUserProfile({
          data: values,
        }));
        setSaveStateWithDelay(dispatch);
      }
    }, 2000),
    [userState.extUserId, dispatch]
  );

  const FormChangeHandler = ({ values }: { values: B2BUser }) => {
    const { isValid } = useFormikContext<B2BUser>();
    const previousValues = useRef<B2BUser>(values);

    useEffect(() => {
      const hasValuesChanged = JSON.stringify(values) !== JSON.stringify(previousValues.current);
      if (hasValuesChanged) {
        handleEditProfileEvent(values, previousValues.current);
        dispatch(setSaveState({ status: SaveStatusEnum.Syncing }));
        debouncedEditUserProfile(values, isValid);
        previousValues.current = values;
      }
    }, [values, debouncedEditUserProfile, isValid]);

    return null;
  };

  return (
    <div className='relative w-80 micro:w-64'>
      {isLoading ? (
        <div className="align-items my-[14.5rem] flex items-center justify-center">
          <FadeLoader
            color="#667085"
            height={10}
            width={3}
            margin={1}
          />
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={userProfileSchema}
          onSubmit={() => { }}
          validateOnBlur={true}
        >
          {({ values }) => (
            <Form>
              <FormChangeHandler
                values={{
                  first_name: values.firstName,
                  last_name: values.lastName,
                  role: values.role,
                  company_name: values.companyName,
                  company_size: values.companySize,
                  setup_profile: true,
                }}
              />
              <div className="mb-2">
                <ProfileAvatar />
              </div>
              <div className="w-full">
                <div className="flex-column mb-4 gap-2">
                  <label
                    className="text-sm font-normal"
                    htmlFor="firstName"
                  >
                    First name
                  </label>
                  <div className="mb-4 w-full">
                    <Input
                      id="firstName"
                      className="pb-2 pl-0 pt-0 text-left text-base font-normal"
                      name="firstName"
                      type="text"
                    />
                  </div>
                </div>
                <div className="flex-column mb-4 gap-2">
                  <label
                    className="text-sm font-normal"
                    htmlFor="lastName"
                  >
                    Last name
                  </label>
                  <div className="mb-4 w-full">
                    <Input
                      id="lastName"
                      className="pb-2 pl-0 pt-0 text-left text-base font-normal"
                      name="lastName"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="flex-column mb-4 gap-2">
                <label
                  className="text-sm font-normal"
                  htmlFor="role"
                >
                  Role
                </label>
                <div className="mb-4">
                  <DropdownInput
                    values={roles}
                    placeholder="Your role"
                    initialValue={initialValues.role}
                    className="pb-2 pl-0 pt-0 text-left text-base font-normal"
                    id="role"
                    name="role"
                  />
                </div>
                <div className="flex-column mb-4 gap-2">
                  <label
                    className="text-sm font-normal"
                    htmlFor="companyName"
                  >
                    Company name
                  </label>
                  <div className="mb-4 w-full">
                    <Input
                      id="companyName"
                      className="pb-2 pl-0 pt-0 text-left text-base font-normal"
                      name="companyName"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="flex-column gap-2">
                <label
                  className="text-sm font-normal"
                  htmlFor="companySize"
                >
                  Company size
                </label>
                <DropdownInput
                  values={companySizes}
                  placeholder="Company size"
                  initialValue={initialValues.companySize}
                  className="pb-2 pl-0 pt-0 text-left text-base font-normal"
                  id="companySize"
                  name="companySize"
                />
              </div>
              <div className="flex w-full mb-1 items-center justify-end">
                <SaveStatus />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};


export default UpdateForm;
