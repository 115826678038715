import React from 'react';
import blackTrash from '../../assets/icons/blackTrash.svg';
import { useGetSurveyQuery } from '../../redux/survey/survey.endpoints';
import { SurveyStatus } from '../../redux/survey/survey.types';
import QuestionSort from "../question-sort/question-sort";
// import clone from '../../assets/icons/clone.svg';
export interface QuestionHeaderProps {
  extSurveyId: string;
  number: number;
  onDuplicate: () => void;
  onDelete: () => void;
}

const QuestionHeader: React.FC<QuestionHeaderProps> = ({ extSurveyId, number, onDuplicate, onDelete }) => {
  const { data } = useGetSurveyQuery(
    { ext_survey_id: extSurveyId },
    { skip: !extSurveyId }
  );
  return (
    <div className="mb-2 px-2">
      <div className="flex items-center">
        <div className="text-lg font-thin text-gray-500">
          {number}
        </div>
        <div className="text-md ml-5 font-semibold">
          Question
        </div>
        <div className="ml-auto mr-4 flex">
          {data?.surveyStatus !== SurveyStatus.Review && (
            <div className="flex flex-row">
              <QuestionSort extSurveyId={extSurveyId} />
              {/* <button
                aria-label="Duplicate"
                className="rounded px-2 py-1 text-black hover:bg-gray-200"
                onClick={onDuplicate}
                draggable={false}
              >
                <img src={clone} alt="" className="h-5" />
              </button>  */}
              <div className="relative group">
                <button
                  aria-label="Delete"
                  className="rounded px-2 py-1  h-full text-black hover:bg-gray-200 cursor-pointer"
                  onClick={onDelete}
                >
                  <img
                    src={blackTrash}
                    alt="Delete"
                    className="h-4"
                    draggable={false}
                  />
                </button>
                <div className="absolute right-0 bottom-0 translate-x-full translate-y-full hidden group-hover:block bg-gray-200 text-gray-700 text-xs rounded py-1 px-2 whitespace-nowrap">
                  Delete question
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionHeader;
