import { Dispatch } from 'redux';
import { setSaveState } from './survey-status.slice';
import { SaveStatusEnum } from './survey.types';

const { JustSynced, Synced } = SaveStatusEnum;
let timeoutId: NodeJS.Timeout | null = null;

export const setSaveStateWithDelay = (
  dispatch: Dispatch,
  immediateStatus: SaveStatusEnum = JustSynced,
  delayedStatus: SaveStatusEnum = Synced,
  delay: number = 5000
) => {
  dispatch(setSaveState({ status: immediateStatus }));

  if (timeoutId) {
    clearTimeout(timeoutId);
  }
  timeoutId = setTimeout(() => {
    dispatch(setSaveState({ status: delayedStatus }));
    timeoutId = null;
  }, delay);
};
