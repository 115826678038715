import trashIcon from '../../assets/icons/blackTrash.svg';
import { Dialog } from 'primereact/dialog';
import '../prime-react/progress-spinner.css';
import AnalyticsManager from '../../services/analytics/analytics.service';
import { AnalyticEventsEnum } from '../../services/analytics/analytic-event-enum';

interface DeleteSurveyDialogProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  deleteSurvey: () => void;
}

const DeleteSurveyDialog: React.FC<DeleteSurveyDialogProps> = ({ deleteSurvey, isModalOpen, handleCloseModal }: DeleteSurveyDialogProps) => {
  const handleDeleteSurvey = () => {
    AnalyticsManager.trackEvent(AnalyticEventsEnum.TapDeleteSurvey);
    deleteSurvey();
  };

  const renderFooter = () => (
    <div className="flex flex-col justify-center mb-2">
      <button
        onClick={handleDeleteSurvey}
        className="p-2 mb-2 px-4 bg-black text-white shadow-md rounded-3xl"
      >
        Delete
      </button>
      <button
        onClick={handleCloseModal}
        className="p-2 px-4 bg-white  rounded-3xl"
      >
        Cancel
      </button>
    </div>
  );

  return (
    <Dialog
      visible={isModalOpen}
      style={{ width: '460px' }}
      onHide={handleCloseModal}
      footer={renderFooter()}
      draggable={false}
      resizable={false}
      header={null}
      closable={false}
    >
      <div className="flex flex-col items-center p-3 py-0 overflow-hidden">
        <img
          className="w-6"
          src={trashIcon}
          alt="icon"
        />
        <div className="mt-4 text-3xl text-black text-center">
          Are you sure you want to delete this survey?
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteSurveyDialog;
