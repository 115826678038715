import {IFirebaseService, RemoteConfigProps} from "./firebase.types";
import { FirebaseApp, initializeApp } from '@firebase/app';
import { RemoteConfig, getRemoteConfig, getValue, fetchAndActivate } from '@firebase/remote-config';
import {firebaseConfig} from "../../constants/config";
import {REMOTE_CONFIG_DEFAULTS} from "./firebase.constants";
import {logger} from "../error-log/decorators/logger.decorator";

class FirebaseService implements IFirebaseService {
  private static instance: FirebaseService | undefined;
  private app: FirebaseApp | undefined;
  private remoteConfig: RemoteConfig | undefined;
  private hasInitialized = false;

  static getInstance(): FirebaseService {
    if (!FirebaseService.instance) {
      FirebaseService.instance = new FirebaseService();
    }

    return FirebaseService.instance;
  }

  public async init(): Promise<void> {
    this.app = initializeApp(firebaseConfig);
    await this.initRemoteConfig();
  }

  private async initRemoteConfig(): Promise<void> {
    this.remoteConfig = getRemoteConfig(this.app);
    this.remoteConfig.defaultConfig = REMOTE_CONFIG_DEFAULTS;

    await fetchAndActivate(this.remoteConfig);
    this.hasInitialized = true;
  }

  @logger('')
  public getRemoteConfigString(key: keyof RemoteConfigProps): string {
    return getValue(this.remoteConfig as RemoteConfig, key as string)
      .asString();
  }

  @logger(false)
  public getRemoteConfigBool(key: keyof RemoteConfigProps): boolean {
    return getValue(this.remoteConfig as RemoteConfig, key as string)
      .asBoolean();
  }

  @logger(0)
  public getRemoteConfigNumber(key: keyof RemoteConfigProps): number {
    return getValue(this.remoteConfig as RemoteConfig, key as string)
      .asNumber();
  }

  @logger({})
  public getRemoteConfigJSON<T>(key: keyof RemoteConfigProps): T {
    if (!this.hasInitialized && REMOTE_CONFIG_DEFAULTS[key] !== undefined) {
      return JSON.parse(REMOTE_CONFIG_DEFAULTS[key] as string) as T;
    }
    const configValueString = getValue(this.remoteConfig as RemoteConfig, key as string)
      .asString();

    if (!configValueString && REMOTE_CONFIG_DEFAULTS[key] !== undefined) {
      return JSON.parse(REMOTE_CONFIG_DEFAULTS[key] as string) as T;
    }

    return JSON.parse(configValueString) as T;
  }
}

export default FirebaseService.getInstance();
