import {ChipPassThroughOptions} from "primereact/chip";

const RESULT_CHIP_PASS_THROUGH_OPTIONS: ChipPassThroughOptions = {
  label: {
    className: 'text-sm',
  },
  root: {
    className: 'bg-custom-gray',
  },
};
const MAX_ANSWER_COUNT = 8;

const TEST_MARKDOWN = `
# **EXECUTIVE SUMMARY**
**Invisibly** seeks to expand its client base by showcasing its unique market research capabilities that combine human and synthetic audiences. Utilizing recent survey data on the growing interest in walkable cities, we propose a targeted digital and programmatic media campaign aimed at marketing professionals and decision-makers in relevant industries. The campaign focuses on emphasizing Invisibly's ability to generate valuable insights and activate them through personalized media plans, highlighting the broad support for walkable cities as a significant market trend.
---
&nbsp;
### TOTAL BUDGET: $10,000
### TOTAL IMPRESSIONS: 475,000
---
&nbsp;
# PLAN SUMMARY
&nbsp;
#### MEDIA CHANNEL: LinkedIn
- • **Flight Dates:** November 1 – November 30, 2023
- • **Budget:** $5,000
- • **CPM:** $40
- • **Impressions:** 125,000
- • **Notes:** Target marketing professionals such as Market Research Managers, Marketing Directors, Brand Managers, and Advertising Agency Executives in the United States. Utilize LinkedIn's precise professional targeting to reach decision-makers interested in innovative market research solutions. Messaging will emphasize Invisibly's unique combination of human and synthetic audiences to deliver deep market insights, using the walkable cities trend as a compelling example.

&nbsp;
#### MEDIA CHANNEL: Digital Display Ads
- • **Flight Dates:** November 1 – November 30, 2023
- • **Budget:** $2,000
- • **CPM:** $8
- • **Impressions:** 250,000
- • **Notes:** Place ads on industry-specific websites and publications frequented by marketing professionals, such as AdAge, Marketing Week, and Marketing Dive. Utilize programmatic advertising to target users based on their professional interests and behaviors. Showcase case studies and insights from the walkable cities survey to demonstrate Invisibly's expertise in generating commercially valuable insights.
---
&nbsp;
# TARGET AUDIENCE

- • **Demographic:** Marketing professionals, market research managers, brand managers, and advertising agency executives in the United States.
- • **Industries:** Consumer Goods, Retail, Technology, Urban Development, and others interested in market trends.
- • **Interests:** Professionals seeking innovative market research solutions that offer deep insights through combined human and synthetic audiences.

---
&nbsp;
# RECOMMENDATIONS

- • Emphasize **Invisibly's unique value proposition** of blending human and synthetic audiences to deliver robust and actionable market insights.
- • Highlight the significant interest in **walkable cities** across diverse demographics as a current and relevant market trend.
- • Develop **hyper-personalized messaging** that resonates with different personas identified in the survey, addressing both the enthusiasm for walkable cities and concerns about practicality and cost.
- • Utilize **frequency caps** and **data analytics** to optimize engagement and avoid oversaturation, ensuring compliance with **GDPR and CCPA** standards.
- • Consider expanding the campaign to include **thought leadership content**, such as whitepapers or webinars on leveraging market research for targeted advertising, to further engage the target audience.
`;

export { MAX_ANSWER_COUNT, RESULT_CHIP_PASS_THROUGH_OPTIONS, TEST_MARKDOWN };
