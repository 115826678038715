
import {
  Survey, SurveyAnswer, SurveyAnswerJSON,
  SurveyDetail,
  SurveyDetailJSON,
  SurveyJSON,
  SurveyModerationResult,
  SurveyModerationResultJSON,
  SurveyQuestion,
  SurveyQuestionJSON, SurveyQuestionType,
} from "../../../redux/admin/survey/survey.types";
import { ChartData } from "../../../components/chart";

interface ISurveyService {
  readonly parseListSurvey: (json: SurveyJSON) => Survey
  readonly parseSurveyDetails: (json: SurveyDetailJSON) => SurveyDetail
  readonly parseSurveyModerationResults: (json: SurveyModerationResultJSON) => SurveyModerationResult
}

class SurveyService implements ISurveyService {
  static instance: SurveyService;

  public static getInstance(): ISurveyService {
    if (!SurveyService.instance) {
      SurveyService.instance = new SurveyService();
    }

    return SurveyService.instance;
  }

  public parseSurveyModerationResults({ created_at, updated_at, survey_moderation_result, is_moderated, is_reviewed, is_paid, is_rejected, feedback }: SurveyModerationResultJSON): SurveyModerationResult {
    return {
      createdAt: created_at,
      updatedAt: updated_at,
      surveyModerationResult: survey_moderation_result,
      isModerated: is_moderated,
      isReviewed: is_reviewed,
      isPaid: is_paid,
      isRejected: is_rejected,
      feedback,
    };
  }

  public parseListSurvey({ created_at, created_by, completed_users, ext_survey_id, id, points, title, category, image_url, total_users, start_date, end_date, rank, is_published, survey_status, has_review, origin }: SurveyJSON): Survey {
    const attemptedCount = total_users > 0 ? total_users : completed_users;
    const percentCompleted = completed_users > 0 ? (completed_users / attemptedCount) * 100 : undefined;
    return {
      attemptedCount,
      completedCount: completed_users,
      createdAt: created_at,
      createdBy: created_by,
      extSurveyId: ext_survey_id,
      id,
      percentCompleted,
      points,
      title,
      category,
      imageUrl: image_url,
      startDate: start_date,
      endDate: end_date,
      rank: rank,
      isPublished: is_published,
      surveyStatus: survey_status,
      hasReview: has_review,
      origin: origin,
    };
  }

  parseSurveyDetails(json: SurveyDetailJSON): SurveyDetail {
    return {
      ...this.parseListSurvey(json),
      questions: json.questions.map(question => this.parseSurveyQuestion(question)),
    };
  }

  parseSurveyQuestion({
    answers, id, points, response_count = 100, title, type, category, image_url,
  }: SurveyQuestionJSON): SurveyQuestion {
    const questionType = type as SurveyQuestionType;

    const parsedAnswers = answers.map(ans => this.parseSurveyAnswer(ans, response_count));

    return {
      answers: parsedAnswers,
      id,
      points: points !== null ? parseInt(points) : 0,
      responseCount: response_count,
      title,
      category,
      imageUrl: image_url,
      questionType,
      chartData: this.chartData(parsedAnswers),
    };
  }

  parseSurveyAnswer({ id, answer_branch, display_order, is_final_question, title, user_count, user_percentage }: SurveyAnswerJSON, totalCount: number): SurveyAnswer {
    return {
      answerBranch: answer_branch ?? undefined,
      displayOrder: display_order,
      id,
      isFinal: is_final_question,
      answerPercentage: Math.round(user_percentage ? user_percentage : 0),
      answerCount: user_count ? user_count : 0,
      title,
    };
  }

  chartData(answers: SurveyAnswer[]): ChartData[] {
    return answers.map(ans => ({ label: ans.title, value: ans.answerCount.toString() }));
  }
}

export default SurveyService.getInstance();
