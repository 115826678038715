/* eslint-disable @typescript-eslint/no-magic-numbers */
export enum ServerResponseEnum {
  SUCCESS_CODE = 1001,
  USER_EMAIL_OR_PASSWORD_NOT_CORRECT = 1113,
  USER_DOES_NOT_EXIST = 1115,
  PROFILE_QUESTION_ALREADY_ANSWERED = 1149,
  EMAIL_DOES_NOT_EXIST = 1151,
  ACCOUNT_WAS_DELETED = 1161,
  SIGIN_IN_WITH_SOCIAL_NOT_FROM_NORMAL_EMAIL = 1173,
  INSUFFICIENT_POINTS = 1177,
  UNKNOWN_ERROR = 1101,
  HAVE_EXISTING_ACCOUNT = 1103,
  ACCOUNT_SUSPENDED = 1270,
  RESET_PASSWORD_MUST_BE_DIFFERENT_FROM_OLD_PASSWORD = 1141,
  RESET_PASSWORD_LINK_ONLY_USED_ONCE = 1139,
  /** for OTP verification  */
  VERIFICATION_CODE_INVALID = 1172,
  INPUT_INVALID = 1302,
  EMAIL_ALREADY_VERIFIED = 1183,
  EMAIL_ALREADY_EXISTS_AND_VERIFIED = 1181,
  INVALID_LINK = 1194,
  INVALID_ZIP_CODE = 1203,
  AUTH_NOT_PROVIDED = 1105,
  TOKEN_PERMISSION_ERROR = 1106,
  INVALID_JWT_FORMAT = 1201
}
