/* eslint-disable camelcase */
import InvisiblyApiService from '../api/invisibly-api.service';
import { apiUrl } from '../../constants/config';
import { InvisiblyApiResponse } from '../api/api-response.types';
import { CheckIfDeviceIdExists, ISignInRequest, ISignUpRequest, ISocialLoginRequest } from './auth.types';
import { User } from '../user/user.types';
import { fernetEncrypt } from './auth.helpers';

export interface INameRequest {
  first_name: string;
  fullname: string;
  last_name: string;
}

interface IAuthApiService {
  readonly checkDeviceTokenAccountExists: (deviceId: string) => Promise<{ isAnonymous: boolean, userExists: boolean }>;
  readonly createAnonymousUser: (deviceId: string) => Promise<User>;
  readonly forgotPassword: (email: string) => Promise<void>;
  readonly linkDeviceIdToUserId: (deviceId: string) => Promise<void>;
  readonly resetPassword: (password: string, token: string) => Promise<string | null>;
  readonly setName: (details: INameRequest) => Promise<User | undefined>;
  readonly signIn: (details: ISignInRequest) => Promise<User>;
  readonly signUp: (details: ISignUpRequest) => Promise<User>;
  readonly socialLogin: (details: ISocialLoginRequest) => Promise<User>;
}

class AuthApiService implements IAuthApiService {
  static instance: AuthApiService;

  public static getInstance(): AuthApiService {
    if (!AuthApiService.instance) {
      AuthApiService.instance = new AuthApiService();
    }

    return AuthApiService.instance;
  }

  public async socialLogin(details: ISocialLoginRequest): Promise<User> {
    return await InvisiblyApiService.invokeLoggedPostRequest<User>(`${apiUrl}/users/social-login`, { ...details }, []);
  }

  public async setName(details: INameRequest): Promise<User> {
    return await InvisiblyApiService.invokeLoggedPatchRequest<User>(`${apiUrl}/users/rename`, details);
  }

  public async signIn(details: ISignInRequest): Promise<User> {
    return await InvisiblyApiService.invokeLoggedPostRequest<User>(`${apiUrl}/users/login`, { ...details }, []);
  }

  public async signUp(details: ISignUpRequest): Promise<User> {
    return await InvisiblyApiService.invokeLoggedPutRequest<User>(`${apiUrl}/users/signup`, details);
  }

  public async checkDeviceTokenAccountExists(deviceId: string): Promise<{ isAnonymous: boolean, userExists: boolean }> {
    const encryptedDeviceId: string = fernetEncrypt(deviceId);
    const { anonymous_user, is_exists } = await InvisiblyApiService.invokeLoggedPostRequest<CheckIfDeviceIdExists>(`${apiUrl}/users/check-device-id-exists`, { device_id: encryptedDeviceId });

    return {
      isAnonymous: anonymous_user,
      userExists: is_exists,
    };
  }

  public async createAnonymousUser(deviceId: string): Promise<User> {
    const encryptedDeviceId = fernetEncrypt(deviceId);
    const { item } = await InvisiblyApiService.invokeLoggedPostRequest<InvisiblyApiResponse<User>>(`${apiUrl}/users/get-anonymous-token`, { device_id: encryptedDeviceId });

    return item;
  }

  public async linkDeviceIdToUserId(deviceId: string): Promise<void> {
    const encryptedDeviceId = fernetEncrypt(deviceId);

    return await InvisiblyApiService.invokePostRequest(`${apiUrl}/users/link-device-id`, { device_id: encryptedDeviceId });
  }

  public async forgotPassword(email: string): Promise<void> {
    await InvisiblyApiService.invokePostRequest(`${apiUrl}/users/reset-password-link`, {
      email,
      platform: 'b2b',
    });
  }

  public async resetPassword(password: string, token: string): Promise<string | null> {
    return await InvisiblyApiService.invokeLoggedPutRequest(`${apiUrl}/users/reset-password`, {
      password,
      token,
    });
  }

  public async checkEmailExists(data: { email: string }): Promise<boolean> {
    const item = await InvisiblyApiService.invokeLoggedPostRequest<{ is_exists: boolean }>(`${apiUrl}/users/check-user-exists`, data);

    return item?.is_exists ?? false;
  }

  public async signOut(): Promise<void> {
    await InvisiblyApiService.invokeLoggedPostRequest(`${apiUrl}/users/logout`);
  }
}

export default AuthApiService.getInstance();
