import { Form, Formik } from "formik";
import { Input } from "../../input/input";
import { B2BButton } from "../../b2bbutton/b2bbutton";
import { authSchema } from '../../../constants/validation.constants';

interface CreateAccountFormProps {
  onSubmit: (values: { email: string; password: string }) => Promise<any> | void;
}

interface FormValues {
  email: string;
  password: string;
}

const CreateAccountForm = ({
  onSubmit,
}: CreateAccountFormProps) => {
  const initialValues: FormValues = {
    email: '',
    password: '',
  };

  const handleSubmit = async(values: FormValues, actions: any) => {
    try {
      await onSubmit(values);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={authSchema}
      onSubmit={handleSubmit}
      validateOnBlur={true}
    >
      {({ isValid, dirty, isSubmitting }) => (
        <Form>
          <div className="mb-4">
            <Input
              className="p-2"
              id="email"
              placeholder="Email*"
              name="email"
              type="email"
            />
          </div>
          <div className="mb-4">
            <Input
              id="password"
              placeholder="Password*"
              name="password"
              type="password"
            />
          </div>
          <B2BButton
            disable={!isValid || !dirty}
            isLoading={isSubmitting}
            expand={true}
            label={"Create account"}
            theme="dark"
            onClick={() => {}}
            type="submit"
          />
        </Form>
      )}
    </Formik>
  );
};

export default CreateAccountForm;
