import FirebaseService from "../../services/firebase/firebase.service";
import { SimpleSurveyPricing} from "../../services/firebase/firebase.types";

export interface SimpleSurveyPricingData {
  readonly perRespondentPriceString: string;
  readonly perCriteriaPriceString: string;
  readonly totalString: string;
}

export const simpleSurveyPricing = (humanResponseCount: number, aiResponseCount: number, criteriaCount: number): SimpleSurveyPricingData | undefined => {
  const pricing = FirebaseService.getRemoteConfigJSON<SimpleSurveyPricing>('A_LA_CARTE_PRICING');

  if (!pricing) {
    return undefined;
  }

  const { perRespondentPrice: perRespondentBase, perCriteriaPrice } = pricing;
  const responseCount = humanResponseCount + aiResponseCount;
  const criteriaPrice = criteriaCount * perCriteriaPrice;
  const criteriaPriceString = (criteriaPrice / 100).toFixed(2);
  const perRespondentPrice = perRespondentBase + criteriaPrice;
  const perRespondentPriceString = `$${(perRespondentPrice / 100).toFixed(2)}`;
  const total = perRespondentPrice * responseCount;

  return {
    perRespondentPriceString,
    perCriteriaPriceString: criteriaPriceString,
    totalString: `$${(total / 100).toFixed(2)}`,
  };
};
