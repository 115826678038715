// import BaseLocalization from '../../locales/en';
import errorLogService from '../error-log/error-log.service';
import { UNKNOWN_ERROR_CODE, UsableError } from './error-parsing.types';

interface IErrorParsingService {
  readonly getUsableError: (error: any, defaultMessage?: string) => UsableError;
}

/**
 * String and Unknown should be the only special cases.
 *   String - finally unwrapped to a string, return this as the message
 *   Unknown - something went wrong while parsing, return default error message
 */
enum ErrorMessageFieldName {
  ApiStatus = 'status',
  ApiStatusMessage = 'status_message',
  GoogleAuthDescription = 'description',
  SerializedError = 'message',
  String = 'string',
  Unknown = 'unknown'
}

enum ErrorCodeFieldName {
  ApiStatus = 'status',
  ApiStatusCode = 'status_code',
  Number = 'number',
  Unknown = 'unknown'
}

class ErrorParsingService implements IErrorParsingService {
  private static instance: IErrorParsingService | undefined;

  static getInstance(): IErrorParsingService {
    if (!ErrorParsingService.instance) {
      ErrorParsingService.instance = new ErrorParsingService();
    }

    return ErrorParsingService.instance;
  }

  public getUsableError(error: any, defaultMessage?: string): UsableError {
    return {
      code: this.getErrorCode(error),
      message: this.getErrorMessage(error, defaultMessage),
    };
  }

  private getErrorMessageFieldName(error: any): ErrorMessageFieldName {
    const {
      ApiStatus, ApiStatusMessage, GoogleAuthDescription, SerializedError, String, Unknown,
    } = ErrorMessageFieldName;

    try {
      if (typeof error === 'string') {
        return String;
      }

      if (ApiStatus in error) {
        return ApiStatus;
      }

      if (ApiStatusMessage in error) {
        return ApiStatusMessage;
      }

      if (GoogleAuthDescription in error) {
        return GoogleAuthDescription;
      }

      if (SerializedError in error) {
        return SerializedError;
      }
    } catch (err) {
      errorLogService.logError(error, 'error-parsing.service.ts', 'getErrorMessageFieldName', [error]);
      return Unknown;
    }

    return Unknown;
  }

  private getErrorMessage(error: any, defaultMessage: string = "Something went wrong, try again."): string {
    const { String, Unknown } = ErrorMessageFieldName;
    const type = this.getErrorMessageFieldName(error);

    switch (type) {
      case String:
        return error as string;
      case Unknown:
        return this.getErrorMessageFromArray(error, defaultMessage);
      default:
        return this.getErrorMessage(error[type], defaultMessage);
    }
  }

  /*
    This makes a few assumptions. If any of these assumptions are wrong, it will return the default error message provided:
    1. If an error message wasn't found in the standard places, then it must be in an array
    2. If it's in an array, it'll be the first child of the object
    3. The first item in that array is the error message to display
   */
  private getErrorMessageFromArray(error: any, defaultMessage: string): string {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const keys = Object.keys(error);
      const [firstElement] = keys;
      const message = error[firstElement];

      if (message?.length) {
        if (Array.isArray(message)) {
          return message[0] as string;
        }
        return message as string;
      }
    } catch (err) {
      errorLogService.logError(error, 'error-parsing.service.ts', 'getErrorMessageFromArray', [error, err]);
    }

    return defaultMessage;
  }

  private getErrorCodeFieldName(error: any): ErrorCodeFieldName {
    const { ApiStatus, ApiStatusCode, Number, Unknown } = ErrorCodeFieldName;

    try {
      if (typeof error === 'number') {
        return Number;
      }

      if (ApiStatus in error) {
        return ApiStatus;
      }

      if (ApiStatusCode in error) {
        return ApiStatusCode;
      }
    } catch (err) {
      // Don't logError here. It doesn't matter much and lets us validate locally without reporting.
      return Unknown;
    }

    return Unknown;
  }

  private getErrorCode(error: any): number {
    const { Number, Unknown } = ErrorCodeFieldName;
    const type = this.getErrorCodeFieldName(error);

    switch (type) {
      case Number:
        return error as number;
      case Unknown:
        return UNKNOWN_ERROR_CODE;
      default:
        return this.getErrorCode(error[type]);
    }
  }
}

export default ErrorParsingService.getInstance();
