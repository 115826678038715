import React, { useEffect } from 'react';
import LayoutComponent from '../../components/layouts/layout-login';
import BackgroundImage from '../../assets/videos/invisibly-brand-img.mp4';
import BrandLogo from '../../components/brandLogo/brandLogo';
import { Title } from '../../components/typography/title';
import CreateAccountForm from '../../components/form/createaccountform/createaccountform';
import { SubTitle } from '../../components/typography/subtitle';
import { B2BLink } from '../../components/link/b2blink';
import { B2BAppleButton } from '../../components/brandedbutton/applebutton';
import { useNavigate } from 'react-router-dom';
import { initEmailSignUp } from '../../redux/login/login.async-thunks';
import { getIsUserAuthenticated, getLoginState, getUserB2BOnboardStatus } from '../../redux/user/user.selectors';
import { ReduxState } from '../../redux/redux-state';
import { ConnectedProps, connect, useSelector } from 'react-redux';
import { B2BGoogleButton } from '../../components/brandedbutton/googlebutton';
import { RootState } from '../../redux/redux-store';
import {useSetPageTitle} from "../../hooks/useSetPageTitle";
import Conditions from "../../components/conditions/conditions.component";
import {CreateAccountCopy} from "./create-account.types";
import FirebaseService from "../../services/firebase/firebase.service";

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {}

const CreateAccountPage: React.FC<Props> = ({ isAuthenticated, emailSignUp }: Props) => {
  const { title } = FirebaseService.getRemoteConfigJSON<CreateAccountCopy>('CREATE_ACCOUNT_COPY');
  useSetPageTitle('Create account');
  const navigate = useNavigate();
  const loginState = useSelector((state: RootState) => getLoginState(state));
  const b2bUserIsOnboarded = useSelector((state: RootState) => getUserB2BOnboardStatus(state));

  useEffect(() => {
    // concern here is that we won't know if it is their first login or not
    if (isAuthenticated && !b2bUserIsOnboarded) {
      navigate('/confirm-account');
    } else if (isAuthenticated && b2bUserIsOnboarded) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, loginState, b2bUserIsOnboarded]);

  const submitEmailSignUp = async(values: { email: string; password: string }) => {
    try {
      await emailSignUp(values.email, values.password);
    } finally {
      //handle
    }
  };

  return (
    <LayoutComponent
      imgComponent={(
        <div className='absolute inset-0 overflow-hidden'>
          <video
            className='size-full object-cover'
            autoPlay
            loop
            muted
          >
            <source
              src={BackgroundImage}
              type='video/mp4'
            />
          </video>
        </div>
      )}
      formComponent={(
        <div className='flex h-screen justify-center pt-20'>
          <div className='flex max-w-[25rem] flex-col'>
            <div className='mb-16 justify-start'>
              <BrandLogo expand={true} />
            </div>
            <div className='mb-16'>
              <Title text={title} />
            </div>
            <div className='mb-4'>
              <B2BGoogleButton />
            </div>
            <div>
              <B2BAppleButton />
            </div>
            <div className='mt-1'>
              <SubTitle text='or sign up with email and password' />
            </div>
            <div className='mt-6'>
              <CreateAccountForm onSubmit={submitEmailSignUp} />
            </div>
            <div className='mt-1'>
              <SubTitle text='Already have an account?' />
              <B2BLink
                className='mx-3'
                label='Sign in'
                link='login'
                target='_self'
              />
            </div>
            <div className="grow" />
            <div className='mb-4 text-center'>
              <Conditions />
            </div>

          </div>
        </div>
      )}
    />
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  emailSignUp: (email: string, password: string) => dispatch(initEmailSignUp({
    email,
    password,
  })),
});

const mapStateToProps = (state: ReduxState) => ({
  isAuthenticated: getIsUserAuthenticated(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CreateAccountPage);
