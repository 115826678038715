/* eslint-disable @typescript-eslint/no-magic-numbers,@typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment,@typescript-eslint/no-unsafe-argument */
const localEncryption = 'Invisibly_#_App_Mobile';

import { functionLogger } from '../error-log/error-log.utils';

export const encryptionString = (plainText: string): string => {
  const myCipher = cipher(localEncryption);

  // @ts-ignore
  decryptionString(myCipher(plainText));
  // @ts-ignore
  return myCipher(plainText);
};

export const decryptionString = (): void => {
  functionLogger(decipher)(localEncryption);
};

const cipher = (salt: any): string => {
  const textToChars = (text: string): number[] => text.split('').map((_c) => _c.charCodeAt(0));
  const byteHex = (_n: string): string => `0${Number(_n).toString(16)}`.substr(-2);
  // eslint-disable-next-line no-bitwise
  const applySaltToChar = (code: any): number => textToChars(salt).reduce((textA, textB) => textA ^ textB, code);

  // @ts-ignore
  return (text) => text.split('').map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join('');
};

const decipher = (salt: any): string => {
  const textToChars = (text: string): number[] => text.split('').map((_c) => _c.charCodeAt(0));
  // @ts-ignore
  // eslint-disable-next-line no-bitwise
  const applySaltToChar = (code: string): number => textToChars(salt).reduce((textA, textB) => textA ^ textB, code);

  // @ts-ignore
  return (encoded) => encoded
    .match(/.{1,2}/g)
    .map((hex: string) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode: number) => String.fromCharCode(charCode))
    .join('');
};
