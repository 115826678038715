import { ReactElement, useEffect, useState } from "react";

interface TextByCharBaseProps {
  readonly text: string;
  readonly textClassName?: string;
}

export interface TextByCharCompletionProps extends TextByCharBaseProps {
  readonly onComplete: (index: number) => void;
  readonly index: number;
}

export interface TextByCharNoCompletionProps extends TextByCharBaseProps {
  readonly onComplete?: never;
  readonly index?: never;
}

export type TextByCharProps = TextByCharNoCompletionProps | TextByCharCompletionProps;

const TextByChar = ({ index, onComplete, text, textClassName }: TextByCharProps): ReactElement => {
  const [displayResponse, setDisplayResponse] = useState("");

  useEffect(() => {
    let i = 0;

    const intervalId = setInterval(() => {
      setDisplayResponse(text.slice(0, i));

      i++;

      if (i > text.length) {
        clearInterval(intervalId);
        onComplete && onComplete(index);
      }
    }, 15);

    return () => clearInterval(intervalId);
  }, [text]);

  return (
    <div>
      <span className={`whitespace-pre-line ${textClassName ? textClassName : ''}`}>
        {displayResponse}
      </span>
    </div>
  );
};

export default TextByChar;
