import * as Yup from 'yup';

export const userProfileSchema = Yup.object({
  firstName: Yup.string()
    .matches(/^[^0-9]*$/, 'The field cannot contain numbers')
    .min(2, 'Must be at least 2 characters')
    .required('First name is required'),
  lastName: Yup.string()
    .matches(/^[^0-9]*$/, 'The field cannot contain numbers')
    .min(2, 'Must be at least 2 characters')
    .required('Last name is required'),
  role: Yup.string()
    .required('Role is required'),
  companyName: Yup.string()
    .min(2, 'Must be at least 2 characters')
    .required('Company name is required'),
  companySize: Yup.string()
    .required('Company size is required'),
});

export const authSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .min(4, 'Password must at least be 4 characters long')
    .required('Password is required'),
});

export const forgotPasswordSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
});
export const resetPasswordSchema = Yup.object({
  password: Yup.string()
    .min(4, 'Password must be at least 4 characters long')
    .required('Password is required'),
  passwordCheck: Yup.string()
    .min(4, 'Password must be at least 4 characters long')
    .required('Password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});