import {ReactNode} from "react";

export interface ResultsSidebarCardProps {
  readonly children: ReactNode;
  readonly title: string;
}

const ResultsSidebarCard = ({children, title}: ResultsSidebarCardProps) => {
  return (
    <div className="flex flex-col min-w-0 rounded-xl bg-white shadow-md overflow-hidden">
      <div className="p-4 border-b border-gray-light-10">
        <p className="text-sm text-status-gray">
          {title}
        </p>
      </div>
      {children}
    </div>
  );
};

export default ResultsSidebarCard;
