/* eslint-disable camelcase */
import { invisiblyApi } from '../../api/invisibly-api';


import SurveyService from '../../../services/admin/survey/survey.service';
import { InvisiblyApiResponse } from "../../../services/api/api-response.types";

import { SurveyDetail, SurveyDetailJSON, SurveyModerationResult, SurveyModerationResultJSON } from "./survey.types";
import { FetchSurveyListApiResponse, FetchSurveyListData, BulkCreateSurveyApiResponse } from '../../../services/admin/survey/survey.types';
import { SURVEY_LIST_PAGE_SIZE } from '../../../services/admin/survey/survey.constants';
import { APITagType } from "../../api/invisibly-api.types";
import { PatchCollection } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import { SurveyStatus } from "../../survey/survey.types";

const surveyEndpoints = invisiblyApi.injectEndpoints({
  endpoints: (builder) => ({
    getAdminSurveys: builder.query<FetchSurveyListData, { page: number, page_size: number }>({
      query: (data) => ({
        method: 'get',
        params: { page: data.page, page_size: data.page_size ? data.page_size : SURVEY_LIST_PAGE_SIZE },
        url: 'admin/surveys',
      }),
      transformResponse(response: InvisiblyApiResponse<FetchSurveyListApiResponse>) {
        return {
          surveys: response.item.items.map(s => SurveyService.parseListSurvey(s)),
          totalObjects: response.item.total_objects,
          totalPages: response.item.total_pages,
        };
      },
    }),
    getAdminReviewSurveys: builder.query<FetchSurveyListData, { page: number, page_size: number, query?: string }>({
      providesTags: [{ type: APITagType.AdminSurvey, id: 'LIST' }],
      query: (data) => ({
        method: 'get',
        params: {
          status: 'active',
          survey_status: 'review,live,rejected',
          page: data.page,
          page_size: data.page_size ? data.page_size : SURVEY_LIST_PAGE_SIZE,
          search: data.query,
        },
        url: 'admin/surveys',
      }),
      transformResponse(response: InvisiblyApiResponse<FetchSurveyListApiResponse>) {
        return {
          surveys: response.item.items.map(s => SurveyService.parseListSurvey(s)),
          totalObjects: response.item.total_objects,
          totalPages: response.item.total_pages,
        };
      },
    }),
    getAdminSurveyDetail: builder.query<SurveyDetail, string>({
      query: (id) => ({
        method: 'get',
        url: `admin/surveys/${id}/survey-stats`,
      }),
      transformResponse(response: InvisiblyApiResponse<SurveyDetailJSON>) {
        return SurveyService.parseSurveyDetails(response.item);
      },
    }),
    getAdminSurveyModerationReview: builder.query<SurveyModerationResult, string>({
      query: (id) => ({
        method: 'get',
        url: `admin/surveys/${id}/moderation`,
      }),
      transformResponse(response: InvisiblyApiResponse<SurveyModerationResultJSON>) {
        return SurveyService.parseSurveyModerationResults(response.item);
      },
    }),
    updateAdminSurveyStatus: builder.mutation<void, { ext_survey_id: string, survey_status?: string, is_moderated?: boolean, is_reviewed?: boolean, is_paid?: boolean, is_rejected?: boolean, feedback?: string }>({
      query: (data) => ({
        data,
        url: 'admin/surveys/review',
        method: 'post',
      }),
      async onQueryStarted({ ext_survey_id, is_moderated, is_paid, is_reviewed, is_rejected, feedback }, { dispatch, queryFulfilled, getState }) {
        const moderationPatchResult = dispatch(surveyEndpoints.util.updateQueryData('getAdminSurveyModerationReview', ext_survey_id, (draft) => {
          const patch = draft;
          if (is_moderated !== undefined) {
            patch.isModerated = is_moderated;
          }
          if (is_paid !== undefined) {
            patch.isPaid = is_paid;
          }
          if (is_reviewed !== undefined) {
            patch.isReviewed = is_reviewed;
          }
          if (is_rejected !== undefined) {
            patch.isRejected = is_rejected;
          }
          if (feedback !== undefined) {
            patch.feedback = feedback;
          }
          Object.assign(draft, patch);
        }));

        const listPatches: PatchCollection[] = [];
        for (const args of surveyEndpoints.util.selectCachedArgsForQuery(getState(), 'getAdminReviewSurveys')) {
          const listResult = dispatch(surveyEndpoints.util.updateQueryData('getAdminReviewSurveys', args, (draft) => {
            const patch = draft;
            const survey = patch.surveys.find((s) => s.extSurveyId === ext_survey_id);
            if (survey) {
              if (is_rejected) {
                survey.surveyStatus = SurveyStatus.Rejected;
              } else if (is_paid && is_reviewed && is_moderated) {
                survey.surveyStatus = SurveyStatus.Live;
              }
            }

            Object.assign(draft, patch);
          }));
          listPatches.push(listResult);
        }

        try {
          await queryFulfilled;
        } catch (error) {
          moderationPatchResult.undo();
          listPatches.forEach((patch) => patch.undo());
        }
      },
    }),
    createAdminSurvey: builder.mutation<void, FormData>({
      query: (data) => ({
        data,
        url: 'admin/surveys/csv',
        method: 'post',
      }),
    }),
    createAdminSurveyBulk: builder.mutation<BulkCreateSurveyApiResponse, FormData>({
      query: (data) => ({
        data,
        url: 'admin/surveys/bulk-create',
        method: 'post',
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetAdminSurveysQuery,
  useGetAdminSurveyDetailQuery,
  useCreateAdminSurveyMutation,
  useCreateAdminSurveyBulkMutation,
  useGetAdminReviewSurveysQuery,
  useUpdateAdminSurveyStatusMutation,
  useGetAdminSurveyModerationReviewQuery,
} = surveyEndpoints;
