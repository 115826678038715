import {ReactElement} from "react";

const FreeformQuestionPreview = (): ReactElement => {
  return (
    <div className="flex flex-col grow bg-white rounded-lg p-4 mb-20">
      <div className="text-sm text-custom-black font-bold">
        Answer below
      </div>
    </div>
  );
};

export default FreeformQuestionPreview;
