import { B2BBrandedButtonProps } from './googlebutton';
import AppleSignin, { AppleAuthResponse } from 'react-apple-signin-auth';
import { B2BButton } from '../b2bbutton/b2bbutton';
import AppleLogo from './../../assets/images/apple-logo.png';
import { appleAuthOptions } from '../../constants/config';
import { generateNonce } from '../../helpers/auth.helpers';
import { useDispatch } from 'react-redux';
import { initAppleSignIn } from '../../redux/login/login.async-thunks';
import { showNotification } from "../../redux/notification/notification.slice";
import { useState } from 'react';
import ErrorParsingService from "../../services/error-parsing/error-parsing.service";
import ErrorLogService from "../../services/error-log/error-log.service";
import AnalyticsManager from '../../services/analytics/analytics.service';
import { AnalyticEventsEnum } from '../../services/analytics/analytic-event-enum';

/*
Notes:
- This will fail unless used in qa or higher environments.

In the Apple portal configuration, you need to have all involved domains registered and validated.
Then, if you need multiple redirect URLs (because the login might start from different paths), just add them into the redirect URLs list (separated by commas).

The undocumented trick is:
The redirect url should fully match the current window url (even if there is a port there, https://domain:port/path)
*/

export const B2BAppleButton = ({
  expand = true,
  theme = 'dark',
}: B2BBrandedButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const onSuccess = async(response: AppleAuthResponse) => {
    setIsLoading(true);
    try {
      // @ts-expect-error unknown typing
      void dispatch(initAppleSignIn(response.authorization));
    } finally {
      setIsLoading(false);
      AnalyticsManager.trackEvent(AnalyticEventsEnum.ContinueWithApple);
    }
  };
  const onError = (err: any) => {
    const usable = ErrorParsingService.getUsableError(err);

    if (usable.message !== "popup_closed_by_user") {
      ErrorLogService.logError(err, 'applebutton', 'onError', [err, usable]);
      dispatch(showNotification({ summary: usable.message, theme: 'warning' }));
    }
  };

  return (
    <AppleSignin
      onSuccess={(response: AppleAuthResponse) => onSuccess(response)}
      onError={(response: any) => onError(response)}
      authOptions={{
        clientId: appleAuthOptions.clientId,
        scope: appleAuthOptions.scope,
        redirectURI: appleAuthOptions.redirectURI,
        state: appleAuthOptions.state,
        nonce: generateNonce(),
        usePopup: appleAuthOptions.usePopup,
      }}
      uiType="dark"
      skipScript={false}
      render={
        (props: any) => (
          <B2BButton
            {...props}
            imgSrc={AppleLogo}
            theme={theme}
            expand={expand}
            isLoading={isLoading}
            label='Continue with Apple'
          />
        )}
    />
  );
};


