import { DataTable, DataTableRowClickEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from 'react-router-dom';
import { Card } from 'primereact/card';
import { useGetAdminSurveysQuery } from "../../redux/admin/survey/survey.endpoints";
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import dayjs from 'dayjs';
import { Survey } from '../../redux/admin/survey/survey.types';
import './data-table-list.css';
import {useSetAdminPageTitle} from "../../hooks/useSetPageTitle";

function SurveyListScreen() {
  useSetAdminPageTitle('Surveys');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const { data, refetch } = useGetAdminSurveysQuery({ page, page_size: pageSize });
  const DATE_TIME_FORMAT = 'MM/DD/YY h:mm a';

  const surveys = data?.surveys;
  const totalObjects = data?.totalObjects;
  const nav = useNavigate();
  useEffect(() => void refetch(), []);

  function onRowClick(event: DataTableRowClickEvent) {
    nav('/admin/surveys/'+event.data.extSurveyId);
  }

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    const nextPage = Math.ceil((event.first / pageSize) + 1);
    setPage(nextPage);
    setPageSize(event.rows);
  };

  const formatDate = (date?: string) => {
    if (date)
      return dayjs(date).format(DATE_TIME_FORMAT);
    else
      return '';
  };

  const createdDateTemplate = (survey: Survey) => {
    return formatDate(survey.createdAt);
  };

  const startDateTemplate = (survey: Survey) => {
    return formatDate(survey.startDate);
  };

  const endDateTemplate = (survey: Survey) => {
    return formatDate(survey.endDate);
  };

  return (
    <Card
      title="Surveys"
      className='m-8 rounded-lg drop-shadow-lg'
    >
      { data && (
        <div className="card">
          <DataTable
            size="small"
            stripedRows
            value={surveys}
            style={{ cursor:'pointer'}}
            tableStyle={{ minWidth: '50rem' }}
            onRowClick={onRowClick}
            filterDisplay='menu'
            className='border-2'
          >
            <Column
              field="createdAt"
              header="Created"
              body={createdDateTemplate}
              sortable
            />
            <Column
              field="title"
              header="Title"
              sortable
              filter
            />
            <Column
              field="category"
              header="Category"
              sortable
              filter
            />
            <Column
              field="completedCount"
              header="Completed Users"
              sortable
            />
            {/* <Column field="attemptedCount" header="Total Users" sortable></Column> */}
            {/* <Column field="percentCompleted" header="% Complete" sortable></Column> */}
            <Column
              field="points"
              header="Points"
              sortable
            />
            <Column
              field="rank"
              header="Rank"
              sortable
            />
            <Column
              field="startDate"
              header="Start Date"
              body={startDateTemplate}
              sortable
            />
            <Column
              field="endDate"
              header="End Date"
              body={endDateTemplate}
              sortable
            />
            <Column
              field="isPublished"
              header="Published"
              sortable
            />
          </DataTable>
          <Paginator
            first={(page-1) * pageSize}
            rows={pageSize}
            totalRecords={totalObjects}
            rowsPerPageOptions={[25, 50, 100, 500]}
            onPageChange={onPageChange}
          />

        </div>
      )}
      { !data && (
        <div className="m-4">
          <div className="grid">
            <div className="col">
              <ProgressSpinner />
            </div>
          </div>
        </div>
      )}

    </Card>
  );
}

export default SurveyListScreen;
