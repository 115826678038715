enum SurveyMediaPlanStatus {
  Building = 'building',
  Completed = 'completed',
  Failed = 'failed'
}

interface SurveyMediaPlanJSON {
  readonly ext_media_plan_id: string;
  readonly media_plan_status: string;
  readonly raw_media_plan: string;
}

interface SurveyMediaPlan {
  readonly extMediaPlanId: string;
  readonly mediaPlanStatus: SurveyMediaPlanStatus;
  readonly rawMediaPlan?: string;
}

export type { SurveyMediaPlanJSON, SurveyMediaPlan };
export { SurveyMediaPlanStatus };
