import { useEffect, useState } from "react";
import { setSurveyTargetingToggle } from "../redux/survey/survey-targeting-toggle.slice";
import SurveyService from "../services/surveys/survey.service";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FirebaseService from "../services/firebase/firebase.service";
import { SurveySampleSizes } from "../services/firebase/firebase.types";
import { SURVEY_MINIMUM_TARGET_SIZE } from "../services/surveys/survey.constants";
import { useCreateQuestionMutation, useCreateSurveyMutation } from "../redux/survey/survey.endpoints";
import AnalyticsManager from "../services/analytics/analytics.service";
import { AnalyticEventsEnum } from "../services/analytics/analytic-event-enum";
import { setSurveyImageTest } from "../redux/survey/survey-image-test.slice";

const useCreateNewSurvey = () => {
  const config = FirebaseService.getRemoteConfigJSON<SurveySampleSizes>('SAMPLE_SIZES');
  const targetingConfig = FirebaseService.getRemoteConfigBool('SURVEYS_DEFAULT_TARGETED');
  const nonZeroHumanSampleSize = config?.human.find((size) => size > 0) ?? SURVEY_MINIMUM_TARGET_SIZE;
  const nonZeroAI = config?.ai.find((size) => size > 0) ?? SURVEY_MINIMUM_TARGET_SIZE;
  const [createSurvey, { data: createdSurvey, error: surveyError }] = useCreateSurveyMutation();
  const [createQuestion, { data: createdQuestion, error: questionError }] = useCreateQuestionMutation();
  const [isImageTest, setIsImageTest] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    surveyError && console.error('Error creating survey:', surveyError);
    questionError && console.error('Error creating question:', questionError);
  }, [surveyError, questionError]);

  useEffect(() => {
    if (!createdSurvey) {
      return;
    }

    const surveyId = createdSurvey.extSurveyId;
    dispatch(setSurveyTargetingToggle({ surveyId, isTargeted: targetingConfig }));
    dispatch(setSurveyImageTest({ surveyId, isImageTest }));

    void createQuestion(SurveyService.createQuestionData(surveyId, 0));
  }, [createdSurvey]);

  useEffect(() => {
    createdSurvey && createdQuestion && navigate(`/create-survey?ext-survey-id=${createdSurvey.extSurveyId}`);
  }, [createdQuestion, createdSurvey]);

  const createNewSurvey = (isImageTest: boolean = false) => {
    setIsImageTest(isImageTest);
    AnalyticsManager.trackEvent(isImageTest ? AnalyticEventsEnum.DashCreateSurveyImageTest : AnalyticEventsEnum.DashCreateSurvey);
    void createSurvey({ title: "Untitled", human_sample_size: nonZeroHumanSampleSize, ai_sample_size: nonZeroAI });
  };

  return { createNewSurvey };
};

export default useCreateNewSurvey;
