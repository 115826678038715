import './custom-paginator.css';
import backIcon from '../../assets/icons/back-arrow.svg';

export const CustomPrevPageLink = (options: any) => (
  <button
    type="button"
    className={`custom-page-link ${options.disabled ? 'p-disabled' : ''}`}
    onClick={options.onClick}
    disabled={options.disabled}
  >
    <img
      src={backIcon}
      className="w-2 h-3"
      alt="back"
      draggable={false}
    />
  </button>
);

export const CustomNextPageLink = (options: any) => (
  <button
    type="button"
    className={`custom-page-link ${options.disabled ? 'p-disabled' : ''}`}
    onClick={options.onClick}
    disabled={options.disabled}
  >
    <img
      src={backIcon}
      alt="back"
      className="-scale-x-100 w-2 h-3"
      draggable={false}
    />
  </button>
);

export const CustomPageLinks = (options: any) => {
  const className = `custom-page-link ${options.page === options.currentPage ? 'p-highlight' : ''}`;

  return (
    <button
      type="button"
      className={className}
      onClick={options.onClick}
    >
      {options.page+1}
    </button>
  );
};
