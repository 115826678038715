import { DataTable, DataTableExpandedRows, DataTableRowClickEvent, DataTableValueArray } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { useGetAdminReviewSurveysQuery } from "../../redux/admin/survey/survey.endpoints";
import { ProgressSpinner } from 'primereact/progressspinner';
import {ChangeEvent, ReactElement, useState} from 'react';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import dayjs from 'dayjs';
import { Survey } from '../../redux/admin/survey/survey.types';
import React from 'react';
import { Dialog } from 'primereact/dialog';
import {IconField} from "primereact/iconfield";
import {InputIcon} from "primereact/inputicon";
import {InputText} from "primereact/inputtext";
import {B2BButton} from "../../components/b2bbutton/b2bbutton";
import SurveyReviewModeration from './survey-review-moderation';
import './data-table-list.css';
import { useNavigate } from 'react-router-dom';
import {useSetAdminPageTitle} from "../../hooks/useSetPageTitle";

function SurveyReviewScreen() {
  useSetAdminPageTitle('Review surveys');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [inputValue, setInputValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const { data } = useGetAdminReviewSurveysQuery({ page, page_size: pageSize, query: searchValue }, { refetchOnMountOrArgChange: true });
  const [visible, setVisible] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const DATE_TIME_FORMAT = 'MM/DD/YY h:mm a';
  const surveys = data?.surveys;
  const totalObjects = data?.totalObjects;
  const nav = useNavigate();
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);

  function onRowClick(event: DataTableRowClickEvent) {
    nav('/admin/surveys/' + event.data.extSurveyId);
  }

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    const nextPage = Math.ceil((event.first / pageSize) + 1);
    setPage(nextPage);
    setPageSize(event.rows);
  };

  const formatDate = (date?: string) => {
    if (date)
      return dayjs(date).format(DATE_TIME_FORMAT);
    else
      return '';
  };

  const createdDateTemplate = (survey: Survey) => {
    return formatDate(survey.createdAt);
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') {
      return;
    }

    setPage(1);
    setSearchValue(inputValue);
  };

  const onSearchPress = () => {
    setPage(1);
    setSearchValue(inputValue);
  };

  const renderSearchInput = (): ReactElement => {
    return (
      <div className="flex flex-row items-center">
        <IconField className="mr-4">
          <InputIcon className="pi pi-search" />
          <InputText
            className='xm-1 border-2 rounded-md p-2 w-full'
            value={inputValue}
            onChange={onSearchChange}
            onKeyDown={onKeyPress}
            placeholder="Search by title"
          />
        </IconField>
        <B2BButton
          label="Search"
          onClick={onSearchPress}
          size="small"
        />
      </div>
    );
  };

  const rowExpansionTemplate = (rowData: Survey) => {
    return (
      <SurveyReviewModeration
        extSurveyId={rowData?.extSurveyId}
        survey={rowData}
      />
    );
  };

  return (
    <div>
      <Card
        title="Survey Review"
        className='m-8 rounded-lg drop-shadow-lg'
      >
        { data && (
          <div className="card">
            <DataTable
              size="small"
              expandedRows={expandedRows}
              onRowClick={onRowClick}
              onRowToggle={(e) => setExpandedRows(e.data)}
              rowExpansionTemplate={rowExpansionTemplate}
              stripedRows
              value={surveys}
              style={{ cursor:'pointer'}}
              tableStyle={{ minWidth: '50rem' }}
              filterDisplay='menu'
              className='border-2'
              header={renderSearchInput}
            >
              <Column
                field="createdAt"
                header="Created"
                body={createdDateTemplate}
                sortable
              />
              <Column
                field="title"
                header="Title"
                sortable
                filter
              />
              <Column
                field="category"
                header="Category"
                sortable
                filter
              />
              <Column
                field="surveyStatus"
                header="Status"
                sortable
                filter
              />
              <Column
                field="createdBy"
                header="Created by"
                sortable
                filter
              />
              <Column
                field='origin'
                header='Origin'
                sortable
                filter
              />
              <Column
                header="Edit"
                expander={true}
                style={{ width: '5rem' }}
              />
            </DataTable>
            <Paginator
              first={(page-1) * pageSize}
              rows={pageSize}
              totalRecords={totalObjects}
              rowsPerPageOptions={[25, 50, 100, 500]}
              onPageChange={onPageChange}
            />

          </div>
        )}
        { !data && (
          <div className="m-4">
            <div className="grid">
              <div className="col">
                <ProgressSpinner />
              </div>
            </div>
          </div>
        )}

      </Card>
      <Dialog
        header={dialogTitle}
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
      >
        <p className="m-0">
          { dialogMessage }
        </p>
      </Dialog>
    </div>
  );
}

export default SurveyReviewScreen;
