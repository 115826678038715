import { ReactElement, useLayoutEffect, useRef, useState } from "react";
import SurveyHeader, { SurveyHeaderProps } from "../../components/survey-header/survey-header";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export interface SurveyWrapperProps {
  headerProps: SurveyHeaderProps;
  headerText?: string;
  emptyState?: ReactElement;
  leftContent: ReactElement;
  rightContent: ReactElement;
}

const SurveyWrapper = ({ headerProps, emptyState, headerText, leftContent, rightContent }: SurveyWrapperProps): ReactElement => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { height, width } = useWindowDimensions();
  const [contentHeight, setContentHeight] = useState(0);

  useLayoutEffect(() => {
    if (!headerRef.current || !height || (width ?? 0) < 768) {
      return;
    }

    setContentHeight(height - headerRef.current.offsetHeight);
  }, [height, width]);

  const renderContent = (): ReactElement | null => {
    return (
      <div className={`flex flex-col md:flex-row`}>
        <div
          style={{
            maxHeight: (width && width > 768) ? `${contentHeight}px` : undefined,
          }}
          className={`flex flex-col md:overflow-y-auto w-full md:w-1/2 lg:w-2/3 xl:w-3/4 p-4`}
        >
          {leftContent}
        </div>
        <div
          style={{
            maxHeight: (width && width > 768) ? `${contentHeight}px` : undefined,
          }}
          className={`flex flex-col md:overflow-y-auto w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 pt-4 mb-4`}
        >
          {rightContent}
        </div>
      </div>
    );
  };

  return (
    <div className="md:overflow-hidden h-screen">
      <div
        ref={headerRef}
        className="flex flex-col"
      >
        {headerText && (
          <p className="text-lg font-bold px-4 pt-4">
            {headerText}
          </p>
        )}
        <SurveyHeader {...headerProps} />
      </div>
      {emptyState ?? renderContent()}
    </div>
  );
};

export default SurveyWrapper;
