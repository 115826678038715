import {FeedbackUserType} from "../../redux/survey/survey.types";
import {formatDisplayDateTime} from "../../helpers/date.helpers";

interface TextBubbleProps {
  createdAt?: string;
  feedback: string;
  userType: FeedbackUserType;
}

const TextBubble = ({ feedback, createdAt, userType }: TextBubbleProps) => {
  const userTypeText = userType === 'ai' ? 'Synthetic User' : 'Human';

  return (
    <div className={`flex shrink lg:max-w-[66%]`}>
      {/* this flex-shrink will only shrink if it's not wrapped. flex-wrap takes precedence so if
          the content doesn't extend to the end of the line, it will feel like there's wasted space */}
      <div className={`flex flex-col shrink gap-2 rounded-xl rounded-ss-none px-4 py-3 bg-custom-gray`}>
        <span>
          {feedback}
        </span>
        <span className="flex text-xs text-status-gray">
          { createdAt && (
            <>
              {formatDisplayDateTime(createdAt)}
              {' '}
              -
            </>
          )}
          {userTypeText}
        </span>
      </div>
    </div>
  );
};

export default TextBubble;
