import React, {useEffect, useRef, useState} from 'react';
import { B2BButton } from '../b2bbutton/b2bbutton';
import QuestionSummary from './survey-summary-component/survey-summary-component';
import { useModerationCheckMutation } from '../../redux/survey/survey.endpoints';
import { showNotification } from '../../redux/notification/notification.slice';
import { useDispatch } from 'react-redux';
import ValidationStepsModal from '../moderation-dialog/moderation-dialog';
import { findLocalSurveyErrors } from "../../services/surveys/surveys.helpers";
import { SurveyStatus } from '../../redux/survey/survey.types';
import { useNavigate } from "react-router-dom";
import AnalyticsManager from '../../services/analytics/analytics.service';
import { AnalyticEventsEnum } from '../../services/analytics/analytic-event-enum';
import { SurveyFull } from '../../redux/survey/survey.types';
import {partial} from "lodash";

const { TapLaunch } = AnalyticEventsEnum;

interface SummaryCardProps {
  mode: 'create' | 'view';
  survey?: SurveyFull,
}

const SummaryCard: React.FC<SummaryCardProps> = ({ mode, survey }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const extSurveyId = survey?.extSurveyId || '';
  const [moderationCheck, { isLoading: moderationLoading, data: moderationData, error: moderationError }] = useModerationCheckMutation({ fixedCacheKey: `moderation-check-${extSurveyId}` });
  const hasNavigated = useRef(false);

  useEffect(() => {
    if (moderationData?.didPass !== true || mode === 'view') {
      return;
    }
    setModalOpen(false);
    hasNavigated.current = true;
    navigate(`/payment?ext-survey-id=${extSurveyId}`);
  }, [moderationData]);

  useEffect(() => {
    moderationError && console.error('Error during moderation call:', moderationError);
  }, [moderationError]);

  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(moderationLoading);
  }, [moderationLoading]);

  const handleSubmitCreate = async() => {
    if (!survey) {
      return;
    }

    AnalyticsManager.trackEvent(TapLaunch);
    if (survey.surveyStatus === SurveyStatus.Review) {
      navigate(`/payment?ext-survey-id=${extSurveyId}`, { replace: true });
      return;
    }

    const localError = findLocalSurveyErrors(survey);
    if (localError) {
      dispatch(showNotification({ summary: localError, sticky: true, theme: "warning" }));
      return;
    }

    void moderationCheck({ extSurveyId });
  };

  return (
    <div className="flex flex-col grow">
      {mode === 'create' ? (
        <>
          <h2 className="mb-3 text-2xl">
            Summary
          </h2>
          <QuestionSummary
            survey={survey}
            mode="create"
          />
          <div className="flex flex-col pb-4">
            <B2BButton
              theme='blue'
              onClick={handleSubmitCreate}
              label={'Pay & Launch'}
            />
          </div>
          <ValidationStepsModal
            isModalOpen={isModalOpen}
            handleCloseModal={partial(setModalOpen, false)}
            showFooter={false}
          />
        </>
      ) : (
        <QuestionSummary
          survey={survey}
          mode="view"
        />
      )}
    </div>
  );
};

export default SummaryCard;
