import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface SurveyAnimationState {
  hasAnimatedSummary: { [key: string]: boolean };
}

export const defaultSurveyAnimationState: SurveyAnimationState = {
  hasAnimatedSummary: {},
};

export const surveyAnimationSlice = createSlice({
  initialState: defaultSurveyAnimationState,
  name: 'surveyAnimationState',
  reducers: {
    clearSurveyAnimationState() {
      return defaultSurveyAnimationState;
    },
    setSummaryAnimated(state, { payload }: PayloadAction<{ surveyId: string }>) {
      if (!state.hasAnimatedSummary) {
        state.hasAnimatedSummary = {};
      }

      state.hasAnimatedSummary[payload.surveyId] = true;
    },
  },
});

export const {
  clearSurveyAnimationState,
  setSummaryAnimated,
} = surveyAnimationSlice.actions;

export default surveyAnimationSlice.reducer;
