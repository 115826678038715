import {Remarkable} from "remarkable";
import './survey-action-plan.css';
import {useSurveyMediaPlan} from "./useSurveyMediaPlan";
import {SurveyStatus} from "../../../redux/survey/survey.types";

const md = new Remarkable();

interface SurveyMediaPlanProps {
  readonly extSurveyId: string;
  readonly surveyStatus?: SurveyStatus;
}

const SurveyMediaPlan = ({ extSurveyId, surveyStatus }: SurveyMediaPlanProps) => {
  const { mediaPlan } = useSurveyMediaPlan(extSurveyId, surveyStatus);

  if (!mediaPlan) {
    return null;
  }

  return (
    <div className="mb-6 p-6 rounded-lg border shadow-md bg-white">
      <div
        className="action-plan"
        dangerouslySetInnerHTML={{__html: md.render(mediaPlan, {})}}
      />
    </div>
  );
};

export default SurveyMediaPlan;
