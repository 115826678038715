import {Dma, IDmaJSON} from "./questionnaire.types";

interface IQuestionnaireService {
  readonly parseDmaList: (dmaList: IDmaJSON[]) => Dma[];
}

class QuestionnaireService implements IQuestionnaireService {
  private static instance: QuestionnaireService | undefined;

  static getInstance(): IQuestionnaireService {
    if (!QuestionnaireService.instance) {
      QuestionnaireService.instance = new QuestionnaireService();
    }

    return QuestionnaireService.instance;
  }

  public parseDmaList(dmaList: IDmaJSON[]): Dma[] {
    return dmaList
      .map(({ display_value, dma_description }) => { return { label: display_value, value: dma_description }; })
      .sort((a, b) => a.label.localeCompare(b.label));
  }
}

export type { IQuestionnaireService };
export default QuestionnaireService.getInstance();
