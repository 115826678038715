import dayjs from "dayjs";

const DISPLAY_DATE_FORMAT = 'MMM D, YYYY';
const formatDisplayDate = (date?: string) => {
  return date ? dayjs(date).format(DISPLAY_DATE_FORMAT) : '';
};

const formatDisplayDateTime = (date?: string) => {
  return date ? dayjs(date).format(`MMM D, h:mm A`) : '';
};

export { formatDisplayDate, formatDisplayDateTime, DISPLAY_DATE_FORMAT };
