import {ModerationItem, Question, SurveyFull, SurveyQuestionType} from "../../redux/survey/survey.types";
import {MINIMUM_ANSWER_COUNT} from "./survey.constants";

const findLocalSurveyErrors = (data: SurveyFull): string | undefined => {
  const { questions, title } = data;
  const hasUntitledSurvey = title === "Untitled";
  const hasNoQuestions = questions.length === 0;
  const hasUntitledQuestion = questions.some(question => question.title === 'Untitled_Question');
  const hasQuestionsWithMismatchedImageAnswers = questions.some(question => question.answers.some(answer => answer.imageUrl !== null) && !question.answers.every(answer => answer.imageUrl !== null));
  const nonFreeformQuestions = questions.filter(question => question.type !== SurveyQuestionType.Freeform);
  const hasInsufficientAnswers = nonFreeformQuestions.some(question => question.answers.length < MINIMUM_ANSWER_COUNT);
  const answers = nonFreeformQuestions.flatMap(question => question.answers);
  const hasUntitledAnswer = answers.some(answer => answer.title === 'Untitled_Answer');

  const reachedQuestions: string[] = [];
  questions.forEach((question, index) => {
    if (index === 0) {
      reachedQuestions.push(question.id);
    }
    if (question.type === SurveyQuestionType.Freeform) {
      if (index !== questions.length - 1) {
        reachedQuestions.push(questions[index + 1].id);
      }
    } else {
      question.answers.forEach(answer => {
        let questionDestination: string | undefined = undefined;
        if (answer.answerBranch !== null) {
          questionDestination = answer.answerBranch;
        } else if (index !== questions.length - 1 && !answer.isFinalQuestion) {
          questionDestination = questions[index + 1].id;
        }
        if (questionDestination !== undefined && !reachedQuestions.includes(questionDestination)) {
          reachedQuestions.push(questionDestination);
        }
      });
    }
  });
  const hasInvalidBranching = reachedQuestions.length !== questions.length;

  if (!hasUntitledSurvey && !hasNoQuestions && !hasUntitledQuestion && !hasUntitledAnswer && !hasQuestionsWithMismatchedImageAnswers && !hasInsufficientAnswers && !hasInvalidBranching) {
    return undefined;
  }

  let errorMessage = "There was an error launching the survey due to the following issues: \n";
  if (hasUntitledSurvey) {
    errorMessage += " - The survey title is untitled.\n";
  }
  if (hasNoQuestions) {
    errorMessage += " - There are no questions in the survey. \n";
  }
  if (hasUntitledQuestion) {
    errorMessage += " - One or more questions are untitled. \n";
  }
  if (hasUntitledAnswer) {
    errorMessage += " - One or more answers are untitled. \n";
  }
  if (hasQuestionsWithMismatchedImageAnswers) {
    errorMessage += " - Please check that all of your answers have text and images uploaded, where applicable. \n";
  }
  if (hasInsufficientAnswers) {
    errorMessage += " - Single and multiple choice questions must have at least two answers. \n";
  }
  if (hasInvalidBranching) {
    errorMessage += " - There are unreachable questions due to branching logic. \n";
  }

  return errorMessage;
};


function isModerationItem(item: ModerationItem | string): item is ModerationItem {
  return typeof item === 'object' && 'status' in item && 'concerns' in item;
}

const formatModerationKey = (key: string): string => {
  return key.replace(/\d+/g, (match) => {
    return (parseInt(match, 10) + 1).toString();
  }).replace(/\./g, ' ')
    .replace(/\b(\w)/g, (match) => match.toUpperCase());
};

const booleanToShuffleMode = (isRandom: boolean) => {
  return isRandom ? 'random' : 'none';
};

const shuffleModeToBoolean = (shuffleMode: string) => {
  return shuffleMode === 'random';
};


function isQuestionList(value: any): value is Question[] {
  return value.every((item: any) => Object.hasOwn(item, 'questionOrder'));
}

const appendShare = (url: string, share: boolean = false) => {
  return share ? `${url}?share=true` : url;
};

export { findLocalSurveyErrors, formatModerationKey, isModerationItem, booleanToShuffleMode, shuffleModeToBoolean, isQuestionList, appendShare };
