import { Button } from 'primereact/button';
import 'primeicons/primeicons.css';

type Theme = 'dark' | 'light';

interface BackButtonProps {
  theme?: Theme,
  onClick: () => void,
}

const computeTheme = (theme: Theme) => {
  switch (theme) {
    case 'light':
      return 'bg-white text-black drop-shadow-xl';
    case 'dark':
      return 'bg-black text-white drop-shadow-xl';
    default:
      return 'bg-white text-black drop-shadow-xl';
  }
};

export const BackButton = ({theme = 'light', onClick}: BackButtonProps) => {
  const computedTheme = computeTheme(theme);
  return(
    <Button
      icon='pi pi-chevron-left'
      rounded
      aria-label='Back'
      className={`${computedTheme} `}
      onClick={() => onClick()}
    />
  );
};