import {useEffect, useRef, useState} from "react";
import { isProd } from "../../helpers/env.helpers";
import useIsTabActive from "../../hooks/useIsTabActive";
import { useGetSurveyQuery, useGetSurveyStatsQuery, useGetSurveySummaryQuery } from "../../redux/survey/survey.endpoints";
import { SurveyFull, SurveyStats, SurveyStatus, SurveySummaryData } from "../../redux/survey/survey.types";
import FirebaseService from "../../services/firebase/firebase.service";

const { Complete, Live } = SurveyStatus;
const SUMMARY_REFRESH_INTERVAL = 30000;

interface UseViewSurveyDataReturn {
  readonly showSummaries: boolean,
  readonly stats?: SurveyStats,
  readonly survey?: SurveyFull,
  readonly surveyError?: boolean,
  readonly summary?: SurveySummaryData,
}

export const useViewSurveyData = (surveyId: string, isPublicView: boolean = false): UseViewSurveyDataReturn => {
  const isTabActive = useIsTabActive();
  const refreshConfig = FirebaseService.getRemoteConfigNumber('RESULTS_AUTO_REFRESH_SECONDS') || 0;

  const fetchParams = { ext_survey_id: surveyId, share: isPublicView };
  const [statsRefreshInterval, setStatsRefreshInterval] = useState<number | undefined>(undefined);
  const surveyFetchOptions = { skip: !surveyId, refetchOnMountOrArgChange: true, pollingInterval: statsRefreshInterval };
  const { data: survey, isError: surveyError } = useGetSurveyQuery(fetchParams, surveyFetchOptions);
  const { data: stats } = useGetSurveyStatsQuery(fetchParams, surveyFetchOptions);

  const hasFetchedAfterComplete = useRef(false);
  const [summaryRefreshInterval, setSummaryRefreshInterval] = useState<number | undefined>(undefined);
  const showSummaries = survey?.surveyStatus === SurveyStatus.Complete;
  const { data: summary } = useGetSurveySummaryQuery(fetchParams, { pollingInterval: summaryRefreshInterval, skip: !showSummaries || !summaryRefreshInterval });

  useEffect(() => {
    // On QA or Perf, most surveys are not answered by real users
    // so we should stop refreshing after AI finishes
    let blockedByAICompletion = false;
    if (!isProd() && survey && survey.maxAICompletes !== null && survey.maxAICompletes > 0 && survey.maxCompletes !== 0 && stats) {
      blockedByAICompletion = stats.aiUsers >= survey.maxAICompletes;
    }

    const shouldRefreshAsComplete = survey?.surveyStatus === Complete && !hasFetchedAfterComplete.current;
    const shouldRefresh = Boolean(refreshConfig && (survey?.surveyStatus === SurveyStatus.Live || shouldRefreshAsComplete) && !blockedByAICompletion && isTabActive);
    if (shouldRefresh && shouldRefreshAsComplete) {
      hasFetchedAfterComplete.current = true;
    }
    setStatsRefreshInterval(shouldRefresh ? refreshConfig * 1000 : undefined);
  }, [refreshConfig, survey, stats, isTabActive]);

  useEffect(() => {
    showSummaries && setSummaryRefreshInterval(summary ? undefined : SUMMARY_REFRESH_INTERVAL);
  }, [summary, showSummaries]);

  return {
    showSummaries,
    stats,
    survey,
    surveyError,
    summary,
  };
};

