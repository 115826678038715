import logoPath from "../../assets/images/brand-logo.svg";
import {ReactElement, useEffect, useState} from "react";
import TextByChar from "../../components/text-by-char/text-by-char.component";
import {SurveyStatus} from "../../redux/survey/survey.types";
import {Skeleton} from "primereact/skeleton";

export enum AISummaryType {
  executive, question
}
export interface AISummaryProps {
  readonly hasAIResponses?: boolean;
  readonly hasHumanResponses?: boolean;
  readonly summaries: string[];
  readonly surveyStatus?: SurveyStatus;
  readonly shouldAnimateSummaries?: boolean;
  readonly onComplete?: () => void;
  readonly type: AISummaryType;
}

const AISummary = ({ hasAIResponses, hasHumanResponses, onComplete, shouldAnimateSummaries = true, summaries, surveyStatus, type }: AISummaryProps): ReactElement => {
  const [nextAnimatedIndex, setNextAnimatedIndex] = useState(0);
  const shouldRenderEmptyState = surveyStatus === SurveyStatus.Live || (surveyStatus === SurveyStatus.Complete && summaries.length === 0);
  const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);
  const title = `${type === AISummaryType.executive ? "Executive" : "Question-level"} summary`;

  useEffect(() => {
    !shouldRenderEmptyState && setShowLoadingAnimation(false);
  }, [shouldRenderEmptyState]);

  const onAnimationComplete = (index: number) => {
    setNextAnimatedIndex(index + 1);
    if (index === summaries.length - 1) {
      onComplete?.();
    }
  };

  const renderSummary = (summary: string, index: number): ReactElement | null => {
    if (shouldAnimateSummaries && index > nextAnimatedIndex) {
      return null;
    }

    return (
      <div
        key={index}
        className="flex flex-row shrink"
      >
        <div
          className="px-4 py-3 bg-gray-100 rounded-r-lg rounded-bl-lg min-h-12 min-w-0 max-w-full"
        >
          {shouldAnimateSummaries === true ? (
            <TextByChar
              index={index}
              onComplete={onAnimationComplete}
              text={summary}
              textClassName="text-base"
            />
          ) : (
            <div className="text-base">
              {summary}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderLoadingBubble = (): ReactElement => {
    return (
      <Skeleton className="flex flex-row items-center justify-center min-h-5 max-w-10 rounded-2xl gap-1">
        <div
          className="size-1.5 rounded-lg bg-custom-gray-disabled opacity-20"
          key="loading-0"
        />
        <div
          className="size-1.5 rounded-lg bg-custom-gray-disabled opacity-20"
          key="loading-1"
        />
        <div
          className="size-1.5 rounded-lg bg-custom-gray-disabled opacity-20"
          key="loading-2"
        />
      </Skeleton>
    );
  };

  const onEmptyStateAnimationComplete = (index: number) => {
    // Not showing loading animation for now
    // setShowLoadingAnimation(true);
  };

  const renderEmptyState = (): ReactElement => {
    let userText = '';
    if (hasAIResponses && hasHumanResponses) {
      userText = 'both real and synthetic ';
    } else if (hasAIResponses) {
      userText = 'synthetic ';
    } else if (hasHumanResponses) {
      userText = 'real ';
    }
    const liveText = `Your survey is live, with responses from ${userText}users coming in. We’ll notify you via email once the full results are ready!`;

    const text = surveyStatus === SurveyStatus.Live ? liveText : "Your survey is complete! We're processing the data to deliver your insights. You can explore the raw numbers now — your full summary will be ready soon!";
    return (
      <div className="flex flex-row h-8 gap-3 min-w-0 grow justify-start items-center">
        <TextByChar
          index={0}
          onComplete={onEmptyStateAnimationComplete}
          text={text}
          textClassName="text-base"
        />
        {showLoadingAnimation && renderLoadingBubble()}
      </div>
    );
  };

  return (
    <div>
      {surveyStatus ? (
        <div className="flex flex-col">
          <div className="text-sm mb-6" >
            {title}
          </div>
          <div className="flex flex-row gap-3">
            <div className="flex flex-col shrink-0 size-8 rounded-2xl bg-gray-100 items-center justify-center">
              <img
                src={logoPath}
                alt="Brand Logo"
                className="object-cover w-2 h-[18px]"
                draggable={false}
              />
            </div>
            {shouldRenderEmptyState ? renderEmptyState() :
              (
                <div className="flex flex-col gap-3 min-w-0">
                  {summaries.map((summary, index) => (renderSummary(summary, index)))}
                </div>
              )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AISummary;
