import TextBubble from "./text-bubble";
import {ReactElement, useMemo} from "react";
import ExpandableTitle from "./expandable-title";
import {AnswerSource, AnswerStats, Feedback} from "../../redux/survey/survey.types";
import {feedbackListForFilter} from "../../pages/view-survey/view-survey.helpers";

const { AI, All, Human, RTR } = AnswerSource;

interface FeedbackListProps {
  readonly answers: AnswerStats[];
  readonly sourceFilter: AnswerSource;
}

interface AnswerFeedbackGroup {
  title: string;
  allFeedback: Feedback[];
}

const FeedbackList = ({ answers, sourceFilter }: FeedbackListProps) => {
  const visibleFeedbackLists = useMemo(() => {
    const feedbackLists: AnswerFeedbackGroup[] = [];

    answers.forEach(answer => {
      if (answer.feedbacks) {
        const { title, feedbacks } = answer;
        const allFeedback = feedbackListForFilter(feedbacks, sourceFilter);
        allFeedback.length && feedbackLists.push({ title, allFeedback });
      }
    });

    return feedbackLists;
  }, [answers, sourceFilter]);

  const renderList = (): ReactElement => {
    return (
      <ul className="flex flex-col px-4 pb-4">
        {visibleFeedbackLists.map(renderAnswerReasons)}
      </ul>
    );
  };

  const renderAnswerReasons = (answer: AnswerFeedbackGroup): ReactElement | null => {
    if (!answer.allFeedback.length) {
      return null;
    }

    const { title, allFeedback } = answer;
    const displayTitle = allFeedback.length > 0 ? `${title} (${allFeedback.length})` : title;

    return (
      <ExpandableTitle
        title={displayTitle}
        nested
        key={`${title}-parent`}
      >
        <div className='flex flex-col gap-2 px-4'>
          <ul className="flex flex-col gap-2">
            {allFeedback.map(({ feedback, createdAt, userType }, index) => (
              <TextBubble
                feedback={feedback}
                createdAt={createdAt}
                userType={userType}
                key={`${title}-${index}`}
              />
            ))}
          </ul>
        </div>
      </ExpandableTitle>
    );
  };

  const renderEmptyState = (): ReactElement => {
    return (
      <div className="flex flex-row justify-center px-4 pb-4 text-gray-500 text-sm">
        No open-ended responses for selected respondent type.
      </div>
    );
  };

  return (
    <ExpandableTitle title='Open-ended responses'>
      {visibleFeedbackLists.length ? renderList() : renderEmptyState()}
    </ExpandableTitle>
  );
};

export default FeedbackList;
