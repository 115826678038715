import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultUserState } from './user.initial-state';
import {B2BUser, User} from "../../services/user/user.types";
import UserParsingService from "../../services/user/user.parsing-service";
import {signOut} from "./user.async-thunks";

export const userSlice = createSlice({
  initialState: defaultUserState,
  name: 'user',
  reducers: {
    clearUserState() {
      return defaultUserState;
    },
    setName(state, { payload }: PayloadAction<{ firstName: string, lastName: string }>) {
      const { firstName, lastName } = payload;

      state.firstName = firstName;
      state.lastName = lastName;
      state.fullName = `${firstName} ${lastName}`;
    },
    setUser(state, { payload }: PayloadAction<User>) {
      const data = UserParsingService.parseUserApiData(payload);

      return {
        ...state,
        ...data,
      };
    },
    setB2BUserProfile(state, {payload }: PayloadAction<B2BUser>) {
      state.firstName = payload.first_name;
      state.lastName = payload.last_name;
      state.companyName = payload.company_name;
      state.companySize = payload.company_size;
      state.role = payload.role;
      state.setupProfile = payload.setup_profile;
    },
  },
  // eslint-disable-next-line sort-keys
  extraReducers: (builder) => {
    builder.addCase(signOut.pending, (state) => {
      state.signOutInProgress = true;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      state.signOutInProgress = false;
    });
    builder.addCase(signOut.rejected, (state) => {
      state.signOutInProgress = false;
    });
  },
});

export const {
  clearUserState,
  setName,
  setUser,
  setB2BUserProfile,
} = userSlice.actions;

export default userSlice.reducer;
