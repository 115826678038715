import React from 'react';


// STEP 1 - Include Dependencies
// Include react

// Include the react-fusioncharts component
import ReactFC from "react-fusioncharts";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";

// Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";

// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

// @ts-expect-error todo private type?
FusionCharts.options.license({
  key:
      "cgB1kvdF5H2E4I2C6D5D4D4A1G3A3D4C2C-22cC-13B3F5F3uI2D1A4hkE-11E2B4G1xG-7sC8B4E6hppI4F2C8C2C6B1F5F1E3E1G2wD-16yC2A3LC1C-7yhB1E4B1ytG4a2A18B14c1bsB4A2H4B1F1A1A3B8C7D6F1A4A3A1k==",
  creditLabel: false,
});

export type ChartData = {
  label: string;
  value: string;
};
  type ChartProps = {
    caption: string;
    chartData: {label:string, value: string}[];
    xAxisName: string;
    yAxisName: string;
  };

class Chart extends React.Component<ChartProps> {
// STEP 3 - Creating the JSON object to store the chart configurations
  chartConfigs = {
    type: "column2d", // The chart type
    width: "700", // Width of the chart
    height: "400", // Height of the chart
    dataFormat: "json", // Data type
    dataSource: {
      // Chart Configuration
      chart: {
        caption: this.props.caption,
        // subCaption: this.props.caption,//"In MMbbl = One Million barrels",
        xAxisName: this.props.xAxisName,
        yAxisName: this.props.yAxisName,
        // numberSuffix: "K",
        theme: "fusion",
      },
      // Chart Data
      data: this.props.chartData,
    },
  };
  render() {
    return (<ReactFC {...this.chartConfigs} />);
  }

}


// // STEP 4 - Creating the DOM element to pass the react-fusioncharts component
// class App extends React.Component {
//   render() {
//     return (<ReactFC {...chartConfigs} />);
//   }
// }

export default Chart;
