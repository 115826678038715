import {Tooltip} from "primereact/tooltip";

export interface TooltipIconProps {
  targetClass: string;
  message: string;
}

const TooltipIcon = ({ targetClass, message }: TooltipIconProps) => {
  return (
    <>
      <div
        className="pi pi-info-circle shuffle-toggle text-xs"
        data-pr-tooltip={message}
        data-pr-position="top"
      />
      <Tooltip
        target={`.${targetClass}`}
        className='max-w-md'
      />
    </>
  );
};

export default TooltipIcon;
