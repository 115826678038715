import {ReactElement, useEffect, useRef, useState} from "react";
import {Attribute} from "../criteria-settings";
import {isEqual} from "lodash";
import AnalyticsManager from "../../../services/analytics/analytics.service";
import {AnalyticEventsEnum} from "../../../services/analytics/analytic-event-enum";
import {B2BButton} from "../../b2bbutton/b2bbutton";
import {ListBox, ListBoxChangeEvent, ListboxPassThroughOptions} from "primereact/listbox";

export interface CriteriaListboxProps {
  index: number;
  selectedItems: string[];
  attribute: Attribute;
  onSave: (attribute: Attribute, index: number) => void;
}

const CriteriaListbox = ({attribute, index, onSave, selectedItems}: CriteriaListboxProps): ReactElement => {
  const previousSelectedItems = useRef<string[]>(selectedItems);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(selectedItems);
  const showSave = !isEqual(selectedOptions, previousSelectedItems.current);
  const allSelected = selectedOptions.length === attribute.options.length;
  const pt: ListboxPassThroughOptions = {
    wrapper: {
      className: `${showSave ? 'max-h-[262px]' : ''} overflow-y-auto`,
    },
  };

  useEffect(() => {
    if (selectedItems && !isEqual(selectedItems, selectedOptions)) {
      setSelectedOptions(selectedItems);
      previousSelectedItems.current = selectedItems;
    }
  }, [selectedItems]);

  const convertValuesToOptions = (values: string[]): { label: string; value: string }[] => {
    return values.map(value => {
      const option = attribute.options.find(opt => opt.value === value);
      return { label: option ? option.label : 'Unknown', value };
    });
  };

  const itemTemplate = (option: { label: string; value: string }) => {
    const isSelected = selectedOptions.includes(option.value);
    return (
      <div className={`flex flex-row cursor-pointer items-center justify-start`}>
        <div className={`${isSelected ? 'bg-custom-blue-2' : ''} mr-2 size-5 items-center justify-center rounded-md border-2 border-gray-200`}>
          <i
            className={`pi ${isSelected ? 'pi-check' : ''} flex size-full items-center justify-center`}
            style={{ fontSize: '0.7rem', color: 'white' }}
          />
        </div>
        <div className="text-base">
          {option.label}
        </div>
      </div>
    );
  };

  const handleSelectChange = ({value}: ListBoxChangeEvent) => {
    setSelectedOptions(value as string[]);
  };

  const onSaveClick = () => {
    const convertedOptions = convertValuesToOptions(selectedOptions);
    const updatedAttribute: Attribute = {
      ...attribute,
      options: convertedOptions,
    };
    onSave(updatedAttribute, index);
    AnalyticsManager.trackEvent(AnalyticEventsEnum.SaveCriteria);
  };

  const onSelectAllClick = () => {
    if (allSelected) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions(attribute.options.map(option => option.value));
    }
  };

  const renderSelectAll = () => {
    return (
      <div
        className={`flex flex-row grow cursor-pointer items-center justify-start px-4 py-2 border-b-2 border-gray-light-10`}
        onClick={onSelectAllClick}
      >
        <div
          className={`${allSelected ? 'bg-custom-blue-2' : ''} mr-2 size-5 items-center justify-center rounded-md border-2 border-gray-200`}
        >
          <i
            className={`pi ${allSelected ? 'pi-check' : ''} flex size-full items-center justify-center`}
            style={{fontSize: '0.7rem', color: 'white'}}
          />
        </div>
        <div className="text-base">
          Select All
        </div>
      </div>
    );
  };

  const renderSaveButton = () => {
    if (!showSave) {
      return null;
    }

    return (
      <div className="flex p-4 justify-center w-full">
        <B2BButton
          label={'Apply criteria'}
          onClick={onSaveClick}
          expand
          outline
        />
      </div>
    );
  };
  return (
    <div className="flex flex-col bg-white shadow-md rounded-2xl h-full overflow-y-auto min-w-48">
      {renderSelectAll()}
      <ListBox
        itemTemplate={itemTemplate}
        options={attribute.options}
        optionLabel="label"
        value={selectedOptions}
        onChange={handleSelectChange}
        metaKeySelection={false}
        multiple
        pt={pt}
      />
      {renderSaveButton()}
    </div>
  );
};

export default CriteriaListbox;
