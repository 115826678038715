import { DataTable} from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { useGetDiscoveriesQuery } from "../../redux/admin/discovery/discovery.endpoints";

import { ProgressSpinner } from 'primereact/progressspinner';
import { useState } from 'react';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import dayjs from 'dayjs';
import { Discovery } from '../../redux/admin/discovery/discovery.types';
import { DISCOVERY_LIST_PAGE_SIZE, DISCOVERY_LIST_INITIAL_PAGE, DATE_TIME_FORMAT } from '../../services/admin/discovery/discovery.constants';
import { useGetAdminSurveyAudiencesQuery } from '../../redux/admin/audience/audience.endpoints';
import './data-table-list.css';
import {useSetAdminPageTitle} from "../../hooks/useSetPageTitle";

function DiscoveryListScreen() {
  useSetAdminPageTitle('Discoveries');
  const [page, setPage] = useState(DISCOVERY_LIST_INITIAL_PAGE);
  const [pageSize, setPageSize] = useState(DISCOVERY_LIST_PAGE_SIZE);

  const { data, isLoading } = useGetDiscoveriesQuery({ page, page_size: pageSize }, { refetchOnMountOrArgChange: true });

  const discoveries = data?.discoveries;
  const totalObjects = data?.totalObjects;

  const { audienceTitles } = useGetAdminSurveyAudiencesQuery({}, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ data }) => {
      const titlesById = {} as {[key: string]: string};
      data?.audiences.forEach(audience => {
        titlesById[audience.id] = audience.title;
      });
      return { audienceTitles: titlesById };
    },
  });

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    const nextPage = Math.ceil((event.first / pageSize) + 1);
    setPage(nextPage);
    setPageSize(event.rows);
  };

  const createdDateTemplate = (discovery: Discovery) => {
    const date = discovery.createdAt;
    if (date)
      return dayjs(date).format(DATE_TIME_FORMAT);
    else
      return '';
  };


  return (
    <Card
      title="Discoveries"
      className='m-8 rounded-lg drop-shadow-lg'
    >
      { data && (
        <div className="card">
          <DataTable
            size="small"
            stripedRows
            className='border-2'
            value={discoveries}
            style={{ cursor:'pointer'}}
            tableStyle={{ minWidth: '50rem' }}
            filterDisplay='menu'
          >
            <Column
              field="createdAt"
              header="Created"
              body={createdDateTemplate}
              sortable
            />
            <Column
              field="title"
              header="Title"
              sortable
              filter
            />
            <Column
              field="description"
              header="Description"
              sortable
              filter
            />
            <Column
              field="category"
              header="Category"
              sortable
              filter
            />
            <Column
              field="isReviewed"
              header="Is Reviewed"
              sortable
            />
            <Column
              field="audience"
              header="Audience"
              body={(rowData) => audienceTitles[rowData.audience]}
              sortable
            />
          </DataTable>
          <Paginator
            first={(page-1) * pageSize}
            rows={pageSize}
            totalRecords={totalObjects}
            rowsPerPageOptions={[25, 50, 100, 500]}
            onPageChange={onPageChange}
          />

        </div>
      )}
      { isLoading && (
        <div className="m-4">
          <div className="grid">
            <div className="col">
              <ProgressSpinner />
            </div>
          </div>
        </div>
      )}

    </Card>
  );
}

export default DiscoveryListScreen;
