import { ReactElement } from 'react';
import { Answer } from "../../../redux/survey/survey.types";
import { partial } from "lodash";
import { Button } from "primereact/button";
import expand from '../../../assets/icons/expand.svg';

export interface SurveyAnswerListProps {
  readonly answers: Answer[];
  readonly activeAnswerIds: string[];
  readonly onAnswerClick: (answerId: string) => void;
  readonly onExpandClick: (imageUrl: string) => void;
}

const SurveyAnswerList = ({ answers, onAnswerClick, activeAnswerIds, onExpandClick }: SurveyAnswerListProps): ReactElement => {
  function clickExpand(event: React.MouseEvent<HTMLButtonElement>, imageUrl: string): void {
    event.stopPropagation();
    onExpandClick(imageUrl);
  }

  function renderImage(imageUrl: string): ReactElement | null {
    if (!imageUrl) {
      return null;
    }

    return (
      <div className='flex h-[120px] w-full justify-center'>
        <div className='absolute top-4 right-4 size-10 bg-white rounded-full' >
          <Button
            className='flex size-full justify-center items-center'
            onClick={event => clickExpand(event, imageUrl)}
          >
            <img
              src={expand}
              className='size-6'
            />
          </Button>
        </div>
        <img
          src={imageUrl}
          alt='answer related image'
          className='object-cover rounded-xl w-full'
        />
      </div>
    );
  }

  const renderAnswer = (answer: Answer): ReactElement => {
    const cardStyle = answer.imageUrl ? 'p-2 pb-4 gap-4' : 'px-3 py-5';
    const activeButton = activeAnswerIds.indexOf(answer.id) >= 0 ? 'bg-blue-500 text-white' : 'bg-white';

    return (
      <li key={answer.id}>
        <Button
          className={`${activeButton} ${cardStyle} flex flex-col w-full justify-center rounded-2xl text-sm items-center`}
          onClick={partial(onAnswerClick, answer.id)}
        >
          {renderImage(answer.imageUrl ?? '')}
          <span>
            {answer.title}
          </span>
        </Button>
      </li>
    );
  };

  return (
    <ul className='flex flex-col gap-2'>
      {answers.map(renderAnswer)}
    </ul>
  );
};

export default SurveyAnswerList;
