import {Question} from "../../../redux/survey/survey.types";
import moveHandle from "../../../assets/icons/move-handle.svg";
import { useState} from "react";

const SurveySummaryQuestion = ({ question }: { question: Question }) => {
  const [isHovered, setIsHovered] = useState(false);

  const onMouseEnter = () => {
    setIsHovered(true);
  };

  const onMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      key={question.id}
      className={`flex flex-row grow items-center rounded-lg border-2 border-white bg-white p-2 text-black min-w-0`}
    >
      <div className="mr-2 px-2 flex flex-row shrink-0 items-center min-w-0">
        { isHovered ? (
          <img
            src={moveHandle}
            alt="question"
            className="w-4 h-4 rounded-full"
          />
        ) : (
          <div className="min-w-0">
            {question.questionOrder + 1}
          </div>
        ) }
      </div>
      <div className="truncate flex flex-row grow text-start">
        {question.title != "Untitled_Question" ? question.title : ""}
      </div>
    </div>
  );
};

export default SurveySummaryQuestion;
