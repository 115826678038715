import { Button } from "primereact/button";
import { ReactElement } from "react";

interface PageEmptyStateProps {
  readonly buttonText: string;
  readonly onButtonClick: () => void;
  readonly title: string;
}

const PageEmptyState = ({ title, buttonText, onButtonClick }: PageEmptyStateProps): ReactElement => {
  return (
    <div className="flex flex-col gap-16 mx-8 sm:mx-20 my-14 text-center sm:text-left">
      <h1 className="font-light text-3xl sm:text-5xl">
        {title}
      </h1>
      <div>
        <Button
          className={'py-3 px-8 sm:px-16 bg-black text-white rounded-full'}
          label={buttonText}
          onClick={onButtonClick}
          size={'large'}
        />
      </div>
    </div>
  );
};

export default PageEmptyState;

