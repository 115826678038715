import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultSurveyFull, SurveyFull } from './survey.types';

export const surveySlice = createSlice({
  initialState: defaultSurveyFull,
  name: 'survey',
  reducers: {
    clearSurveyState() {
      return defaultSurveyFull;
    },
    setSurvey(state, {payload }: PayloadAction<SurveyFull>) {
      return payload;
    },
    setSurveyTitle(state, action: PayloadAction<string>) {
      state.title = action.payload;
    },

  },
  // eslint-disable-next-line sort-keys
  extraReducers: (builder) => {
  },
});

export const {
  clearSurveyState,
  setSurvey,
  setSurveyTitle,
} = surveySlice.actions;

export default surveySlice.reducer;
