// src/SignInPage.tsx
import { JSX, useEffect } from 'react';

import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../redux/redux-state';
import { getIsUserAuthenticated, getUserB2BOnboardStatus } from '../../redux/user/user.selectors';
import { initEmailSignIn } from '../../redux/login/login.async-thunks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/layouts/layout-login';
import SignInForm from '../../components/form/signinform/signinform';
import BackgroundImage from '../../assets/video/invisibly-brand-img.mp4';
import BrandLogo from '../../components/brandLogo/brandLogo';
import { Title } from '../../components/typography/title';
import { SubTitle } from '../../components/typography/subtitle';
import { B2BLink } from '../../components/link/b2blink';
import { B2BAppleButton } from '../../components/brandedbutton/applebutton';
import { B2BGoogleButton } from '../../components/brandedbutton/googlebutton';
import { RootState } from '../../redux/redux-store';
import ForgotPasswordDialog from '../../components/dialog/forgotPasswordDialog/forgotPasswordDialog';
import { showNotification } from "../../redux/notification/notification.slice";
import ErrorLogService from "../../services/error-log/error-log.service";
import ErrorParsingService from "../../services/error-parsing/error-parsing.service";
import AnalyticsManager from '../../services/analytics/analytics.service';
import { AnalyticEventsEnum } from '../../services/analytics/analytic-event-enum';
import {useSetPageTitle} from "../../hooks/useSetPageTitle";
import Conditions from "../../components/conditions/conditions.component";
import FirebaseService from "../../services/firebase/firebase.service";
import {SignInCopy} from "./sign-in.types";

const { TapCreateAccount, TapSignIn } = AnalyticEventsEnum;

interface Props extends PropsFromRedux {
}

function SignInPage({ isAuthenticated, emailSignIn }: Props): JSX.Element {
  const { title } = FirebaseService.getRemoteConfigJSON<SignInCopy>('SIGN_IN_COPY');
  useSetPageTitle('Invisibly');
  const { t } = useTranslation();
  const b2bUserIsOnboarded = useSelector((state: RootState) => getUserB2BOnboardStatus(state));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated && !b2bUserIsOnboarded) {
      navigate('/confirm-account');
    } else if (isAuthenticated && b2bUserIsOnboarded) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, b2bUserIsOnboarded]);

  const submitEmailSignIn = async(values: { email: string; password: string }) => {
    AnalyticsManager.trackEvent(TapSignIn);
    try {
      await emailSignIn(values.email, values.password);
    } catch (err) {
      ErrorLogService.logError(err, 'sign-in', 'submitEmailSignIn', [err]);

      const usable = ErrorParsingService.getUsableError(err);
      dispatch(showNotification({ summary: usable.message, theme: 'warning' }));
    }
  };

  const handleAcctCreateClick = () => {
    AnalyticsManager.trackEvent(TapCreateAccount);
  };

  return (
    <div>
      <Layout
        imgComponent={(
          <div className='absolute inset-0 overflow-hidden'>
            <video
              className='size-full object-cover'
              autoPlay
              loop
              muted
            >
              <source
                src={BackgroundImage}
                type='video/mp4'
              />
            </video>
          </div>
        )}
        formComponent={(
          <div className='flex h-screen justify-center pt-20'>
            <div className='flex max-w-[25rem] flex-col'>
              <div className='mb-12 justify-start'>
                <BrandLogo expand={true} />
              </div>
              <div className='mb-12'>
                <Title text={title} />
                <div className="text-lg">
                  The world’s first gen-AI research panel that blends real peoples’ data with synthetic data for instant and accurate results.
                </div>
              </div>
              <div className='mb-4'>
                <B2BGoogleButton />
              </div>
              <div>
                <B2BAppleButton />
              </div>
              <div className='mb-4'>
                <SubTitle text='or sign in with email and password' />
              </div>
              <div>
                <SignInForm onSubmit={submitEmailSignIn} /*isLoading={isLoading}*/ />
              </div>
              <div className='my-3'>
                <ForgotPasswordDialog />
              </div>
              <div className="grow" />
              <div className='mb-2'>
                <SubTitle text='New here?' />
                <B2BLink
                  className='mx-3'
                  label='Create account'
                  link='/create-account'
                  target='_self'
                  onClick={handleAcctCreateClick}
                />
              </div>
              <div className='mb-4'>
                <Conditions />
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  emailSignIn: (email: string, password: string) => dispatch(initEmailSignIn({
    email,
    password,
  })),
});

const mapStateToProps = (state: ReduxState) => ({
  isAuthenticated: getIsUserAuthenticated(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SignInPage);
