import filterIcon from '../../assets/icons/preferences.svg';
import { ReactComponent as ImageIcon } from '../../assets/icons/image.svg';
import { InputText } from 'primereact/inputtext';
import { useRef, useState, useEffect } from 'react';
import { Answer, SurveyStatus } from '../../redux/survey/survey.types';
import { useGetSurveyQuery, useUpdateAnswerMutation, useUploadImageMutation } from '../../redux/survey/survey.endpoints';
import { TieredDropdown } from '../tiered-answer-dropdown/tiered-dropdown';
import { UpdateAnswerParams } from "../../redux/admin/survey/survey.types";
import { ALLOWED_IMAGE_TYPE_STRING, ALLOWED_IMAGE_TYPES } from "../image-upload/image-uploads.constants";
import AnalyticsManager from '../../services/analytics/analytics.service';
import { AnalyticEventsEnum } from '../../services/analytics/analytic-event-enum';


interface AnswerInputProps {
  answerId: string;
  index: number;
  isImage?: boolean;
  className?: string;
  placeholder?: string;
  extSurveyId: string;
  questionId: string;
  isMultiSelect: boolean;
}

export const AnswerInput = ({
  answerId,
  index,
  isImage,
  extSurveyId,
  className,
  questionId,
  placeholder = 'Enter your answer...',
  isMultiSelect,
}: AnswerInputProps) => {
  const { data } = useGetSurveyQuery(
    { ext_survey_id: extSurveyId },
    { skip: !extSurveyId }
  );
  const [answer, setAnswer] = useState<Answer | undefined>(undefined);
  const [totalAnswer, setTotalAnswer] = useState<number>(0);
  const [totalQuestion, setTotalQuestion] = useState<number>(0);
  const [updateAnswer, { error: updateAnswerError, isSuccess: updateAnswerSuccess }] = useUpdateAnswerMutation();
  const [localTitle, setLocalTitle] = useState<string>("");
  const [questionOrder, setQuestionOrder] = useState<number>(-1);
  const [jumpToIndex, setJumpToIndex] = useState<number>(-1);
  const [isFinalQuestion, setIsFinalQuestion] = useState<boolean>(false);
  const supportImage = isImage ? 'pl-28 pr-10 py-6 text-md' : 'px-8 py-3 text-sm';
  const [uploadedImageLocal, setUploadedImageLocal] = useState<string | null>(answer?.imageUrl || null);
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const [hasJumpTo, setHasJumpTo] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [error, setError] = useState<string>("");
  const [uploadImage] = useUploadImageMutation();
  const isInReview = data?.surveyStatus === SurveyStatus.Review;
  const canDelete = totalAnswer > 2;
  const canBranchOrEnd = !isMultiSelect && (totalQuestion - 1 - questionOrder > 0);
  const canShowMenu = !isInReview && (canDelete || canBranchOrEnd);

  useEffect(() => {
    setUploadedImageLocal(answer?.imageUrl || null);
  }, [answer]);

  useEffect(() => {
    updateAnswerError && console.error('Error updating answer:', updateAnswerError);
    Boolean(updateAnswerSuccess || updateAnswerError) && setMenuVisible(false);
  }, [updateAnswerError, updateAnswerSuccess]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenuVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (data?.questions) {
      const question = data.questions.find(q => q.id === questionId);
      const index = question?.questionOrder;
      setQuestionOrder(index != undefined ? index : -1);
      if (question) {
        const updatedAnswer = question.answers.find(a => a.id === answerId);
        if (updatedAnswer) {
          setAnswer(updatedAnswer);
          const jumpToQuestion = data.questions.find(q => q.id === (updatedAnswer?.answerBranch || ""));
          const findQuestionJumpToID = jumpToQuestion ? jumpToQuestion.questionOrder : -1;
          setHasJumpTo(findQuestionJumpToID != -1);
          setJumpToIndex(findQuestionJumpToID);
          setIsFinalQuestion(updatedAnswer.isFinalQuestion != null ? updatedAnswer.isFinalQuestion : false);
          setTotalAnswer(question.answers.length);
          setTotalQuestion(data?.questions.length);
          setLocalTitle(updatedAnswer?.title !== "Untitled_Answer" ? updatedAnswer?.title || "" : "");
        }
      }
    }
  }, [data, questionId, answerId]);

  const toggleMenu = () => {
    setMenuVisible(prev => !prev);
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && ALLOWED_IMAGE_TYPES.includes(file.type)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImageLocal(reader.result as string);
        setUploadedImage(file);
      };
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    const handleUpload = async() => {
      if (uploadedImage) {
        try {
          const formData = new FormData();
          formData.append('file', uploadedImage);
          formData.append('type', 'answer');
          formData.append('uuid', answerId);
          const response = await uploadImage({ formData: formData, ext_survey_id: extSurveyId }).unwrap();
        } catch (error) {
          console.error('Upload Error:', error);
        }
      }
    };

    handleUpload();
  }, [uploadedImage]);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLocalTitle(value);
    validateAnswerText(value);
  };

  const handleAnswerTextBlur = async() => {
    if (!answer) return;
    const params = {
      id: answer.id,
      title: localTitle === "" ? "Untitled_Answer" : localTitle,
      survey: extSurveyId || '',
      survey_question: questionId,
      ext_survey_id: extSurveyId,
    };
    try {
      await updateAnswer(params).unwrap();
      localTitle !== "" && AnalyticsManager.trackEvent(AnalyticEventsEnum.EnterSurveyAnswer);
    } catch (error) {
      console.error('Error updating answer:', error);
    }
  };

  const handleJumpTo = (jumpIndex: number, reset?: boolean) => {
    if (!answer || !data?.questions) return;

    if (!reset && (jumpIndex < 0 || jumpIndex > data.questions.length)) {
      return;
    }
    const updateTitle = localTitle != "" ? localTitle : "Untitled_Answer";
    const updateParams: UpdateAnswerParams = {
      id: answer.id,
      title: updateTitle,
      survey: extSurveyId || '',
      survey_question: questionId,
      ext_survey_id: extSurveyId,
    };

    if (reset) {
      updateParams.is_final_question = false;
      updateParams.answer_branch = null;
    } else if (jumpIndex === data.questions.length) {
      updateParams.is_final_question = true;
    } else {
      updateParams.answer_branch = data.questions[jumpIndex].id;
    }

    void updateAnswer(updateParams);
  };
  const validateAnswerText = (value: string) => {
    if (value.trim().length === 0) {
      setError('Input cannot be empty');
      return false;
    }
    setError('');
    return true;
  };
  return (
    <div className="mb-2">
      <div className={`relative flex items-center ${className}`}>
        <span className='absolute ml-4 text-sm text-gray-400'>
          {index + 1}
        </span>
        {isImage && (
          <div
            className="absolute ml-10 flex size-12 cursor-pointer items-center justify-center rounded-md bg-gray-100"
            onClick={() => {
              if (!isInReview) {
                fileInputRef.current?.click();
              }
            }}
          >
            {!uploadedImageLocal ? (
              <ImageIcon className='size-4 cursor-pointer fill-black opacity-50' />
            ) : (
              <img
                src={uploadedImageLocal}
                alt="Uploaded"
                className="size-12 cursor-pointer rounded-md object-cover"
                draggable={false}
              />
            )}
            <input
              ref={fileInputRef}
              id="upload_answer_image"
              type="file"
              accept={ALLOWED_IMAGE_TYPE_STRING}
              className="transparent absolute inset-0 hidden cursor-pointer"
              onChange={handleImageUpload}
            />
          </div>
        )}
        <div className='grow'>
          <InputText
            type="text"
            value={localTitle}
            placeholder={placeholder}
            onChange={handleTextChange}
            onBlur={handleAnswerTextBlur}
            className={`w-full rounded-lg border border-gray-300 ${supportImage}`}
            disabled={isInReview}
          />
        </div>
        {canShowMenu && (
          <span className='absolute right-0 cursor-pointer pr-6'>
            <div className={`flex size-7 items-center justify-center rounded-full ${hasJumpTo || isFinalQuestion ? "bg-gray-200" : " "}`} >
              <img
                src={filterIcon}
                alt="Filter"
                onClick={toggleMenu}
                draggable={false}
              />
            </div>
            {menuVisible && (
              <div
                className="absolute right-0 top-6"
                ref={menuRef}
              >
                <TieredDropdown
                  isMultiSelect={isMultiSelect}
                  isFinalQuestion={isFinalQuestion}
                  currentQuestion={questionOrder}
                  totalAnswer={totalAnswer}
                  totalQuestion={totalQuestion}
                  jumpToIndex={jumpToIndex}
                  handleJumpTo={handleJumpTo}
                  extSurveyId={extSurveyId}
                  questionId={questionId}
                  answerId={answerId}
                />
              </div>
            )}
          </span>
        )}
      </div>
      {error && (
        <p className=" mt-1 text-red-500 text-sm">
          {error}
        </p>
      )}
    </div>
  );
};

