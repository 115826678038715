import {ListBox, ListBoxChangeEvent} from 'primereact/listbox';
import React, {useEffect, useRef, useState} from 'react';
import chevron from '../../assets/icons/chevron.svg';
import { Attribute } from '../criteria/criteria-settings';
import './result-filter.css';
import {SurveyAudienceCriteriaKey} from "../../redux/survey/survey.types";
import {isEqual} from "lodash";

interface ResultFilterProps {
  readonly attribute: Attribute;
  readonly initialSelected: string[];
  readonly onFilterChange: (key: SurveyAudienceCriteriaKey, value: string[]) => void;
}
interface Option {
  label: string;
  value: any;
}

const ResultFilter: React.FC<ResultFilterProps> = ({attribute, initialSelected, onFilterChange}) => {
  const options: Option[] = attribute.options;
  const [showListBox, setShowListBox] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>(initialSelected);
  const previousSelected = useRef<string[]>(initialSelected || []);

  useEffect(() => {
    if (isEqual(initialSelected, previousSelected.current)) {
      return;
    }

    setSelected(initialSelected);
    previousSelected.current = initialSelected;
  }, [initialSelected]);

  const onChange = (event: ListBoxChangeEvent) => {
    setSelected(event.value);
    onFilterChange(attribute.label as SurveyAudienceCriteriaKey, event.value);
  };

  const itemTemplate = (option: Option) => {
    const isSelected = selected.includes(option.value);
    return (
      <div className={`flex cursor-pointer items-center justify-start`}>
        <div className={`${isSelected ? 'bg-custom-blue-2' : ''} mr-2 flex size-5 items-center justify-center rounded-md border-2 border-gray-200`}>
          <i
            className={`pi ${isSelected ? 'pi-check' : ''} flex size-full items-center justify-center`}
            style={{ fontSize: '0.7rem', color: 'white' }}
          />
        </div>
        <div className="text-sm">
          {option.label}
        </div>
      </div>
    );
  };

  return (
    <div className="flex w-full flex-col justify-start">
      <button
        onClick={() => setShowListBox(!showListBox)}
        className="flex items-center justify-between border-t border-gray-200 p-4 text-sm text-gray-700 hover:bg-gray-200"
      >
        <span className="text-md grow text-left">
          {attribute.label}
        </span>
        <img
          src={chevron}
          className={`transition-transform duration-200 ${showListBox ? 'rotate-270' : 'rotate-90'}`}
          alt="Chevron"
        />
      </button>
      {showListBox && (
        <ListBox
          multiple
          value={selected}
          onChange={onChange}
          options={options}
          itemTemplate={itemTemplate}
          optionLabel="name"
          className="md:w-14rem w-full"
        />
      )}
    </div>
  );
};

export default ResultFilter;
