import React, { useEffect } from 'react';
import LayoutComponent from '../components/layouts/layout-login';
import BackgroundImage from '../assets/videos/invisibly-brand-img.mp4';
import BrandLogo from '../components/brandLogo/brandLogo';
import { Title } from '../components/typography/title';
import SetupProfileForm from '../components/form/setupprofileform/setupprofileform';
import { ProfileAvatar } from '../components/avatar/profile-avatar';
import { useNavigate } from 'react-router-dom';
import { ConnectedProps, connect, useSelector } from 'react-redux';
import { getIsUserAuthenticated, getUserB2BOnboardStatus } from '../redux/user/user.selectors';
import { ReduxState } from '../redux/redux-state';
import { RootState } from '../redux/redux-store';
import {useSetPageTitle} from "../hooks/useSetPageTitle";

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {}

const SetupProfilePage: React.FC<Props> = ({isAuthenticated}: Props) => {
  useSetPageTitle('Setup profile');
  const navigate = useNavigate();
  const b2bUserIsOnboarded = useSelector((state: RootState) => getUserB2BOnboardStatus(state));

  useEffect(() => {
    if (isAuthenticated && b2bUserIsOnboarded) {
      navigate('/dashboard');
    } else if (!isAuthenticated) {
      navigate('/login');
    }


  }, [isAuthenticated, b2bUserIsOnboarded]);

  return (
    <div className='overflow-hidden'>
      <LayoutComponent
        imgComponent={(
          <div className='absolute inset-0'>
            <video
              className='size-full object-cover'
              autoPlay
              loop
              muted
            >
              <source
                src={BackgroundImage}
                type='video/mp4'
              />
            </video>
          </div>
        )}
        formComponent={(
          <div>
            <div className='mr-6 mt-4 flex justify-end'>
              <ProfileAvatar onClick={() => {}} />
            </div>
            <div className='flex h-screen justify-center pt-20'>
              <div className='flex max-w-[25rem] flex-col'>
                <div className='mb-14 justify-start'>
                  <BrandLogo expand={true} />
                </div>
                <div className='mb-16'>
                  <Title text='Welcome! Let’s set up your profile.' />
                </div>
                <div>
                  <SetupProfileForm />
                </div>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  isAuthenticated: getIsUserAuthenticated(state),
});

const connector = connect(mapStateToProps);

export default connector(SetupProfilePage);
