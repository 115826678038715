import { Discovery, DiscoveryJSON } from "../../../redux/admin/discovery/discovery.types";


interface IDiscoveryParsingService {
  readonly parseListDiscovery: (json: DiscoveryJSON) => Discovery
}

class DiscoveryParsingService implements IDiscoveryParsingService {
  static instance: DiscoveryParsingService;

  public static getInstance(): IDiscoveryParsingService {
    if (!DiscoveryParsingService.instance) {
      DiscoveryParsingService.instance = new DiscoveryParsingService();
    }

    return DiscoveryParsingService.instance;
  }

  parseListDiscovery({ id, created_at, title, description, category, is_reviewed, audience }: DiscoveryJSON): Discovery {
    return {
      id,
      createdAt: created_at,
      title,
      description,
      category,
      isReviewed: is_reviewed,
      audience,
    };
  }
}

export default DiscoveryParsingService.getInstance();
