import { FormEvent, JSX, useEffect, useState } from 'react';

import {connect, ConnectedProps, useDispatch} from 'react-redux';
import { ReduxState } from '../../redux/redux-state';
import { getIsUserSuperAdmin} from '../../redux/user/user.selectors';
import { initEmailSignUp, initEmailSignIn, initGoogleSignIn } from '../../redux/login/login.async-thunks';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import {CredentialResponse, GoogleLogin} from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import './sign-in.screen.css';
import {showNotification} from "../../redux/notification/notification.slice";
import ErrorLogService from "../../services/error-log/error-log.service";
import {useSetAdminPageTitle} from "../../hooks/useSetPageTitle";
interface Props extends PropsFromRedux {
}

function SignInScreen({ isSuperAdmin, isSigningOut, emailSignIn, googleSignIn }: Props): JSX.Element {
  useSetAdminPageTitle('Sign in');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function sendEmailSignIn(event: FormEvent): void {
    event.preventDefault();
    void emailSignIn(email, password);
  }

  function onSuccess(response: CredentialResponse): void {
    googleSignIn(response?.credential ?? '');
  }

  function onError(): void {
    // GoogleLogin doesn't provide an error onError?: () => void
    ErrorLogService.logError(undefined, 'sign-in.screen', 'onError');
    dispatch(showNotification({ summary: 'Something went wrong.', theme: 'warning' }));
  }

  useEffect(() => {
    if (isSuperAdmin && !isSigningOut) {
      navigate('/admin/surveys');
    }
  }, [isSuperAdmin]);

  return (
    <div>
      <div className="mt-4 grid">
        <div className="col-12 text-center">
          <h1>
            Survey Dashboard
          </h1>
        </div>
        <div className="col">
          <form onSubmit={sendEmailSignIn}>
            <div className="col text-center">
              <InputText
                className='xm-1'
                placeholder="Username"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col text-center">
              <Password
                className='xm-1'
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="col text-center">
              <Button
                type="submit"
                label='Login'
              />
            </div>
          </form>
        </div>
      </div>
      <div className="flex grid">
        <div className="col google_button">
          <GoogleLogin
            onSuccess={onSuccess}
            onError={onError}
            useOneTap
          />
        </div>
      </div>
    </div>
  );
}


const mapDispatchToProps = (dispatch: any) => ({
  emailSignIn: (email: string, password: string) => dispatch(initEmailSignIn({
    email,
    password,
  })),
  googleSignIn: (credential: string) => dispatch(initGoogleSignIn({
    credential,
  })),
  emailSignUp: (email: string, password: string) => dispatch(initEmailSignUp({
    email,
    password,
  })),
});

const mapStateToProps = (state: ReduxState) => ({
  isSuperAdmin: getIsUserSuperAdmin(state),
  isSigningOut: state.userState.signOutInProgress,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SignInScreen);
