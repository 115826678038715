import { useGetAdminSurveyModerationReviewQuery, useUpdateAdminSurveyStatusMutation } from "../../redux/admin/survey/survey.endpoints";
import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Panel } from "primereact/panel";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { Survey } from '../../redux/admin/survey/survey.types';
import { ApiStatus } from "../../types/apiResponses";
import { InputText } from 'primereact/inputtext';


interface SurveyReviewModerationProps {
  survey: Survey,
  extSurveyId: string;
}

function SurveyReviewModeration({ extSurveyId, survey }: SurveyReviewModerationProps) {
  const { data } = useGetAdminSurveyModerationReviewQuery(extSurveyId, { refetchOnMountOrArgChange: true, skip: !extSurveyId || !survey?.hasReview });
  const [visible, setVisible] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [Feedback, setFeedback] = useState(data?.feedback);

  const [isModerated, setIsModerated] = useState(data?.isModerated);
  const [isReviewed, setIsReviewed] = useState(data?.isReviewed);
  const [isPaid, setIsPaid] = useState(data?.isPaid);
  const [updateSurveyStatus, { isSuccess: updateSurveySuccess, error: updateSurveyError }] = useUpdateAdminSurveyStatusMutation();

  const handleSurveyReviewStatus = (event: CheckboxChangeEvent, type: string) => {
    if (type == 'moderated') {
      setIsModerated(event.checked);
      updateSurveyStatus({ ext_survey_id: survey.extSurveyId, is_moderated: event.checked });
    } else if (type == 'reviewed') {
      setIsReviewed(event.checked);
      updateSurveyStatus({ ext_survey_id: survey.extSurveyId, is_reviewed: event.checked });
    } else if (type == 'paid') {
      setIsPaid(event.checked);
      updateSurveyStatus({ ext_survey_id: survey.extSurveyId, is_paid: event.checked });
    }
  };

  useEffect(() => {
    if (updateSurveySuccess) {
      setDialogTitle('Success');
      setDialogMessage('Survey status was updated');
      setVisible(true);
    }
  }, [updateSurveySuccess]);

  useEffect(() => {
    if (updateSurveyError) {
      setDialogTitle('Error updating survey status');
      setDialogMessage((updateSurveyError as ApiStatus).status.status_message);
      setVisible(true);
    }
  }, [updateSurveyError]);

  const handleFeedbackChange = (feedback: string) => {
    setFeedback(feedback);
  };

  const handleRejectFeedback = () => {
    updateSurveyStatus({ ext_survey_id: survey.extSurveyId, is_rejected: true, feedback: Feedback });
  };

  const hasAllFields = survey.hasReview;

  return (
    <div className="bg-slate-200 border p-2 rounded-sm">
      <Panel
        header="Edit Survey Status"
        className="border rounded-sm mb-2"
      >
        <div className="w-full">
          <div className="text-md font-bold mb-1">
            Survey Review Status
          </div>
          {hasAllFields ? (
            <div className="flex w-fit gap-3 border rounded-md bg-slate-100">
              <div className="flex align-items-center m-2">
                <Checkbox
                  name="Moderation"
                  onChange={(e) => handleSurveyReviewStatus(e, 'moderated')}
                  checked={isModerated ?? false}
                />
                <label
                  htmlFor="moderation"
                  className="ml-2"
                >
                  Moderation Status
                </label>
              </div>
              <div className="flex align-items-center m-2">
                <Checkbox
                  name="Reviewed"
                  onChange={(e) => handleSurveyReviewStatus(e, 'reviewed')}
                  checked={isReviewed ?? false}
                />
                <label
                  htmlFor="reviewed"
                  className="ml-2"
                >
                  Is Reviewed
                </label>
              </div>
              <div className="flex align-items-center m-2">
                <Checkbox
                  name="Paid"
                  onChange={(e) => handleSurveyReviewStatus(e, 'paid')}
                  checked={isPaid ?? false}
                />
                <label
                  htmlFor="paid"
                  className="ml-2"
                >
                  Is Paid
                </label>
              </div>
            </div >
          ) : (
            <div className="bg-orange-500 text-white w-1/2 text-center border rounded-md p-2">
              Survey does not have a survey review, was most likely created by admin user
            </div>
          )
          }
          <div className="w-1/2 border rounded-md bg-slate-100 mt-1">
            <div className="p-2">
              <div className="font-bold text-sm mb-2">
                Reject Feedback (optional)
              </div>
              <InputText
                type="text"
                value={Feedback}
                onChange={(e) => handleFeedbackChange(e.target.value)}
                className="w-full p-1 rounded-lg border border-gray-300"
              />
              <button
                onClick={handleRejectFeedback}
                className="mt-2 p-1 px-3 bg-white rounded-3xl"
              >
                Reject
              </button>
            </div>
          </div>
        </div >
      </Panel >
      {hasAllFields && (
        <Panel
          header='Survey Moderation Results'
          toggleable
          className="border rounded-sm mb-2"
        >
          {data && (
            <div>
              <pre>
                {JSON.stringify(data, null, 2)}
              </pre>
            </div>
          )}
        </Panel>
      )
      }

      <Dialog
        header={dialogTitle}
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
      >
        <p className="m-0">
          {dialogMessage}
        </p>
      </Dialog>
    </div >
  );
}

export default SurveyReviewModeration;
