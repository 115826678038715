import {ReactElement, useEffect} from "react";
import { useSearchParams} from "react-router-dom";
import {useCreatePaymentMutation} from "../redux/payment/payment.endpoints";
import {EmbeddedCheckout, EmbeddedCheckoutProvider} from "@stripe/react-stripe-js";
import StripeService from "../services/stripe/stripe.service";
import FirebaseService from "../services/firebase/firebase.service";
import { SimpleSurveyPricing} from "../services/firebase/firebase.types";
import {useSetPageTitle} from "../hooks/useSetPageTitle";

const PaymentPage = (): ReactElement => {
  useSetPageTitle('Checkout');
  const [searchParams] = useSearchParams();
  const pricing = FirebaseService.getRemoteConfigJSON<SimpleSurveyPricing>('A_LA_CARTE_PRICING');
  const extSurveyId = searchParams.get('ext-survey-id') ?? '';
  const [createPayment, { data: clientSecret, error, isUninitialized}] = useCreatePaymentMutation();

  useEffect(() => {
    if (!extSurveyId || clientSecret || !pricing || !isUninitialized) {
      return;
    }

    void createPayment({ extSurveyId, pricing });
  }, [pricing]);

  useEffect(() => {
    error && console.error('Error creating payment:', error);
    clientSecret && console.log('Payment created:', clientSecret);
  }, [error, clientSecret]);

  function renderCheckout() {
    return (
      <div className="w-full h-screen">
        <EmbeddedCheckoutProvider
          stripe={StripeService.initStripe()}
          options={{clientSecret}}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-start items-center w-full h-screen">
      {clientSecret && renderCheckout()}
    </div>
  );
};

export default PaymentPage;
