import React, {useEffect, useMemo, useState} from 'react';
import {
  useGetSurveyAudienceQuery,
  useUpdateQuestionOrderMutation,
} from '../../../redux/survey/survey.endpoints';
import { defaultQuestion, Question, SurveyFull } from '../../../redux/survey/survey.types';
import { OrderList, OrderListChangeEvent, OrderListPassThroughOptions } from "primereact/orderlist";
import './survey-summary-component.css';
import SurveySummaryQuestion from "./survey-summary-question.component";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../redux/notification/notification.slice";
import { isEqual } from "lodash";
import {
  simpleSurveyPricing,
} from "../survey-summary.helpers";
import FirebaseService from "../../../services/firebase/firebase.service";
import { SimpleSurveyPricing} from "../../../services/firebase/firebase.types";
import {isQuestionList} from "../../../services/surveys/surveys.helpers";

interface QuestionSummaryProps {
  survey?: SurveyFull,
  mode: 'create' | 'view' | 'share';
}

const PASS_THROUGH_OPTIONS: OrderListPassThroughOptions = {
  container: { className: 'flex min-w-0 grow' },
  controls: { className: 'hidden' },
  list: { className: 'bg-transparent min-h-min w-full min-w-0 max-w-full grow' },
  item: { className: 'p-0 md:pr-1 bg-transparent w-full min-w-0 max-w-full flex flex-row grow mx-0' },
  root: { className: 'max-w-full grow' },
};

const QuestionSummary: React.FC<QuestionSummaryProps> = ({ survey, mode }) => {
  const configPricing = FirebaseService.getRemoteConfigJSON<SimpleSurveyPricing>('A_LA_CARTE_PRICING');
  const respondentBasePrice = ((configPricing?.perRespondentPrice || 0) / 100).toFixed(2);
  const criteriaPricing = ((configPricing?.perCriteriaPrice || 0) / 100).toFixed(2);
  const dispatch = useDispatch();
  const [updateQuestionOrder] = useUpdateQuestionOrderMutation();
  const { data: surveyAudience } = useGetSurveyAudienceQuery({ id: survey?.audience || "", isShare: mode === 'share' }, { skip: !survey?.audience });
  const [questions, setQuestions] = useState(survey?.questions || [defaultQuestion]);

  useEffect(() => {
    if (!survey?.questions) {
      return;
    }
    const orderedQuestions = [...survey.questions].sort((a, b) => a.questionOrder - b.questionOrder);
    setQuestions(orderedQuestions);
  }, [survey]);

  const { criteriaCount, pricing, totalRespondents } = useMemo(() => {
    const critCount = Object.keys(surveyAudience?.criteria || {}).length;
    return {
      criteriaCount: critCount,
      pricing: simpleSurveyPricing(survey?.maxCompletes ?? 0, survey?.maxAICompletes ?? 0, critCount),
      totalRespondents: (survey?.maxCompletes ?? 0) + (survey?.maxAICompletes ?? 0),
    };
  }, [survey, surveyAudience]);
  const hasTargeting = criteriaCount > 0;

  const renderQuestion = (q: Question) => (
    <SurveySummaryQuestion question={q} />
  );
  const renderQuestionView = (q: Question, index: number) => (
    <div
      key={index}
      className={
        index + 1 === questions.length
          ? 'flex w-full items-center rounded-b-xl border-t border-gray-200 bg-white px-2 py-3 text-black'
          : 'flex w-full items-center border-t border-gray-200 bg-white px-2 py-3 text-black'
      }
    >
      <div className="mr-2 shrink-0 px-2 text-gray-400">
        {index + 1}
      </div>
      <div className="grow truncate text-start">
        {q.title}
      </div>
    </div>
  );
  const renderAttribute = () => (
    <>
      {Object.entries(surveyAudience?.criteria || {}).map(([key], index) => (
        <div
          key={index}
          className="flex w-full items-center justify-between pt-1"
        >
          <div className="text-gray-400">
            {key === 'DMA' ? 'Location' : key}
          </div>
          {renderPerRespondentPrice(criteriaPricing)}
        </div>
      ))}
    </>
  );

  const renderPerRespondentPrice = (priceString: string) => (
    <div className="flex flex-col items-end">
      <div className="text-gray-400">
        {`$${priceString}`}
      </div>
      <div className="text-sm text-gray-400">
        Per respondent
      </div>
    </div>
  );

  const onQuestionListChange = (event: OrderListChangeEvent) => {
    if (!isQuestionList(event.value) || !survey) {
      return;
    }

    const previousIds = questions.map(({ id }) => id);
    const updatedIds = event.value.map(({ id }) => id);
    if (isEqual(previousIds, updatedIds)) {
      return;
    }

    updateQuestionOrder({ ext_survey_id: survey.extSurveyId, questionArray: updatedIds });
  };

  const onListDragStart = () => {
    const hasSorting = questions.some(q => q.answers.some(a => a.answerBranch !== null || a.isFinalQuestion));
    if (hasSorting) {
      dispatch(showNotification({ summary: "Sorting the questions will clear all answer branching within the survey 'Jump to' or 'End Survey'", title: "Warning", theme: "info" }));
    }
  };

  return (
    <>
      {mode === 'create' ? (
        <div>
          <div className="question-summary-list flex overflow-hidden md:max-h-[300px]">
            <OrderList
              dragdrop
              onDragStart={onListDragStart}
              dataKey="id"
              value={questions}
              pt={PASS_THROUGH_OPTIONS}
              itemTemplate={renderQuestion}
              onChange={onQuestionListChange}
            />
          </div>
          <div className="my-4 rounded-xl bg-white shadow-md">
            <div className="flex flex-col w-full items-center justify-between border-b border-gray-200 p-3">
              <div className="flex w-full justify-between">
                <div className="text-black">
                  Respondents
                </div>
                <div className="text-black">
                  {totalRespondents}
                </div>
              </div>
              <div
                className="flex w-full items-center justify-end pt-1"
              >
                {renderPerRespondentPrice(respondentBasePrice)}
              </div>
            </div>
            {hasTargeting && (
              <div className="flex w-full flex-col items-center justify-between border-b border-gray-200 p-3">
                <div className="flex w-full justify-between">
                  <div className="text-black">
                    Targeting
                  </div>
                  <div className="text-black">
                    {criteriaCount}
                  </div>
                </div>
                {renderAttribute()}
              </div>
            )}
            <div className="flex w-full items-center justify-between border-b border-gray-200 p-3">
              <div className="text-black">
                Total
              </div>
              <div className="text-2xl text-black">
                {pricing?.totalString}
              </div>
            </div>
          </div>
        </div>
      )
        : (
          <div className="flex flex-col justify-start rounded-xl bg-white shadow-md">
            <div className="ml-4 py-3 text-gray-400">
              Questions
            </div>
            {questions.map((q, index) => renderQuestionView(q, index))}
          </div>
        )
      }

    </>
  );
};

export default QuestionSummary;
