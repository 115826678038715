import { Button } from "primereact/button";
import 'primeicons/primeicons.css';
import plus from "../../assets/icons/plus.svg";
import { useCreateQuestionMutation, useGetSurveyQuery } from "../../redux/survey/survey.endpoints";
import { SurveyStatus } from "../../redux/survey/survey.types";
import SurveyService from "../../services/surveys/survey.service";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setSaveStateWithDelay } from "../../redux/survey/survey-status.utils";
import AnalyticsManager from '../../services/analytics/analytics.service';
import { AnalyticEventsEnum } from '../../services/analytics/analytic-event-enum';

interface AddQuestionBtnProps {
  expand?: boolean;
  extSurveyId: string;
}
const { Draft, Rejected } = SurveyStatus;
const MAX_QUESTION_COUNT = 10;

export const AddQuestionBtn = ({ expand = false, extSurveyId }: AddQuestionBtnProps) => {
  const isExpanded = expand ? 'p-4' : 'p-2';
  const dispatch = useDispatch();
  const { questionCount, questionsRemaining } = useGetSurveyQuery({ ext_survey_id: extSurveyId }, {
    selectFromResult: ({ data }) => {
      let qRemaining = 0;

      const canAddQuestion = data?.surveyStatus === Draft || data?.surveyStatus === Rejected;
      const qCount = data?.questions?.length;

      if (canAddQuestion && qCount !== undefined) {
        qRemaining = Math.max(MAX_QUESTION_COUNT - qCount, 0);
      }

      return {
        questionCount: qCount,
        questionsRemaining: qRemaining,
      };
    },
  });
  const [createQuestion, { isLoading: createLoading, isSuccess: createSuccess, error: createError }] = useCreateQuestionMutation();

  useEffect(() => {
    createError && console.error('Error creating question:', createError);
  }, [createError]);

  useEffect(() => {
    createSuccess && setSaveStateWithDelay(dispatch);
  }, [createSuccess]);

  const onClicked = () => {
    if (questionCount === undefined || createLoading) {
      return;
    }
    AnalyticsManager.trackEvent(AnalyticEventsEnum.AddSurveyQuestion);
    void createQuestion(SurveyService.createQuestionData(extSurveyId, questionCount));
  };

  if (questionsRemaining === 0) {
    return null;
  }

  return (
    <Button
      type="button"
      onClick={onClicked}
      className={`mb-6 mt-2 flex w-full items-center rounded-lg border-2 border-dashed border-gray-200 text-black ${isExpanded}`}
    >
      <img
        className=" mr-2 shrink-0 px-1 "
        src={plus}
        alt="plus"
      />
      <div className="grow text-start text-gray-500">
        Add question
      </div>
      <div className="ml-auto shrink-0 text-gray-500">
        {questionsRemaining}
        {' '}
        left
      </div>
    </Button>
  );
};
