/* eslint-disable max-params,camelcase,no-empty-function,@typescript-eslint/no-unused-vars */
import * as Sentry from '@sentry/react';
import {appVersion, currentEnv} from "../../constants/config";
import {SeverityLevel} from "@sentry/react";
import {isLocalHost, isPerf, isProd, isQA} from "../../helpers/env.helpers";
import {isInvisiblyResponse} from "../../helpers/response.helpers";
import {removeUUIDsFromUrl} from "./error-log.utils";
// import Constants from 'expo-constants';
// import { SeverityLevel } from '@sentry/types/types/severity';

class ErrorLogService {
  private static instance: ErrorLogService | undefined;
  private userInitialized = false;

  static getInstance(): ErrorLogService {
    if (!ErrorLogService.instance) {
      ErrorLogService.instance = new ErrorLogService();
    }

    return ErrorLogService.instance;
  }

  public init(): void {
    // isLocalHost and isQA placed last to allow for early exit for prod and perf
    // Remove !isLocalHost to enable Sentry in local development
    const shouldSample = isProd() || isPerf() || (isQA() && !isLocalHost());
    const SAMPLE_RATE = shouldSample ? 1 : 0;

    Sentry.init({
      attachStacktrace: isProd(),
      autoSessionTracking: isProd(),
      dsn: "https://144bda4fc7d64b99a1d0a8c475a2df2f@o4504357398249472.ingest.us.sentry.io/4507816387543040",
      environment: currentEnv,
      ignoreErrors: [
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
      ],
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      normalizeDepth: 5,
      tracesSampleRate: SAMPLE_RATE,
      defaultIntegrations: false,
      // Wallet target will not link BE requests unless we add BE services to tie the values together
      // https://docs.sentry.io/platforms/javascript/tracing/instrumentation/automatic-instrumentation/#tracepropagationtargets
      // Ideally, we can add cd-wallet-qa + wallet-api to the tracePropagationTargets but as it stands now, the BE blocks requestions with
      // 'baggage' and returns a CORS error
      // tracePropagationTargets: ["localhost", /^https:\/\/cd-wallet-qa\.pymx6\.com\/v1/, /^https:\/\/wallet-api\.invisibly\.com\/v1/],
      tracePropagationTargets: ["localhost"],
      replaysSessionSampleRate: 0.1,
      release: appVersion,
      replaysOnErrorSampleRate: 1.0,
      sampleRate: SAMPLE_RATE,
    });
  }

  public logError(error: unknown, file: string, method: string, args: IArguments | any[] = [], type: 'function' | 'data' = 'function'): void {
    const newError = error as Error;

    let transactionName = `${file} - ${method}`;
    if (file === 'baseQuery' && Array.isArray(args) && args.length !== 0 && typeof args[0] === 'string') {
      transactionName = `${file} - ${removeUUIDsFromUrl(args[0])}`;
    }

    Sentry.getCurrentScope().setTransactionName(transactionName);
    Sentry.getCurrentScope().addEventProcessor(event => {
      if (!event.exception || !event.exception.values || !event.extra) {
        return event;
      }
      const exceptionValue = event.exception?.values?.[0];
      if (exceptionValue) {
        const serialized = event.extra['__serialized__'];
        if (serialized && isInvisiblyResponse(serialized)) {
          if (exceptionValue.mechanism) {
            exceptionValue.mechanism.synthetic = undefined;
          }
          event.exception.values[0] = {
            ...event.exception.values[0],
            value: `${serialized.status.status_code} - ${serialized.status.status_message}`,
            type: 'API Error',
            mechanism: exceptionValue.mechanism,
          };
        }
      }
      return event;
    });

    Sentry.captureException(newError, {
      extra: { arguments: args },
      tags: {
        // this allows for filtering based on error type
        errorType: typeof error,
        file,
        method,
        type,
      },
    });
  }

  public logMessage(message: string, level: SeverityLevel = 'info', data?: object): void {
    if (data) {
      Sentry.withScope(scope => {
        scope.setExtra('data', data);
        Sentry.captureMessage(message, level);
      });
      return;
    }

    Sentry.captureMessage(message, level);
  }

  public setUser(id: string, email: string, anonymousUser: boolean): void {
    if (!this.userInitialized && id) {
      Sentry.setUser({
        anonymous: anonymousUser,
        email,
        id,
      });
      this.userInitialized = true;
    }
  }

  public async withTransactionLog<P>(promise: Promise<P>, name: string): Promise<P> {
    // const transaction = Sentry.Browser.startTransaction({ name });
    //
    // const res = await promise
    //   .catch((event) => {
    //     transaction.setStatus('Error');
    //     throw event;
    //   })
    //   .finally(() => {
    //     transaction.finish();
    //   });

    return promise;
  }
}

export default new ErrorLogService();
