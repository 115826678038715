import { Locale } from "./locales.types";

export const en: Locale = {
  createSurvey: {
    imageSizeRecommendation: 'This will be the preview image for users on the Invisibly app taking your survey. Please upload an image under 500 Kb. Allowed file sizes are png, jpg, and jpeg.',
  },
  signIn: {
    title: "Sign into Invisibly Business",
  },
  forgotPassword: {
    link: "Forgot password?",
    title: "Forgot password?",
    placeholder: "Email",
    helpText: "Enter your account's email address and we'll send you a link to reset your password.",
    submit: "Send reset link",
    confirmTitle: "Password reset link sent!",
    confirm: "We sent you the password reset link to {{email}}. It will land in your inbox any moment now.",
    error: "There was a problem resetting your password",
    done: "Done",
  },
  four04: {
    button: "Back to dashboard",
    title: "Whoops! You ran into a 404 error",
  },
  surveyPreview: {
    continue: 'Continue',
    lastQuestion: 'Last question',
    questionLeft: "question left",
    questionsLeft: "questions left",
  },
};
