/* eslint-disable camelcase */
import { invisiblyApi } from '../../api/invisibly-api';
import {FetchDiscoveryListApiResponse, FetchDiscoveryListData} from "../../../services/admin/discovery/discovery.types";
import DiscoveryService from "../../../services/admin/discovery/discovery-parsing.service";
import {InvisiblyApiResponse} from "../../../services/api/api-response.types";
import {DISCOVERY_LIST_PAGE_SIZE} from "../../../services/admin/discovery/discovery.constants";

const discoveryEndpoints = invisiblyApi.injectEndpoints({
  endpoints: (builder) => ({
    getDiscoveries: builder.query<FetchDiscoveryListData, { page: number, page_size: number }>({
      query: (data) => ({
        method: 'get',
        params: { page: data.page, page_size: data.page_size ? data.page_size : DISCOVERY_LIST_PAGE_SIZE },
        url: 'admin/insights',
      }),
      transformResponse(response: InvisiblyApiResponse<FetchDiscoveryListApiResponse>) {
        return {
          discoveries: response.item.items.map(s => DiscoveryService.parseListDiscovery(s)),
          totalObjects: response.item.total_objects,
          totalPages: response.item.total_pages,
        };
      },
    }),
    createDiscovery: builder.mutation<void, FormData>({
      query: (data) => ({
        data,
        url: 'admin/insights',
        method: 'post',
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetDiscoveriesQuery,
  useCreateDiscoveryMutation,
} = discoveryEndpoints;
