import type {BaseQueryFn} from '@reduxjs/toolkit/query/react';
import type {AxiosRequestConfig} from 'axios';
import axios from 'axios';
import {HttpResponse} from '../../model/http-response';
import {ApiResponses} from '../../types/apiResponses';
import {checkIsNetworkCallSuccess, isInvisiblyResponse} from "../../helpers/response.helpers";
import {RootState} from "../redux-store";
import ErrorParsingService from "../../services/error-parsing/error-parsing.service";
import ErrorLogService from "../../services/error-log/error-log.service";
import {UsableError} from "../../services/error-parsing/error-parsing.types";
import {showNotification} from "../notification/notification.slice";
import {clearUserState} from "../user/user.slice";
import {isAuthError} from "./invisibly-api.helpers";

export const axiosBaseQuery = ({ baseUrl }: { baseUrl: string } = { baseUrl: '' }):
BaseQueryFn<
{
  data?: AxiosRequestConfig['data'];
  handleErrorGlobally?: boolean;
  localError?: string;
  method: AxiosRequestConfig['method'];
  params?: AxiosRequestConfig['params'];
  successCodes?: number[];
  url: string;
},
ApiResponses.BaseSuccessResponse,
UsableError
> => async({
  data, handleErrorGlobally = true, localError, method, params, successCodes = [], url,
}, { getState, dispatch }) => {
  try {
    if (localError) {
      const error = ErrorParsingService.getUsableError(localError);

      if (handleErrorGlobally) {
        dispatch(showNotification({summary: error.message, theme: 'warning'}));
      }

      return { error };
    }

    const { userState: { jwt }} = getState() as RootState;

    // Check to see if a full url has been passed in. If so, don't use baseUrl
    const checkedUrl = url.includes('https://') ? url : baseUrl + url;
    const axiosConfig: AxiosRequestConfig = {
      data,
      headers: { Authorization: undefined },
      method,
      params,
      url: checkedUrl,
    };

    // These calls can't access the global axios headers, so the auth needs to be set here as well
    if (jwt && axiosConfig.headers) {
      axiosConfig.headers.Authorization = `JWT ${jwt}`;
    }

    const result = await axios(axiosConfig);

    // This means it is calling something other than the base invisibly api
    if (checkedUrl === url) {
      // We need to map the data to match the expectation of the baseQuery
      // Maestro handling should be broken out into a different api handler in the future
      return {
        data: { item: result.data },
      };
    }

    const resultData: HttpResponse<any> = result.data;

    if (checkIsNetworkCallSuccess(resultData, successCodes)) {
      return {
        data: result.data,
      };
    }

    const error = ErrorParsingService.getUsableError(resultData);
    if (isInvisiblyResponse(result.data)) {
      isAuthError(resultData.status.status_code) && void dispatch(clearUserState());
      error.message = `${resultData.status.status_code} - ${resultData.status.status_message}`;
    }

    if (handleErrorGlobally) {
      dispatch(showNotification({summary: error.message, theme: 'warning'}));
    }

    ErrorLogService.logError(result.data, 'baseQuery', 'none', [url, method, params, data]);
    return {
      error,
    };
  } catch (axiosError) {
    const error = ErrorParsingService.getUsableError(axiosError);

    ErrorLogService.logError(axiosError, 'baseQuery', 'none', [url, method, params, data]);
    return {
      error,
    };
  }
};
