import { B2BButton } from '../b2bbutton/b2bbutton';
import GoogleLogo from '../../assets/images/google-logo.png';
import { useGoogleLogin } from '@react-oauth/google';
import React, { useState } from 'react';
import { showNotification } from '../../redux/notification/notification.slice';
import { initGoogleSignIn } from '../../redux/login/login.async-thunks';
import { useDispatch } from "react-redux";
import ErrorLogService from "../../services/error-log/error-log.service";
import ErrorParsingService from "../../services/error-parsing/error-parsing.service";
import AnalyticsManager from '../../services/analytics/analytics.service';
import { AnalyticEventsEnum } from '../../services/analytics/analytic-event-enum';

type Theme = 'dark' | 'light';

export interface B2BBrandedButtonProps {
  expand?: boolean;
  theme?: Theme;
}

export const B2BGoogleButton: React.FC<B2BBrandedButtonProps> = ({
  expand = true,
  theme = 'dark',
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async(response) => {
      setIsLoading(true);
      try {
        // @ts-expect-error unknown typing
        void dispatch(initGoogleSignIn({ credential: response.access_token }));
      } finally {
        setIsLoading(false);
      }
    },
    onError: (err) => {
      ErrorLogService.logError(err, 'googlebutton', 'onError', [err]);
      const usable = ErrorParsingService.getUsableError(err);
      dispatch(showNotification({ summary: usable.message, theme: 'warning' }));
    },
  });

  const handleClick = () => {
    AnalyticsManager.trackEvent(AnalyticEventsEnum.ContinueWithGoogle);
    handleGoogleLogin();
  };

  return (
    <B2BButton
      imgSrc={GoogleLogo}
      theme={theme}
      expand={expand}
      label='Continue with Google'
      onClick={handleClick}
      isLoading={isLoading}
    />
  );
};


