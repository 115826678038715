import {ISurveyState, SurveyListData} from "./survey.types";

const DEFAULT_SURVEY_LIST_DATA: SurveyListData = {
  apiConfig: {
    page: 1,
    totalObjects: 0,
  },
  data: [],
};

export const defaultSurveyState: ISurveyState = {
  list: DEFAULT_SURVEY_LIST_DATA,
};
