import { ReactElement } from "react";
import { useUpdateSurveyMutation } from "../../redux/survey/survey.endpoints";
import { AnalyticEventsEnum } from "../../services/analytics/analytic-event-enum";
import AnalyticsManager from "../../services/analytics/analytics.service";
import { RadioButtonGrid, RadioButtonGridType } from "../radio-button/radio-button";

interface TargetHumanSampleSizeRadioButtonProps {
  readonly activeSampleSize: string;
  readonly extSurveyId: string;
  readonly surveyTitle: string;
  readonly zeroDisabled: boolean;
  readonly sampleSizes: number[];
}

const TargetHumanSampleSizeRadioButton = ({ activeSampleSize, zeroDisabled, extSurveyId, surveyTitle, sampleSizes }: TargetHumanSampleSizeRadioButtonProps): ReactElement => {
  const [updateSurvey] = useUpdateSurveyMutation();
  const radioButtons = sampleSizes.map(size => {
    const value = String(size);
    return { label: value, value };
  });

  const updateMaxCompletes = (value: string) => {
    const numberValue = Number(value);
    updateSurvey({
      ext_survey_id: extSurveyId,
      title: surveyTitle,
      max_completes: numberValue,
    });
    AnalyticsManager.trackEvent(AnalyticEventsEnum.SelectHumanSampleSize, { size: value });
  };

  return (
    <RadioButtonGrid
      buttons={radioButtons}
      disabledForZero={zeroDisabled}
      extSurveyId={extSurveyId}
      initialValue={activeSampleSize}
      onClick={updateMaxCompletes}
      title='Human sample size'
      type={RadioButtonGridType.Human}
    />
  );
};

export default TargetHumanSampleSizeRadioButton;
