import React, { useEffect, useState } from 'react';
import check from '../../assets/icons/check-mark.svg';
import waiting from '../../assets/icons/grey-horizontal-bar.svg';
import invisiblyIcon from '../../assets/images/brand-logo.svg';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import '../prime-react/progress-spinner.css';

const MODERATION_STEPS = [
  'Checking survey images',
  'Checking language used',
  'Checking question flow',
  'Checking answer flow',
  'Validating fields',
  'Ensuring security',
];
const MODERATION_TITLE = 'We\'re doing a few pre-flight checks. This will only take a few seconds.';
const MODERATION_INTERVAL = 3000;
const MODERATION_WINDOW_SIZE = 4;
interface ValidationStepsModalProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  showFooter?: boolean;
  steps?: string[];
  title?: string;
  updateInterval?: number;
  windowSize?: number;
}

const ValidationStepsModal: React.FC<ValidationStepsModalProps> = ({ isModalOpen, handleCloseModal, showFooter = true, steps = MODERATION_STEPS, title = MODERATION_TITLE, updateInterval = MODERATION_INTERVAL, windowSize = MODERATION_WINDOW_SIZE }) => {
  const [validationSteps, setValidationSteps] = useState(clearMappedSteps());
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  useEffect(() => {
    isModalOpen && setValidationSteps(clearMappedSteps());
  }, [isModalOpen]);

  useEffect(() => {
    if (isModalOpen) {
      const interval = setInterval(() => {
        setCurrentStepIndex((prevIndex) => {
          const nextStepIndex = prevIndex + 1;
          if (nextStepIndex < validationSteps.length) {
            setValidationSteps((prevSteps) =>
              prevSteps.map((step, index) => {
                if (index === nextStepIndex - 1) return { ...step, status: 'complete' };
                if (index === nextStepIndex) return { ...step, status: 'loading' };
                return step;
              })
            );
            return nextStepIndex;
          } else {
            clearInterval(interval);
            return prevIndex;
          }
        });
      }, updateInterval);

      return () => clearInterval(interval);
    }
  }, [isModalOpen, validationSteps]);

  function clearMappedSteps() {
    return steps.map((text, index) => ({ text, status: index === 0 ? 'loading' : 'waiting' }));
  }

  const renderFooter = () => {
    if (!showFooter) return null;

    return (
      <div className="flex justify-center mb-6">
        <button
          onClick={handleCloseModal}
          className="p-2 px-4 bg-white shadow-md rounded-3xl"
        >
          Cancel
        </button>
      </div>
    );
  };

  const renderIconText = (status: string, text: string) => {
    let icon;
    const spinnerClass = 'custom-progress-spinner';
    const textClass = status === 'loading' ? 'text-black' : 'text-gray-500';
    if (status === 'loading') {
      return (
        <span className="flex items-center space-x-2 mb-2">
          <div className={spinnerClass}>
            <ProgressSpinner
              unstyled={true}
              className="spinner circle"
              strokeWidth="5"
            />
          </div>
          <span>
            {text}
          </span>
        </span>
      );
    }
    if (status === 'complete') icon = check;
    if (status === 'waiting') icon = waiting;

    return (
      <span className="flex items-center space-x-2 mb-2">
        <img
          src={icon}
          alt="icon"
          className="w-4 h-4 mr-1"
          draggable={false}
        />
        <span className={textClass}>
          {text}
        </span>
      </span>
    );
  };

  const adjustedWindow = Math.min(windowSize, validationSteps.length);
  const totalSteps = validationSteps.length;
  let endIndex = Math.min(currentStepIndex + adjustedWindow, totalSteps);
  const startIndex = Math.max(endIndex - windowSize - 1, 0);
  endIndex = Math.min(endIndex - 1, totalSteps);
  const adjustedEndIndex = startIndex === 0 ? windowSize : endIndex;
  const visibleSteps = validationSteps.slice(startIndex, adjustedEndIndex);

  return (
    <Dialog
      visible={isModalOpen}
      style={{ width: '360px' }}
      onHide={handleCloseModal}
      footer={renderFooter()}
      draggable={false}
      resizable={false}
      header={null}
      closable={false}
    >
      <div className="flex flex-col items-center p-6 py-0 overflow-hidden">
        <img
          className="w-3 mt-6"
          src={invisiblyIcon}
          alt="icon"
        />
        <div className="mt-6 text-3xl text-center">
          {title}
        </div>
        <div
          className="mt-8 w-full flex flex-col items-start overflow-y-auto"
          style={{ maxHeight: '200px' }}
        >
          {visibleSteps.map((step, index) => (
            <React.Fragment key={index}>
              {renderIconText(step.status, step.text)}
            </React.Fragment>
          ))}
        </div>
      </div>
    </Dialog>
  );
};

export default ValidationStepsModal;
