import React, { ReactElement, useEffect, useState } from 'react';
import CriteriaCard from '../../components/criteria/criteria-card';
import SummaryCard from '../../components/survey-summary-card/survey-summary-card';
import { QuestionCard } from '../../components/question-card/question-card';
import { AddQuestionBtn } from '../../components/add-question-btn/add-question-btn';
import { useGetSurveyQuery } from '../../redux/survey/survey.endpoints';
import { useSearchParams } from 'react-router-dom';
import { Question } from '../../redux/survey/survey.types';
import { showNotification } from '../../redux/notification/notification.slice';
import { useDispatch } from 'react-redux';
import { SurveyRejected } from '../../components/survey-rejected/survey-rejected';
import SurveyImageUpload from '../../components/image-upload/survey-image-upload/survey-image-upload';
import { useSetPageTitle } from "../../hooks/useSetPageTitle";
import SurveyWrapper from "../view-survey/survey-wrapper.component";
import FirebaseService from "../../services/firebase/firebase.service";
import { CreateSurveyCopy } from "./create-survey.types";

const CreateSurvey: React.FC = () => {
  const { betaHeader } = FirebaseService.getRemoteConfigJSON<CreateSurveyCopy>('CREATE_SURVEY_COPY');
  useSetPageTitle('Create survey');
  const [searchParams] = useSearchParams();
  const extSurveyId = searchParams.get('ext-survey-id') ?? '';
  const dispatch = useDispatch();
  const { data, error } = useGetSurveyQuery(
    { ext_survey_id: extSurveyId },
    { refetchOnMountOrArgChange: true, skip: !extSurveyId }
  );
  const [questions, setQuestions] = useState<Question[]>(
    data?.questions && data?.questions.length > 0
      ? data?.questions
      : []
  );

  useEffect(() => {
    if (error) {
      dispatch(showNotification({ summary: "There was an error retrieving surveys", sticky: true, theme: "warning" }));
    }
  }, [error]);
  useEffect(() => {
    if (data?.questions) {
      const orderedQuestions = [...data.questions].sort((a, b) => a.questionOrder - b.questionOrder);
      setQuestions(orderedQuestions);
    }
  }, [data]);

  const renderLeftPanel = (): ReactElement => {
    return (
      <>
        <SurveyRejected extSurveyId={extSurveyId} />
        {data && (
          <SurveyImageUpload
            extSurveyId={extSurveyId}
            src={data.imageUrl || null}
          />
        )}
        <div className="text-2xl my-4">
          Questions
        </div>
        {questions.map((question, index) => (
          <QuestionCard
            key={index}
            id={question.id}
            extSurveyId={extSurveyId}
          />
        ))}
        <AddQuestionBtn
          expand={true}
          extSurveyId={extSurveyId}
        />
        <CriteriaCard extSurveyId={extSurveyId} />
      </>
    );
  };


  return (
    <SurveyWrapper
      headerProps={{ survey: data, mode: 'create' }}
      headerText={betaHeader.length > 0 ? betaHeader : undefined}
      leftContent={renderLeftPanel()}
      rightContent={(
        <SummaryCard
          mode="create"
          survey={data}
        />
      )}
    />
  );
};

export default CreateSurvey;
