import { JSX } from 'react';
import Layout from '../components/layouts/layout-login';
import BackgroundImage from '../assets/video/invisibly-brand-img.mp4';
import BrandLogo from '../components/brandLogo/brandLogo';
import { Title } from '../components/typography/title';
import {B2BLink} from '../components/link/b2blink';
import ResetPasswordForm from '../components/form/reset-password-form/reset-password-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { userResetPassword } from '../redux/login/login.async-thunks';
import { useDispatch } from 'react-redux';
import {useSetPageTitle} from "../hooks/useSetPageTitle";
import {getContactSupportLink} from "../components/link/link.helpers";

const ResetPasswordPage = (): JSX.Element => {
  useSetPageTitle('Reset password');
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async(values: {password: string, passwordCheck: string}) => {
    if (values.password == values.passwordCheck && token) {
      // @ts-expect-error unknown typing
      dispatch(userResetPassword({ password: values.password, token: token })).then(response => {
        setTimeout(() => {
          if (userResetPassword.fulfilled.match(response)) {
            navigate('/login');
          }
        }, 2500);
      });
    }
  };

  return (
    <div>
      <Layout
        imgComponent={(
          <div className='absolute inset-0 overflow-hidden'>
            <video
              className='size-full object-cover'
              autoPlay
              loop
              muted
            >
              <source
                src={BackgroundImage}
                type='video/mp4'
              />
            </video>
          </div>
        )}
        formComponent={(
          <div className='flex h-screen justify-center pt-40'>
            <div className='flex max-w-[25rem] flex-col'>
              <div className='mb-12 justify-start'>
                <BrandLogo expand={true} />
              </div>
              <div className='mb-12'>
                <Title text={"Set new password"} />
              </div>
              <div>
                <ResetPasswordForm onSubmit={handleSubmit} />
              </div>
              <div className="grow" />
              <div className='mb-10'>
                <B2BLink
                  className='mx-3 text-gray-400'
                  label={'Sign in'}
                  link='/login'
                  target='_self'
                />
                <B2BLink
                  className='mx-3 text-gray-400'
                  label={'Contact support'}
                  link={getContactSupportLink('Reset password help')}
                  target='_self'
                />
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default ResetPasswordPage;
