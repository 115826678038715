import { useState } from 'react';
import { useParams } from "react-router-dom";
import Chart from '../../components/chart';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { ProgressBar } from 'primereact/progressbar';
import { useGetAdminSurveyDetailQuery } from "../../redux/admin/survey/survey.endpoints";
import { skipToken } from "@reduxjs/toolkit/query";
import { ProgressSpinner } from 'primereact/progressspinner';
import dayjs from 'dayjs';
import { useSetAdminPageTitle } from "../../hooks/useSetPageTitle";

function SurveyDetailsScreen() {
  useSetAdminPageTitle('Survey details');
  const params = useParams();
  const DATE_TIME_FORMAT = 'MM/D/YY h:mm a';
  const [isGraph, setIsGraph] = useState(false);

  const { data: survey } = useGetAdminSurveyDetailQuery(params?.id ?? skipToken, { refetchOnMountOrArgChange: true });

  const percentBodyTemplate = (answer: any) => {
    return <ProgressBar value={answer['answerPercentage']} />;
  };

  return (
    <>
      {survey && (
        <div className="m-8">
          <div className="flex flex-row gap-8">
            <Card
              title="Metadata"
              className='mb-8 rounded-lg drop-shadow-lg w-fit max-w-3/5 min-w-1/3 p-4'
            >
              <div className="grid">
                <div className="grid grid-cols-2 border-b-2 w-full gap-2">
                  <span className="col p-float-label font-bold text-wrap">
                    Title
                  </span>
                  <span className='col'>
                    {survey?.title}
                  </span>
                </div>
                <div className="grid grid-cols-2 border-b-2 w-full gap-2">
                  <span className="p-float-label font-bold">
                    Points
                  </span>
                  <span>
                    {survey?.points}
                  </span>
                </div>
                <div className="grid grid-cols-2 border-b-2 w-full gap-2">
                  <span className="p-float-label font-bold">
                    Total Respondents
                  </span>
                  <span>
                    {survey?.completedCount}
                  </span>
                </div>
                <div className="grid grid-cols-2 border-b-2 w-full gap-2">
                  <span className="p-float-label font-bold">
                    Rank
                  </span>
                  <span>
                    {survey?.rank}
                  </span>
                </div>
                <div className="grid grid-cols-2 border-b-2 w-full gap-2">
                  <span className="p-float-label font-bold">
                    Start Date
                  </span>
                  <span>
                    {survey.startDate ? dayjs(survey.startDate).format(DATE_TIME_FORMAT) : ''}
                  </span>
                </div>

                <div className="grid grid-cols-2 border-b-2 w-full gap-2">
                  <span className="p-float-label font-bold">
                    End Date
                  </span>
                  <span>
                    {survey.endDate ? dayjs(survey.endDate).format(DATE_TIME_FORMAT) : ''}
                  </span>
                </div>

                <div className="grid grid-cols-2 border-b-2 w-full gap-2">
                  <span className="p-float-label font-bold">
                    Published?
                  </span>
                  <span>
                    {survey?.isPublished ? 'Yes' : 'No'}
                  </span>
                </div>
                <div className="col">
                  <span />
                </div>
              </div>
            </Card>
            {survey.imageUrl && (
              <div className='flex justify-center'>
                <img
                  src={survey.imageUrl}
                  className='object-cover max-h-[200px] rounded-2xl'
                  alt='survey image'
                />
              </div>
            )}
          </div>
          <Card
            title='Survey Results'
            className='mb-8 rounded-lg drop-shadow-lg mx-auto'
          >
            <div className="m-0 p-0">
              {survey.questions.map((question, i) => {
                return (
                  <div
                    className="col"
                    key={i}
                  >
                    {isGraph && (
                      <Chart
                        caption={question.title}
                        chartData={question.chartData}
                        xAxisName='Question'
                        yAxisName='% of Users'
                      />
                    )}
                    {!isGraph && (
                      <div className='mb-2 border-b-2 p-4'>
                        <h4 className='font-bold text-lg'>
                          {question['title']}
                        </h4>
                        <DataTable
                          showGridlines={true}
                          size="small"
                          value={question.answers}
                          style={{ cursor: 'pointer' }}
                          tableStyle={{ minWidth: '1rem' }}
                        >
                          <Column
                            field="displayOrder"
                            header="#"
                            style={{ width: '20px' }}
                            sortable
                          />
                          <Column
                            field="title"
                            header="Answer"
                            style={{ width: '70%' }}
                            sortable
                          />
                          <Column
                            field="answerCount"
                            header="Count"
                            style={{ width: '10%' }}
                            sortable
                          />
                          <Column
                            field="percent"
                            header="Percent"
                            style={{ width: '20%' }}
                            sortable
                            body={percentBodyTemplate}
                          />
                        </DataTable>
                      </div>
                    )}
                  </div>
                );
              })}


            </div>
          </Card>
        </div>
      )}
      {!survey && (
        <div className="m-4">
          <div className="grid">
            <div className="col">
              <ProgressSpinner />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SurveyDetailsScreen;
