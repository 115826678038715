import { Action, combineReducers } from 'redux';
import { emptyState, IState } from './redux-state';
import userStateReducer, { clearUserState } from './user/user.slice';
import loginReducer from './login/login.slice';
import {invisiblyApi} from "./api/invisibly-api";
import {signOut} from "./user/user.async-thunks";
import notificationReducer from './notification/notification.slice';
import surveyReducer from './survey/survey.slice';
import saveStateReducer from './survey/survey-status.slice';
import surveyToggleReducer from './survey/survey-targeting-toggle.slice';
import surveyAnimationReducer from './survey/survey-animation.slice';
import surveyImageTestReducer from "./survey/survey-image-test.slice";

const AppReducer = combineReducers({
  userState: userStateReducer,
  loginState: loginReducer,
  [invisiblyApi.reducerPath]: invisiblyApi.reducer,
  notificationState: notificationReducer,
  b2bSurveyState: surveyReducer,
  saveState: saveStateReducer,
  surveyAnimationState: surveyAnimationReducer,
  surveyImageTestState: surveyImageTestReducer,
  surveyToggleState: surveyToggleReducer,
});

const RootReducer = (state: IState, action: Action): any => {
  if (action.type === signOut.fulfilled.type || action.type === clearUserState.type) {
    // eslint-disable-next-line no-param-reassign
    state = emptyState();
  }

  return AppReducer(state, action);
};

export default RootReducer;
