import whiteExclamation from '../../assets/icons/white_exclamation.svg';
import { useModerationCheckMutation } from '../../redux/survey/survey.endpoints';
import {ModerationIssue} from '../../redux/survey/survey.types';
import {WebsiteRedirectLinks} from "../../constants/redirects.constants";

interface SurveyRejectedProps {
  extSurveyId: string;
}

export const SurveyRejected: React.FC<SurveyRejectedProps> = ({ extSurveyId }: SurveyRejectedProps) => {
  const [_, { data }] = useModerationCheckMutation({ fixedCacheKey: `moderation-check-${extSurveyId}` });

  if (!data || data?.didPass === true || data?.issues?.length === 0) {
    return null;
  }

  const renderIssues = (issues: ModerationIssue[]) => {
    if (!issues || issues?.length === 0) {
      return null;
    }
    return issues.map((issue, index) => {
      return (
        <div
          key={issue.issueKey}
          className="my-3 flex flex-row"
        >
          <h3 className="text-sm mr-3">
            <span className="text-status-gray">
              {index + 1}
              .
            </span>
          </h3>
          <div className="flex flex-row w-full">
            <div className="flex flex-col w-8/12 mr-4 pt-0 p-2">
              <h3 className="text-sm mb-1 text-start">
                {issue.issueKey}
              </h3>
              <div className="p-2 text-base border truncate rounded text-status-gray text-start">
                {issue.offendingText}
              </div>
            </div>
            <div className="flex flex-col w-3/12">
              <h3 className="text-sm mb-1 text-start">
                Reason
              </h3>
              <div className="p-2 border rounded text-base text-red-500 truncate text-start">
                {issue.concernString}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="mb-10 bg-white text-black w-full rounded-xl py-8 pl-6 pr-8 text-center shadow-lg">
      <div className="flex items-start space-x-4">
        <div className="flex items-center justify-center">
          <img
            src={whiteExclamation}
            alt="!"
            className="w-5 h-5 mt-1.5"
          />
        </div>
        <div className="flex flex-col w-full">
          <span className="text-left mb-3 text-2xl">
            Survey violates our content policy
          </span>
          <span className="flex-grow">
            { renderIssues(data.issues) }
          </span>
          <span className="text-sm mt-3 text-left font-medium text-gray-400">
            Some of the questions do not adhere to our
            {' '}
            <a
              target="_blank"
              href={WebsiteRedirectLinks.ContentGuidelines}
              className="underline"
              rel="noreferrer"
            >
              content guidelines
            </a>
          </span>
          <span className="text-sm text-left text-gray-400">
            Review them and resubmit the survey.
          </span>
        </div>
      </div>
    </div>

  );
};
