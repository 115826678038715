import React, { useState } from 'react';
import trash from '../../../assets/icons/trash.svg';
import chevronDownBlack from '../../../assets/icons/chevronDownBlack.svg';
import MultiSelectComponent from './multi-select';
import {Attribute, generateOptionsString} from '../criteria-settings';
import { SurveyStatus } from '../../../redux/survey/survey.types';
import { useGetSurveyQuery } from '../../../redux/survey/survey.endpoints';
import {useAttributeList} from "../../../hooks/useAttributeList";


interface AddedCriteriaProps {
  extSurveyId: string;
  options: { label: string; value: any }[];
  index: number;
  updateIndex: number;
  onDelete: (index: number) => void;
  handleMultiSelectChange: (chosenAttribute: Attribute, index: number) => void;
  value: string;
}

const AddedCriteria: React.FC<AddedCriteriaProps> = ({
  extSurveyId,
  options,
  index,
  updateIndex,
  onDelete,
  handleMultiSelectChange,
  value,
}) => {
  const { attributes } = useAttributeList();
  const attribute = attributes[index];
  const label = attribute.label;
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const { data } = useGetSurveyQuery(
    { ext_survey_id: extSurveyId },
    { skip: !extSurveyId }
  );
  const clickedDropdown = () => {
    if(data?.surveyStatus === SurveyStatus.Review) {
      return;
    }
    setShowDropdown(!showDropdown);
  };
  const handleDeleteClick = () => {
    onDelete(index);
  };
  const selectedItems = options.map(option => option.value);

  const onMultiSelectChange = (chosenAttribute: Attribute, index: number) => {
    setShowDropdown(false);
    handleMultiSelectChange(chosenAttribute, index);
  };

  return (
    <div>
      <div className="mt-2 flex w-full items-center rounded-lg border-2 border-gray-200 p-3 text-black">
        <div className="text-gray-500">
          {label}
        </div>
        <button
          className="ml-3 flex items-center text-black focus:outline-none"
          onClick={clickedDropdown}
        >
          <div>
            {generateOptionsString(options, value, attribute)}
          </div>
          { data?.surveyStatus !== SurveyStatus.Review && (
            <img
              src={chevronDownBlack}
              alt=""
              className="ml-2 h-1.5 mt-1"
              draggable={false}
            />
          )}
        </button>
        <div className="grow" />
        { data?.surveyStatus !== SurveyStatus.Review &&(
          <img
            src={trash}
            onClick={handleDeleteClick}
            alt="Trash"
            className="mr-2 shrink-0 cursor-pointer px-1"
            draggable={false}
          />
        )}
      </div>

      <div className="multi-select-specifier">
        {showDropdown && (
          <MultiSelectComponent
            index={updateIndex}
            isActive={true}
            selectedItems={selectedItems}
            attribute={attribute}
            handleMultiSelectChange={onMultiSelectChange}
          />
        )}
      </div>
    </div>
  );
};

export default AddedCriteria;
