export interface TextProps {
  bold?: boolean;
  className?: string;
  text: string;
  color?: string;
}

export const Title = ({
  bold = false,
  className = '',
  color = '',
  text,
}: TextProps) => {
  const boldClass = bold ? 'font-bold' : '';
  return (
    <span className={`text-5xl font-light leading-[3.5rem] ${boldClass} ${className} ${color}`}>
      {text}
    </span>
  );
};
