import { ApiStatus } from "../../types/apiResponses";
import {FetchedList} from "../redux-util.types";

interface Status {
  status_type: boolean;
  status_message: string;
  status_code: number;
}

export interface ModerationItem {
  id: string;
  field: string;
  status: string;
  concerns: string[];
  message: string;
  text: string;
}

export interface ModerationResponse {
  status: Status;
  item: {
    [key: string]: ModerationItem | string;
    survey_status: string;
  };
}

export interface ModerationIssue {
  readonly issueKey: string;
  readonly offendingText: string;
  readonly concernString: string;
}

export interface ModerationCheckResults {
  readonly count: number;
  readonly didPass: boolean;
  readonly issues: ModerationIssue[];
}

export enum SurveyStatus {
  Building = 'building',
  Draft = 'draft',
  Complete = 'complete',
  Live = 'live',
  Review = 'review',
  Rejected = 'rejected'
}
export function toSurveyStatus(status: string | null): SurveyStatus {
  switch (status) {
    case 'draft':
      return SurveyStatus.Draft;
    case 'complete':
      return SurveyStatus.Complete;
    case 'live':
      return SurveyStatus.Live;
    case 'review':
      return SurveyStatus.Review;
    case 'rejected':
      return SurveyStatus.Rejected;
    case 'building':
      return SurveyStatus.Building;
    default:
      return SurveyStatus.Draft;
  }
}

export enum SurveyDisplayStatus {
  Live = 'Live',
  Draft = 'Draft',
  Complete = 'Complete'
}

export enum SurveyQuestionType {
  SingleSelect = 'single-select',
  MultiSelect = 'multi-select',
  Freeform = 'free-form'
}
export enum SaveStatusEnum {
  Syncing = 'Syncing',
  JustSynced = 'Just synced',
  Synced = 'Synced'
}
export const defaultSaveStatus:SaveStatus = {
  status: SaveStatusEnum.Synced,
};
interface SaveStatus {
  status: SaveStatusEnum;
}
interface UploadImage {
  item: {
    obj_uuid: string;
    type: string;
    url: string;
  };
  status: ApiStatus;
}

// maybe change the name of this to SurveyListObjectJSON
interface SurveyJSON {
  readonly id: string;
  readonly created_at: string;
  readonly created_by: string;
  readonly ext_survey_id: string;
  readonly title: string;
  readonly completed_users: number;
  readonly ai_completed_users: number;
  readonly max_completes?: number;
  readonly ai_max_completes?: number;
  readonly start_date?: string;
  readonly survey_status: SurveyStatus;
  readonly question_count: number;
}

// maybe change the name of this to SurveyListObject or SurveyListItem?
interface Survey {
  id: string;
  createdAt: string;
  createdBy: string;
  extSurveyId: string;
  title: string;
  completedUsers: number;
  aiCompletedUsers: number;
  maxCompletes?: number;
  maxAICompletes?: number;
  startDate?: string;
  surveyStatus: SurveyStatus;
  questionCount: number;
}
export const defaultSurvey: Survey = {
  id: '',
  createdAt: '',
  createdBy: '',
  extSurveyId: '',
  title: '',
  completedUsers: 0,
  aiCompletedUsers: 0,
  maxCompletes: undefined,
  startDate: undefined,
  surveyStatus: SurveyStatus.Draft,
  questionCount: 0,
};

interface BaseStatsJSON {
  readonly ai_users: number;
  readonly human_users: number;
  readonly rtr_users: number;
  readonly image_url?: string | null;
  readonly title: string;
  readonly display_name?: string;
  readonly total_users: number;
}

interface BaseStats {
  readonly aiPercentage: number;
  aiUsers: number;
  humanUsers: number;
  readonly humanPercentage: number;
  readonly imageUrl?: string;
  rtrUsers: number;
  readonly rtrPercentage: number;
  readonly title: string;
  readonly displayName: string;
  totalUsers: number;
  readonly totalPercentage: number
}

export enum DemographicsKey {
  education = 'education',
  employment = 'employment',
  householdIncome = 'household_income',
  maritalStatus = 'marital_status',
  children = 'children',
  pets = 'pets',
  ethnicity = 'ethnicity',
  placeOfOrigin = 'place_of_origin',
  politicalAffiliation = 'political_affiliation',
  registeredToVote = 'registered_to_vote',
  language = 'language',
  gender = 'gender',
  ageGroup = 'age_group',
  dma = 'dma'
}

type DemographicsStatsJSON = {
  [key in DemographicsKey]: { [id: string] : BaseStatsJSON }
};

type FullDemographicsStats = {
  [key in DemographicsKey]: BaseStats[]
};

type DemographicsStats = Partial<FullDemographicsStats>;

interface AllFeedbackJSON {
  readonly ai_answers: FeedbackJSON[];
  readonly human_answers: FeedbackJSON[];
  readonly rtr_answers: FeedbackJSON[];
}

interface FeedbackJSON {
  created_at: string;
  feedback: string;
}

interface AllFeedback {
  readonly aiAnswers: Feedback[];
  readonly humanAnswers: Feedback[];
  readonly rtrAnswers: Feedback[];
}

interface Feedback {
  createdAt: string;
  feedback: string;
  userType: FeedbackUserType;
}

type FeedbackUserType = 'ai' | 'human' | 'rtr';

interface FreeformJSON {
  readonly ai_users: string[];
  readonly human_users: string[];
  readonly rtr_users: string[];
}

interface FreeformAnswer {
  readonly createdAt?: string;
  readonly answer: string;
  readonly userType: FeedbackUserType;
}

interface AllFreeformAnswers {
  readonly aiUsers: FreeformAnswer[];
  readonly humanUsers: FreeformAnswer[];
  readonly rtrUsers: FreeformAnswer[];
}

interface SurveyAnswerStatsJSON extends BaseStatsJSON {
  readonly id: string;
  readonly demographics?: DemographicsStatsJSON;
  readonly feedback?: AllFeedbackJSON;
}

interface AnswerStats extends BaseStats {
  readonly id: string;
  readonly demographics?: DemographicsStats;
  feedbacks?: AllFeedback;
}

interface SurveyQuestionStatsJSON extends BaseStatsJSON {
  readonly id: string;
  readonly answers: { [id: string] : SurveyAnswerStatsJSON }
  readonly ['free-form-answers']?: FreeformJSON;
}

interface QuestionStats extends BaseStats {
  readonly id: string;
  answers: AnswerStats[];
  freeformAnswers?: AllFreeformAnswers;
  readonly demographics?: DemographicsStats;
}

interface SurveyStatsJSON extends BaseStatsJSON {
  readonly ext_survey_id: string;
  readonly questions: { [id: string] : SurveyQuestionStatsJSON }
}

interface SurveyStats extends BaseStats {
  readonly extSurveyId: string;
  readonly questions: QuestionStats[];
}

// maybe change the name to SurveyJSON?
interface SurveyFullJSON {
  readonly title: string;
  readonly ext_survey_id: string;
  readonly points: number;
  readonly questions: SurveyQuestionJSON[];
  readonly audience: string | null;
  readonly category: string | null;
  readonly image_url: string | null;
  readonly rank: number | null;
  readonly ranked_at: string | null;
  readonly start_date: string | null;
  readonly end_date: string | null;
  // question_count:  number;
  readonly is_published: boolean;
  readonly max_completes: number | null;
  readonly ai_max_completes: number | null;
  readonly survey_status: string | null;
}

// Do not change these, they're server values
type ShuffleMode = 'none' | 'random' | 'reverse';

interface SurveyQuestionJSON {
  readonly id: string;
  readonly title: string;
  readonly type: string;
  readonly points: number;
  readonly answers: SurveyAnswerJSON[];
  readonly response_ids: string[];
  readonly shuffle: ShuffleMode;
  readonly next_question: string | null;
  readonly image_url: string | null;
  readonly total_respondents: number;
  readonly question_order: number;
  readonly has_feedback: boolean;
}

interface SurveyAnswerJSON {
  readonly id: string;
  readonly title: string;
  readonly answer_pixel: string | null;
  readonly answer_branch: string | null;
  readonly is_final_question: boolean;
  readonly image_url: string | null;
  readonly user_percent: number;
  readonly total_count: number;
}

interface SummaryJSON {
  readonly summaries: string[];
}

interface SurveySummaryDataJSON {
  readonly survey: SummaryJSON;
  readonly questions: { [id: string] : SummaryJSON }
}

interface SurveySummaryQuestion {
  readonly id: string;
  readonly summaries: string[];
}

interface SurveySummaryData {
  readonly summaries: string[];
  readonly questions: SurveySummaryQuestion[];
}

interface Answer {
  id: string;
  title: string;
  answerPixel: string | null;
  answerBranch: string | null;
  isFinalQuestion: boolean;
  imageUrl: string | null;
  userPercent: number;
  totalCount: number;
}

export const defaultAnswer: Answer = {
  id: '',
  title: '',
  answerPixel: null,
  answerBranch: null,
  isFinalQuestion: false,
  imageUrl: null,
  userPercent: 0,
  totalCount: 0,
};


interface Question {
  id: string;
  title: string;
  type: string;
  points: number;
  answers: Answer[];
  responseIds: string[];
  shuffle: ShuffleMode;
  nextQuestion: string | null;
  questionOrder: number;
  imageUrl: string | null;
  totalRespondents: number;
  hasFeedback: boolean;
}

interface QuestionCreationData {
  title: string,
  type?: SurveyQuestionType,
  question_order: number,
  shuffle: boolean,
  survey: string,
  ext_survey_id: string
}

interface QuestionUpdateData {
  ext_survey_id: string,
  title: string,
  question_order: number,
  survey: string,
  question_id: string,
  image_url?: string,
  type?: SurveyQuestionType,
  shuffle?: ShuffleMode,
  has_feedback?: boolean,
}

export const defaultQuestion: Question = {
  id: '',
  title: '',
  type: '',
  points: 0,
  answers: [],
  responseIds: [],
  shuffle: 'random',
  nextQuestion: null,
  questionOrder: 0,
  imageUrl: null,
  totalRespondents: 0,
  hasFeedback: false,
};

interface SurveyFull {
  title: string;
  extSurveyId: string;
  points: number;
  questions: Question[];
  audience: string | null;
  category: string | null;
  imageUrl: string | null;
  rank: number | null;
  rankedAt: string | null;
  startDate: string | null;
  endDate: string | null;
  isPublished: boolean;
  maxAICompletes: number | null;
  maxCompletes: number | null;
  extMediaPlanId?: string;
  surveyStatus: SurveyStatus;
}

export const defaultSurveyFull: SurveyFull = {
  title: '',
  extSurveyId: '',
  points: 0,
  questions: [],
  audience: null,
  category: null,
  imageUrl: null,
  rank: null,
  rankedAt: null,
  startDate: null,
  endDate: null,
  isPublished: false,
  maxCompletes: 15,
  maxAICompletes: null,
  surveyStatus: SurveyStatus.Draft,
};

interface SurveyAudienceCriteria {
  readonly 'Education'?: string[];
  readonly 'Employment'?: string[];
  readonly 'Household income'?: string[];
  readonly 'Marital status'?: string[];
  readonly 'Children'?: string[];
  readonly 'Pets'?: string[];
  readonly 'Ethnicity'?: string[];
  readonly 'Place of origin'?: string[];
  readonly 'Political affiliation'?: string[];
  readonly 'Registered to vote'?: string[];
  readonly 'Gender'?: string[];
  readonly 'Age Group'?: string[];
  readonly 'DMA'?: string[];
}
type SurveyAudienceCriteriaKey = keyof SurveyAudienceCriteria;

export const defaultSurveyAudienceCriteria: SurveyAudienceCriteria = {
};


interface SurveyAudienceJSON {
  readonly criteria: SurveyAudienceCriteria;
  readonly total_users: number;
  readonly id: string;
  readonly created_at: string;
  readonly updated_at: string;
  readonly title: string;
  readonly surveys: string | null;
  readonly status: number;
  readonly created_by: string;
}


interface SurveyAudience {
  criteria: SurveyAudienceCriteria;
  totalUsers: number;
  id: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  surveys: string | null;
  status: number;
  createdBy: string;
}

interface SurveyAudienceSmallJSON {
  readonly criteria: SurveyAudienceCriteria;
  readonly total_users: number;
  readonly id: string;
}

interface SurveyAudienceSmall {
  criteria: SurveyAudienceCriteria;
  totalUsers: number;
  id: string;
}

type SurveyData = FetchedList<Survey>;

interface ISurveyState {
  readonly list: SurveyData
}

export enum AnswerSource {
  All, Human, AI, RTR
}

export type {
  UploadImage,
  SaveStatus,
  SurveyJSON,
  Survey,
  ISurveyState,
  SurveyData,
  SurveyFullJSON,
  SurveyFull,
  SurveyAnswerJSON,
  SurveyQuestionJSON,
  Question,
  Answer,
  SurveyAudienceJSON,
  SurveyAudience,
  SurveyAudienceCriteria,
  SurveyAudienceSmallJSON,
  SurveyAudienceSmall,
  QuestionCreationData,
  QuestionUpdateData,
  BaseStatsJSON,
  BaseStats,
  SurveyAnswerStatsJSON,
  SurveyQuestionStatsJSON,
  SurveyStatsJSON,
  AnswerStats,
  QuestionStats,
  SurveyStats,
  DemographicsStatsJSON,
  FullDemographicsStats,
  DemographicsStats,
  SurveyAudienceCriteriaKey,
  AllFeedback,
  AllFeedbackJSON,
  Feedback,
  FeedbackJSON,
  FeedbackUserType,
  ShuffleMode,
  SummaryJSON,
  SurveySummaryDataJSON,
  SurveySummaryQuestion,
  SurveySummaryData,
  FreeformJSON,
  FreeformAnswer,
  AllFreeformAnswers,
};

