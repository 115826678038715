
export interface ILoginState {
  forgotPasswordError: string;
  forgotPasswordLoading: boolean;
  forgotPasswordSuccess: boolean;
  isUserEmail: boolean | null;
  showSocialLoginLoader: boolean;
  userSignUpError: string;
  userSignUpLoading: boolean;
  userSignUpSuccess: boolean;
}


export const defaultLoginState: ILoginState = {
  forgotPasswordError: '',
  forgotPasswordLoading: false,
  forgotPasswordSuccess: false,
  isUserEmail: false,
  showSocialLoginLoader: false,
  userSignUpError: '',
  userSignUpLoading: false,
  userSignUpSuccess: false,
};