import {PropsWithChildren, ReactElement, useState} from "react";
import backArrow from "../../assets/icons/back-arrow.svg";

interface ExpandableTitleProps extends PropsWithChildren {
  readonly initialIsExpanded?: boolean;
  readonly nested?: boolean;
  readonly title: string;
}

const ExpandableTitle = ({ children, initialIsExpanded = false, nested = false, title }: ExpandableTitleProps) => {
  const [isExpanded, setIsExpanded] = useState(initialIsExpanded);

  const onTitleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const renderTitle = (): ReactElement => {
    return (
      <div
        onClick={onTitleClick}
        className="flex flex-row p-4 items-center cursor-pointer"
      >
        <img
          src={backArrow}
          className={`size-3 mr-1 transition-transform duration-200 -scale-y-100 ${isExpanded ? 'rotate-270' : 'rotate-180'}`}
          draggable={false}
        />
        <p className="text-base">
          {title}
        </p>
      </div>
    );
  };

  return (
    <div>
      {!nested && <div className="w-full bg-custom-gray-disabled h-px opacity-10" />}
      {renderTitle()}
      {isExpanded && children}
    </div>
  );
};

export default ExpandableTitle;
