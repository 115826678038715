/* eslint-disable @typescript-eslint/no-unsafe-call,camelcase */
import { logger } from '../error-log/decorators/logger.decorator';

class FreshpaintService {
  private static instance: FreshpaintService | undefined;

  static getInstance(): FreshpaintService {
    if (!FreshpaintService.instance) {
      FreshpaintService.instance = new FreshpaintService();
    }

    return FreshpaintService.instance;
  }

  @logger()
  public identifyUser(extUserID: string, email?: string, name?: string): void {
    if (email && !email.endsWith('@invisiblymail.com') && !email.endsWith('@invisiblyphone.com')) {
      window.freshpaint.identify(extUserID, {
        email,
        name,
      });
    } else {
      window.freshpaint.identify(extUserID);
    }
  }

  @logger()
  public trackCustomEvent(eventName: string, obj: unknown): void {
    const props = (obj as { [index: string]: any });

    window.freshpaint.track(eventName, props);
  }
}

export default FreshpaintService.getInstance();
