export const roles = [
  { label: 'Administrative', value: 'administrative' },
  { label: 'Customer Support', value: 'customer_support' },
  { label: 'Engineering & Technology', value: 'engineering_technology' },
  { label: 'Executive Management', value: 'executive_management' },
  { label: 'Finance & Accounting', value: 'finance_accounting' },
  { label: 'Human Resources', value: 'human_resources' },
  { label: 'Legal', value: 'legal' },
  { label: 'Operations', value: 'operations' },
  { label: 'Product Development', value: 'product_development' },
  { label: 'Sales & Marketing', value: 'sales_marketing' },
];

export const companySizes = [
  { label: '1-10', value: 'micro' },
  { label: '11-50', value: 'small' },
  { label: '51-250', value: 'medium' },
  { label: '251-1000', value: 'large' },
  { label: '1001+', value: 'enterprise' },
];
