import InvisiblyApiService from '../api/invisibly-api.service';
import { apiUrl } from '../../constants/config';
import { asyncLogger } from '../error-log/decorators/async-logger.decorator';
import { ApiStatus } from '../api/api-response.types';
import { B2BUser, User } from './user.types';
import {cleanUpPhoneNumber} from "../../helpers/string.helpers";

interface IUserApiService {
  readonly sendPhoneCode: (phoneNumber: string) => Promise<ApiStatus| string>;
  readonly setOnboarded: () => Promise<User>;
  readonly verifyEmailCode: (token: string) => Promise<string | null>;
  readonly verifyPhoneCode: (phoneNumber: string, phoneCode: string) => Promise<User>;
  readonly setupUserProfile: (extUserId: string, b2bUser: B2BUser) => Promise<B2BUser>;
}

class UserApiService implements IUserApiService {
  private static instance: UserApiService | undefined;

  static getInstance(): UserApiService {
    if (!UserApiService.instance) {
      UserApiService.instance = new UserApiService();
    }

    return UserApiService.instance;
  }

  public async sendPhoneCode(phoneNumber: string): Promise<ApiStatus | string> {
    return await InvisiblyApiService.invokePostRequest(`${apiUrl}/users/get-verification-code`, {
      'phone_number': cleanUpPhoneNumber(phoneNumber),
    });
  }

  public async verifyPhoneCode(phoneNumber: string, phoneCode: string): Promise<User> {
    return await InvisiblyApiService.invokePostRequest<User>(`${apiUrl}/users/verify-code`, {
      code: phoneCode,
      'phone_number': cleanUpPhoneNumber(phoneNumber),
    });
  }

  @asyncLogger(undefined)
  public async verifyEmailCode(token: string): Promise<string | null> {
    return await InvisiblyApiService.invokeGetRequest(`${apiUrl}/users/verify-email`, { token });
  }

  public async setOnboarded(): Promise<User> {
    return await InvisiblyApiService.invokePostRequest<User>(`${apiUrl}/users/set-onboarding`, {
      onboarding: true,
    });
  }

  public async setupUserProfile(extUserId: string, b2bUser: B2BUser): Promise<B2BUser> {
    return await InvisiblyApiService.invokePutRequest<B2BUser>(`${apiUrl}/b2b/user/${extUserId}`, {...b2bUser});
  }

  public async editUserProfile(extUserId: string, b2bUser: B2BUser): Promise<B2BUser> {
    return await InvisiblyApiService.invokePatchRequest<B2BUser>(`${apiUrl}/b2b/user/${extUserId}`, b2bUser);
  }
}

export default UserApiService.getInstance();
