import { JSX, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Menubar } from 'primereact/menubar';
import { connect, ConnectedProps } from 'react-redux';
import { ReduxState } from '../redux/redux-state';
import { signOut } from '../redux/user/user.async-thunks';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { getIsUserAuthenticated } from '../redux/user/user.selectors';

interface LayoutProps extends PropsFromRedux {

}

function Layout({initSignOut, isAuthenticated}: LayoutProps): JSX.Element {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated]);

  const menuItems = [
    {
      label: 'Home',
      icon: 'pi pi-home',
      url: '/admin/surveys',
    },
    {
      label: 'Surveys',
      icon: 'pi pi-fw pi-list',
      items: [
        {
          icon: 'pi pi-list',
          label: 'List',
          url: '/admin/surveys',
        },
        {
          icon: 'pi pi-plus',
          label: 'Create',
          url: '/admin/surveys/csv',
        },
        {
          icon: 'pi pi-plus-circle',
          label: 'Bulk Create Survey',
          url: '/admin/surveys/bulk-create',
        },
      ],
    },
    {
      label: 'Audiences',
      icon: 'pi pi-fw pi-list',
      items: [
        {
          icon: 'pi pi-list',
          label: 'List',
          url: '/admin/audiences',
        },
        {
          icon: 'pi pi-plus',
          label: 'Create',
          url: '/admin/audiences/create',
        },
      ],
    },
    {
      label: 'Discoveries',
      icon: 'pi pi-fw pi-list',
      items: [
        {
          icon: 'pi pi-list',
          label: 'List',
          url: '/admin/discoveries',
        },
        {
          icon: 'pi pi-plus',
          label: 'Create',
          url: '/admin/discoveries/create',
        },
      ],
    },
    {
      label: 'Review',
      icon: 'pi pi-search',
      url: '/admin/review',
    },
    {label: 'Logout', icon: 'pi pi-fw pi-sign-out', command: () => {
      initSignOut();
      navigate('/admin/login');
    }},
  ];

  return (
    <>
      { isAuthenticated &&
        <Menubar model={menuItems} />
      }
      <Outlet />
    </>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  isAuthenticated: getIsUserAuthenticated(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<ReduxState, any, Action>) => ({
  initSignOut: () => dispatch(signOut()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Layout);
