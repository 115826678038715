import { ILoginState } from '../login/login.initial-state';
import { ReduxState } from '../redux-state';
import { IUserState } from './user.initial-state';

export function getIsUserAuthenticated(state: ReduxState): boolean {
  if (state && 'userState' in state && 'anonymousUser' in state.userState && 'extUserId' in state.userState) {
    const { anonymousUser, extUserId, jwt } = state.userState;

    return Boolean(extUserId && !anonymousUser && jwt);
  }

  return false;
}

export function getIsUserSuperAdmin(state: ReduxState): boolean {
  return getIsUserAuthenticated(state) && state.userState.isSuperAdmin;
}

export const getLoginState = (state: ReduxState): ILoginState => {
  // @ts-expect-error typing issue
  return state.loginState;
};

export const getUserB2BOnboardStatus = (state: ReduxState): boolean => {
  return state.userState.setupProfile;
};

export const getUser = (state: ReduxState): Partial<IUserState> => {
  return state.userState;
};

