import { useState } from "react";
import { Question } from "../../redux/survey/survey.types";

export interface UseSurveyPreview {
  readonly activeQuestionIndex: number;
  readonly activeAnswerIds: string[];
  readonly fullscreenImage: string | undefined;
  readonly onPressNext: () => void;
  readonly onPressBack: () => void;
  readonly setActiveAnswerIds: (answerIds: string[]) => void;
  readonly setActiveQuestionIndex: (questionIndex: number) => void;
  readonly setFullscreenImage: (imageUrl?: string) => void
}

export function useSurveyPreview(questions: Question[]): UseSurveyPreview {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [activeAnswerIds, setActiveAnswerIds] = useState<string[]>([]);
  const [fullscreenImage, setFullscreenImage] = useState<string | undefined>(undefined);

  function setQuestionIndex(questionIndex: number) {
    setActiveQuestionIndex(questionIndex);
    setActiveAnswerIds([]);
  }

  function onPressNext() {
    if (activeQuestionIndex === questions.length - 1) {
      return;
    }

    setQuestionIndex(activeQuestionIndex + 1);
    setFullscreenImage(undefined);
  }

  function onPressBack() {
    if (activeQuestionIndex === 0) {
      return;
    }

    setQuestionIndex(activeQuestionIndex - 1);
    setFullscreenImage(undefined);
  }

  return {
    activeQuestionIndex,
    activeAnswerIds,
    fullscreenImage,
    onPressNext,
    onPressBack,
    setActiveQuestionIndex: setQuestionIndex,
    setActiveAnswerIds,
    setFullscreenImage,
  };
}
