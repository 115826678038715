import { AllFreeformAnswers, AnswerSource} from "../../redux/survey/survey.types";
import {ReactElement, useMemo} from "react";
import { freeformListForFilter} from "../../pages/view-survey/view-survey.helpers";
import ExpandableTitle from "./expandable-title";
import TextBubble from "./text-bubble";

interface FreeformResponseListProps {
  readonly allResponses: AllFreeformAnswers;
  readonly sourceFilter: AnswerSource;
}

const FreeformResponseList = ({ allResponses, sourceFilter }: FreeformResponseListProps) => {
  const responses = useMemo(() => {
    return freeformListForFilter(allResponses, sourceFilter);
  }, [allResponses, sourceFilter]);


  const renderResponseList = (): ReactElement => {
    return (
      <div className='flex flex-col gap-2 px-4'>
        <ul className="flex flex-col gap-2">
          {responses.map(({answer, createdAt, userType}, index) => (
            <TextBubble
              feedback={answer}
              createdAt={createdAt}
              userType={userType}
              key={`${userType}-${index}`}
            />
          ))}
        </ul>
      </div>
    );
  };

  const renderEmptyState = (): ReactElement => {
    return (
      <div className="flex flex-row justify-center px-4 pb-4 text-gray-500 text-sm">
        No freeform responses for selected respondent type.
      </div>
    );
  };

  return (
    <ExpandableTitle title={'Freeform Responses'}>
      {responses.length ? (renderResponseList()) : renderEmptyState()}
    </ExpandableTitle>
  );
};

export default FreeformResponseList;
