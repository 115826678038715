import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SurveyResultCard from '../components/survey-results/survey-results-card';
import {
  QuestionStats,
  SurveyAudienceCriteria,
  SurveyQuestionType,
} from '../redux/survey/survey.types';
import SurveyImageUpload from '../components/image-upload/survey-image-upload/survey-image-upload';
import { useSetPageTitle } from "../hooks/useSetPageTitle";
import QuestionSummary from "../components/survey-summary-card/survey-summary-component/survey-summary-component";
import ResultsTargeting from "./view-survey/results-targeting.component";
import ResultFilterList from "./view-survey/result-filter-list.component";
import { CombinedQuestionStats } from "./view-survey/view-survey.types";
import SurveyWrapper from "./view-survey/survey-wrapper.component";
import AISummary, { AISummaryType } from "./view-survey/ai-summary.component";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/redux-store";
import { setSummaryAnimated } from "../redux/survey/survey-animation.slice";
import { useViewSurveyData } from './view-survey/useViewSurveyData';
import AnalyticsService from "../services/analytics/analytics.service";
import { AnalyticEventsEnum } from "../services/analytics/analytic-event-enum";
import PageEmptyState from '../components/page-empty-state/page-empty-state';
import { getIsUserAuthenticated } from '../redux/user/user.selectors';
import AnalyticsManager from '../services/analytics/analytics.service';
import SurveyMediaPlan from "./view-survey/survey-action-plan/survey-media-plan.component";
import {isQA} from "../helpers/env.helpers";

const ViewSurvey: React.FC = () => {
  useSetPageTitle('Survey results');
  const [searchParams] = useSearchParams();
  const extSurveyId = searchParams.get('ext-survey-id') ?? '';
  const shareId = searchParams.get('share-id') ?? '';
  const useableId = extSurveyId !== '' ? extSurveyId : shareId;
  const isShare = Boolean(shareId);
  const { showSummaries, stats, survey, surveyError, summary } = useViewSurveyData(useableId, isShare);
  const mode = surveyError ? 'error' : shareId !== '' ? 'share' : 'view';
  const audienceId = survey?.audience ?? '';
  const [filterCriteria, setFilterCriteria] = useState<SurveyAudienceCriteria | undefined>(undefined);
  const hasShownAnimation = useSelector((state: RootState) => state.surveyAnimationState.hasAnimatedSummary[useableId] || false);
  const dispatch = useDispatch();
  const animationsCompleted = useRef(0);
  const isLoggedIn = useSelector((state: RootState) => getIsUserAuthenticated(state));
  const navigate = useNavigate();

  useEffect(() => {
    shareId && AnalyticsService.trackEvent(AnalyticEventsEnum.ViewSurveyPublic);
  }, [shareId]);

  const {questions, availableDmas } = useMemo(() => {
    if (!stats || !survey) return {questions: [], availableDmas: []};

    const dmas = stats.questions
      .reduce((acc, question) => {
        question.demographics?.dma?.forEach((dma) => {
          if (!acc.includes(dma.title)) {
            acc.push(dma.title);
          }
        });

        return acc;
      }, [] as string[])
      .sort((a, b) => a.localeCompare(b));

    const questionStats: CombinedQuestionStats[] = stats.questions.map((question: QuestionStats) => {
      const matchingQuestion = survey.questions.find(q => q.id === question.id);
      const hasFeedback = matchingQuestion?.hasFeedback || false;
      const summaries = showSummaries ? summary?.questions.find(q => q.id === question.id)?.summaries : undefined;

      return { ...question, hasFeedback, summaries, questionType: matchingQuestion?.type as SurveyQuestionType };
    });
    return {questions: questionStats, availableDmas: dmas };
  }, [stats, survey, summary]);

  const onFilterChange = (filter: SurveyAudienceCriteria) => {
    setFilterCriteria(filter);
  };

  const onSummaryAnimationComplete = () => {
    if (shareId) {
      return;
    }

    const updatedCount = animationsCompleted.current + 1;
    animationsCompleted.current = updatedCount;
    // questions.length + 1 to account for survey level summary
    if (updatedCount === questions.length + 1) {
      dispatch(setSummaryAnimated({ surveyId: useableId }));
    }
  };

  const sharedSummaryProps = {
    shouldAnimateSummaries: !hasShownAnimation,
    surveyStatus: survey?.surveyStatus,
    onComplete: onSummaryAnimationComplete,
  };

  const onEmptyClick = (): void => {
    AnalyticsManager.trackEvent(AnalyticEventsEnum.TapErrorBack);
    navigate(isLoggedIn ? '/dashboard' : '/login');
  };

  const renderMediaPlan = (): ReactElement | null => {
    if (survey?.extSurveyId === undefined || mode !== 'view' || !isQA()) {
      return null;
    }

    return (
      <SurveyMediaPlan
        extSurveyId={survey.extSurveyId}
        surveyStatus={survey.surveyStatus}
      />
    );
  };

  const renderErrorState = (): ReactElement | undefined => {
    if (!surveyError) {
      return undefined;
    }

    return (
      <PageEmptyState
        title="Whoops! Something went wrong. Make sure you have the right link and try again."
        buttonText={isLoggedIn ? 'Back to dashboard' : 'Back to sign up'}
        onButtonClick={onEmptyClick}
      />
    );
  };

  const renderLeftPanel = (): ReactElement => {
    return (
      <>
        {stats?.imageUrl && (
          <SurveyImageUpload
            extSurveyId={extSurveyId}
            src={stats?.imageUrl || null}
            viewImage={true}
          />
        )}
        {Boolean(questions.length) && (
          <div className="mb-6 p-6 rounded-lg border shadow-md bg-white">
            <AISummary
              {...sharedSummaryProps}
              hasAIResponses={Boolean(survey?.maxAICompletes)}
              hasHumanResponses={Boolean(survey?.maxCompletes)}
              summaries={summary?.summaries || []}
              type={AISummaryType.executive}
            />
          </div>
        )}
        {renderMediaPlan()}
        {questions.map((question, index: number) => (
          <SurveyResultCard
            criteria={filterCriteria}
            key={question.title + index}
            question={question}
            answers={question.answers}
            summaryProps={sharedSummaryProps}
            humanMaxCompletes={survey?.maxCompletes ?? undefined}
            aiMaxCompletes={survey?.maxAICompletes ?? undefined}
          />
        ))}
      </>
    );
  };

  const renderRightPanel = (): ReactElement => {
    return (
      <div className="flex flex-col gap-3 mb-4">
        <QuestionSummary
          survey={survey}
          mode={isShare ? 'share' : 'view'}
        />
        {audienceId && (
          <ResultsTargeting
            audienceId={audienceId}
            isShare={isShare}
          />
        )}
        <ResultFilterList
          audienceId={audienceId}
          availableDmas={availableDmas}
          isShare={isShare}
          onFilterChange={onFilterChange}
        />
      </div>
    );
  };

  return (
    <SurveyWrapper
      headerProps={{ mode, survey }}
      emptyState={renderErrorState()}
      leftContent={renderLeftPanel()}
      rightContent={renderRightPanel()}
    />
  );
};

export default ViewSurvey;
