import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {useGetPaymentStatusQuery} from "../../redux/payment/payment.endpoints";
import {SurveyStatus} from "../../redux/survey/survey.types";

const INITIAL_DELAY = 2000;
const REFETCH_DELAY = 5000;

export const usePaymentLandingListener = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sessionId = searchParams.get('session-id') ?? '';
  const [sessionToCheck, setSessionToCheck] = useState<string>('');
  const { data: sessionStatus, error: statusError, refetch } = useGetPaymentStatusQuery({ sessionId: sessionToCheck }, { skip: !sessionToCheck });
  const navigate = useNavigate();
  const lastRefetchedSurvey = useRef<string | undefined>(undefined);

  useEffect(() => {
    !sessionToCheck && sessionId && fetchAfterDelay(sessionId);
  }, [sessionId]);

  useEffect(() => {
    statusError && console.error('Error getting payment status:', statusError);
  }, [statusError]);

  useEffect(() => {
    if (!sessionStatus || !sessionStatus.extSurveyId || !sessionStatus.surveyStatus) {
      return;
    }

    const { extSurveyId, surveyStatus, sessionPaymentStatus } = sessionStatus;

    setSearchParams();
    const isSurveyApproved = surveyStatus === SurveyStatus.Live;
    // Sometimes users are landing from the redirection before the stripe webhook has been hit on the backend
    // This check allows users who aren't rejected but haven't had the webhook hit to wait a few seconds before refetching to confirm payment
    if (!isSurveyApproved && sessionPaymentStatus === 'paid' && surveyStatus === SurveyStatus.Review && lastRefetchedSurvey.current !== extSurveyId) {
      refetchAfterDelay(extSurveyId);
      return;
    }
    setSessionToCheck((''));
    navigate(`${isSurveyApproved ? '/view-survey' : '/create-survey'}?ext-survey-id=${extSurveyId}`);
  }, [sessionStatus]);

  const fetchAfterDelay = (session: string) => {
    setTimeout(() => {
      setSessionToCheck(session);
    }, INITIAL_DELAY);
  };

  const refetchAfterDelay = (surveyId: string) => {
    setTimeout(() => {
      lastRefetchedSurvey.current = surveyId;
      void refetch();
    }, REFETCH_DELAY);
  };
};
