import {currentEnv} from "../constants/config";

function isDev(): boolean {
  return currentEnv === 'DEV';
}

function isProd(): boolean {
  return currentEnv === 'PROD';
}

function isPerf(): boolean {
  return currentEnv === 'PERF';
}

function isQA(): boolean {
  return currentEnv === 'QA';
}

function isDevOrQA(): boolean {
  return isDev() || isQA();
}

function isJest(): boolean {
  // eslint-disable-next-line
  // JEST_WORKER_ID is not defined in the NodeJS namespace
  return Boolean(process.env.JEST_WORKER_ID);
}

function isLocalHost(): boolean {
  return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
}

function getBrowser(): string {
  let userAgent = '';

  if (navigator !== undefined) {
    userAgent = navigator?.userAgent ?? '';
  }

  const documentMode = document?.DOCUMENT_NODE ?? 0;

  // https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browsers
  if ((userAgent.indexOf('Opera') || userAgent.indexOf('OPR')) !== -1) {
    return 'opera';
  }

  if (userAgent.includes('Edg')) {
    return 'edge';
  }

  if (userAgent.includes('Chrome')) {
    return 'chrome';
  }

  if (userAgent.includes('Safari')) {
    return 'safari';
  }

  if (userAgent.includes('Firefox')) {
    return 'firefox';
  }

  if (userAgent.includes('MSIE') || Boolean(documentMode)) {
    // IF IE > 10
    return 'ie';
  }

  return 'unknown';
}

export { getBrowser, isDev, isProd, isPerf, isQA, isDevOrQA, isJest, isLocalHost };
