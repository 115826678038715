/* Unlike TryCatchDecorator, this one is for class methods. Use the log decorator by placing @logger() above the method name to log with a try catch.
  Pass in a default value to return when an error is caught.
*/
/* eslint-disable */
// @ts-nocheck

export function logger(defaultReturn?: any): (target: object, methodName: string, descriptor: PropertyDescriptor) => PropertyDescriptor {
  return (_target: object, methodName: string, descriptor: PropertyDescriptor): PropertyDescriptor => {
    // originalMethod is the method executed
    const originalMethod: () => void = descriptor.value;

    // Wraps the originalMethod in a try/ catch
    // tslint:disable-next-line:no-multi-spaces
    descriptor.value = function (...args: any[]): () => void {
      // tslint:disable-next-line:no-this-assignment
      const classObject: object = this;
      const modifiedMethod = () => {
        let result: () => void;

        try {
          result = originalMethod.apply(classObject, args);
        } catch (exception) {

          return defaultReturn && exception?.message;
        }

        return result;
      };

      return modifiedMethod();
    };

    return descriptor;
  };
}
