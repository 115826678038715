import { ReactElement } from "react";
import { SurveyStatus } from "../../../redux/survey/survey.types";
import shareSvg from '../../../assets/icons/share.svg';
import loading from '../../../assets/icons/loading.svg';
import useShareSurvey from "../../../hooks/useShareSurvey";

export interface SurveyShareButtonProps {
  readonly surveyId?: string;
  readonly surveyStatus?: SurveyStatus;
}

const SurveyShareButton = ({ surveyStatus, surveyId }: SurveyShareButtonProps): ReactElement | null => {
  const { handleShare, isLoading, canShare } = useShareSurvey({ surveyId, surveyStatus });

  if (!canShare) {
    return null;
  }

  return (
    <button
      onClick={handleShare}
      className="sm-max:hidden ml-2 flex items-center rounded-3xl bg-white lg-max:p-2 px-6 py-2 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      title="This button copies a publicly shareable link to this survey’s results"
    >
      <img
        className='mr-2 lg-max:m-0'
        src={isLoading ? loading : shareSvg}
        alt='share survey icon'
        draggable={false}
      />
      <span className="lg-max:hidden">
        Share survey
      </span>
    </button>
  );
};

export default SurveyShareButton;
