// src/ConfirmAccountPage.tsx
import React, { useEffect } from 'react';
import Layout from '../components/layouts/layout-login';
import BackgroundVideo from '../assets/video/invisibly-brand-img.mp4';
import BrandLogo from '../components/brandLogo/brandLogo';
import { Title } from '../components/typography/title';
import { SubTitle } from '../components/typography/subtitle';
import { ConnectedProps, connect, useSelector } from 'react-redux';
import { RootState } from '../redux/redux-store';
import { getIsUserAuthenticated, getLoginState, getUserB2BOnboardStatus } from '../redux/user/user.selectors';
import { ReduxState } from '../redux/redux-state';
import { useNavigate } from 'react-router-dom';
import Conditions from "../components/conditions/conditions.component";

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {}

const ConfirmAccountPage: React.FC<Props> = ({ isAuthenticated }: Props) => {
  const navigate = useNavigate();
  const loginState = useSelector((state: RootState) => getLoginState(state));
  const b2bUserIsOnboarded = useSelector((state: RootState) => getUserB2BOnboardStatus(state));

  useEffect(() => {
    if (isAuthenticated && !b2bUserIsOnboarded) {
      navigate('/setup-profile');
    } else {
      navigate('/dashboard');
    }
  }, [loginState, b2bUserIsOnboarded]);

  return (
    <div>
      <Layout
        imgComponent={(
          <div className='absolute inset-0 overflow-hidden'>
            <video
              className='size-full object-cover'
              autoPlay
              loop
              muted
            >
              <source
                src={BackgroundVideo}
                type='video/mp4'
              />
            </video>
          </div>
        )}
        formComponent={(
          <div className='flex h-screen justify-center pt-64'>
            <div className='flex max-w-[25rem] flex-col'>
              <div className='mb-10 justify-start'>
                <BrandLogo expand={true} />
              </div>
              <div className='mb-10'>
                <Title text={"Next, let's confirm your account. Check your inbox"} />
              </div>
              <div className='my-0'>
                <SubTitle
                  color='text-black'
                  text='We emailed you a link.'
                />
              </div>
              <div className='my-0'>
                <SubTitle
                  color='text-black'
                  text='Click it to confirm your account.'
                />
              </div>
              <div className="grow" />
              <div className='mb-4'>
                <Conditions />
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};


const mapStateToProps = (state: ReduxState) => ({
  isAuthenticated: getIsUserAuthenticated(state),
});

const connector = connect(mapStateToProps, null);

export default connector(ConfirmAccountPage);
