
export interface IUserState {
  anonymousUser: boolean;
  jwe: string;
  jwt: string;
  isSignedIn: boolean;
  isSuperAdmin: boolean;
  extUserId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  profilePic: string;
  signOutInProgress: boolean;
  role: string;
  companyName: string;
  companySize: string;
  setupProfile: boolean;
}

export const defaultUserState: IUserState = {
  anonymousUser: true,
  jwe: '',
  jwt: '',
  isSignedIn: false,
  isSuperAdmin: false,
  extUserId: '',
  firstName: '',
  lastName: '',
  fullName: '',
  email: '',
  profilePic: '',
  signOutInProgress: false,
  role: '',
  companyName: '',
  companySize: '',
  setupProfile: false,
};
