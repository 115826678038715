import { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { useCreateDiscoveryMutation } from "../../redux/admin/discovery/discovery.endpoints";

import { ApiStatus } from '../../services/api/api-response.types';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { LARGE_AUDIENCE_LIST_PAGE_SIZE } from '../../services/admin/audience/audience.constants';
import { useGetAdminSurveyAudiencesQuery } from '../../redux/admin/audience/audience.endpoints';
import {useSetAdminPageTitle} from "../../hooks/useSetPageTitle";

function DiscoveriesCreateScreen() {
  useSetAdminPageTitle('Create discovery');
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [audience, setAudience] = useState<string>('');
  const [submitDiscovery, { isSuccess, error }] = useCreateDiscoveryMutation();
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);

  const categories = ['You', 'Entertainment', 'Tech', 'Community', 'Business', 'Lifestyle', 'Food & Drink', 'Professional', 'Retail', 'Law & Politics', 'Animals', 'Parenting', 'Home & Garden', 'Auto', 'Product Feedback'];

  useEffect(() => {
    if (isSuccess) {
      setDialogTitle('Success');
      setDialogMessage('New Discovery Created');
      setVisible(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      setDialogTitle('Error');
      setDialogMessage((error as ApiStatus).status.status_message);
      setVisible(true);
    }
  }, [error]);


  const { audienceTitle } = useGetAdminSurveyAudiencesQuery({page_size: LARGE_AUDIENCE_LIST_PAGE_SIZE}, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ data }) => {
      if (!data) {
        return { audienceTitle: [] };
      }
      const audienceOptions = data.audiences.map(audience => ({
        label: audience.title || 'Untitled',
        value: audience.id,
      }));
      return { audienceTitle: audienceOptions };
    },
  });


  function createDiscovery(): void {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('category', category);
    formData.append('audience', audience);
    submitDiscovery(formData);
  }

  return (
    <div>
      <div className="justify-content-center flex grid flex-col flex-wrap w-1/2 mx-auto">
        <div className="col p-6">
          <Panel
            header="Create Discovery"
            className='rounded-lg drop-shadow-lg content-center'
          >
            <div className="col-1 m-2">
              <InputText
                className='xm-1 border-2 rounded-md p-2 w-full'
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="col-1 m-2">
              <InputText
                className='xm-1 border-2 rounded-md p-2 w-full'
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="col-1 m-2">
              <Dropdown
                className='xm-1 border-2 rounded-md p-2 w-full'
                value={category}
                onChange={(e) => setCategory(e.value)}
                options={categories}
                placeholder="Select a Category"
              />
            </div>
            <div className="col-1 m-2">
              <Dropdown
                className='xm-1 border-2 rounded-md p-2 w-full'
                value={audience}
                onChange={(e) => setAudience(e.value)}
                options={audienceTitle}
                placeholder="Select an Audience"
              />
            </div>
            <div className="col m-2">
              <Button
                label='Create'
                className='border-2 p-2 w-full bg-blue-400 text-white rounded-md'
                onClick={createDiscovery}
              />
            </div>
          </Panel>
          <Dialog
            header={dialogTitle}
            visible={visible}
            style={{ width: '50vw' }}
            onHide={() => setVisible(false)}
          >
            <p className="m-0">
              {dialogMessage}
            </p>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default DiscoveriesCreateScreen;
