/* eslint-disable @typescript-eslint/no-unsafe-call */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import fernet from 'fernet/fernetBrowser';
import { encryptionKey } from '../../constants/config';
import ErrorLogService from '../error-log/error-log.service';

export const fernetEncrypt = (message: string): string => {
  let _encodedObj = '';
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const iv = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  try {
    const key = encryptionKey;
    const secret = new fernet.Secret(key);
    const token = new fernet.Token({
      iv,
      secret,
      time: Date.parse('1'),
    });

    _encodedObj = token.encode(message);
  } catch (error) {
    ErrorLogService.logError(error, 'helper-functions', 'fernetEncrypt', [message]);
  }

  return _encodedObj || '';
};
