import BrandLogo from '../brandLogo/brandLogo';
import { ProfileAvatar } from '../avatar/profile-avatar';
import { useEffect, useRef, useState } from 'react';
import ProfileMenu from '../profileMenu/profileMenu';
import { connect } from 'react-redux';
import { signOut } from '../../redux/user/user.async-thunks';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AnalyticsManager from '../../services/analytics/analytics.service';
import { AnalyticEventsEnum } from '../../services/analytics/analytic-event-enum';

const { TapLogOut, TapProfile } = AnalyticEventsEnum;

interface DashboardMenubarProps {
  readonly bgColorClass?: string;
  readonly imageUrl?: string;
}

export const DashboardMenubar = ({ bgColorClass = '', imageUrl }: DashboardMenubarProps) => {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { search } = useLocation();

  const handleAvatarClick = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleProfileClick = () => {
    AnalyticsManager.trackEvent(TapProfile);
    const profilePath = search.includes('404=t') ? '/profile?404=t' : '/profile';
    navigate(profilePath);
  };

  const handleLogoutClick = async() => {
    AnalyticsManager.trackEvent(TapLogOut);
    await dispatch(signOut() as any);
    navigate('/login');
  };

  useEffect(() => {
    const handler = (e: any) => {
      if (!menuRef.current?.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
  });

  return (
    <div className={`left-0 top-0 flex w-full items-center justify-between p-4 ${bgColorClass}`}>
      <div className="flex items-center">
        <Link to="/dashboard">
          <BrandLogo />
        </Link>
      </div>
      <div
        className="flex items-center"
        ref={menuRef}
      >
        <ProfileAvatar
          imgSrc={imageUrl}
          onClick={handleAvatarClick}
        />
        <div className="relative" >
          {isMenuOpen && (
            <ProfileMenu
              onProfileClick={handleProfileClick}
              onLogoutClick={handleLogoutClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const connector = connect(null, { signOut });

export default connector(DashboardMenubar);
