import { en } from "../locales/en";
import { DashboardMenubar } from "../components/menubar/menubar";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import ErrorLogService from "../services/error-log/error-log.service";
import { useSetPageTitle } from "../hooks/useSetPageTitle";
import PageEmptyState from "../components/page-empty-state/page-empty-state";

const { title, button } = en.four04;

const Four04 = () => {
  useSetPageTitle('Page not found');
  const navigate = useNavigate();
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams({ '404': 't' });
    ErrorLogService.logError(new Error('404 page not found'), '404.tsx', 'mount', [location]);
  }, []);

  function handleBackToDashboard() {
    navigate('/dashboard');
  }

  return (
    <div className="flex min-h-screen w-full flex-col">
      <DashboardMenubar />
      <PageEmptyState
        buttonText={button}
        onButtonClick={handleBackToDashboard}
        title={title}
      />
    </div>
  );
};

export default Four04;
