import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { webClientId } from './constants/config';

import { Persistor } from './redux/redux-store';
import ReduxService from './redux/redux.service';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter } from 'react-router-dom';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './index.css';
import ErrorLogService from "./services/error-log/error-log.service";

ErrorLogService.init();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={ReduxService.Store}>
    <PersistGate
      loading={null}
      persistor={Persistor}
    >

      <GoogleOAuthProvider clientId={webClientId}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </GoogleOAuthProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
