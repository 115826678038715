import { useField } from 'formik';
import Select from 'react-select';
import dropdownStylesFull from './dropdown-style-full';
import dropdownStyles from './dropdown-style';

interface DropdownOptionProps {
  label: string;
  value: string;
}

interface DropdownProps {
  disabled?: boolean;
  fullWidth?: boolean;
  id: string;
  name: string;
  className?: string;
  placeholder?: string;
  values: DropdownOptionProps[];
  initialValue?: string;
  onChange?: (selectedOption: DropdownOptionProps | null) => void;
  menuPlacement?: 'auto' | 'bottom' | 'top';
}

export const DropdownInput = ({
  disabled,
  fullWidth,
  id,
  name,
  className,
  placeholder,
  values,
  initialValue,
  onChange,
  menuPlacement = 'bottom',
  ...props
}: DropdownProps) => {
  const [field, meta, helpers] = useField(name);

  const handleChange = (selectedOption: any) => {
    helpers.setValue(selectedOption.value);
    if (onChange) {
      onChange(selectedOption);
    }
  };

  const formattedOptions = values.map((option) => ({
    label: option.label,
    value: option.value,
  }));

  const selectedValue = formattedOptions.find(option => option.value === (field.value || initialValue));


  return (
    <>
      <Select
        id={id}
        name={name}
        className={className}
        menuPlacement={menuPlacement}
        styles={fullWidth ? dropdownStylesFull : dropdownStyles}
        options={formattedOptions}
        onChange={handleChange}
        placeholder={placeholder}
        isSearchable={false}
        isDisabled={disabled}
        value={selectedValue}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="mt-1 text-sm text-red-500">
          {meta.error}
        </div>
      ) : null}
    </>
  );
};
