import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultSaveStatus, SaveStatus } from './survey.types';

export const saveStateSlice = createSlice({
  initialState: defaultSaveStatus,
  name: 'saveState',
  reducers: {
    clearSaveState() {
      return defaultSaveStatus;
    },
    setSaveState(state, {payload }: PayloadAction<SaveStatus>) {
      return payload;
    },
  },
  // eslint-disable-next-line sort-keys
  extraReducers: (builder) => {
  },
});

export const {
  clearSaveState,
  setSaveState,
} = saveStateSlice.actions;

export default saveStateSlice.reducer;
