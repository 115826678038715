import logoPath from '../../assets/images/brand-logo.svg';
import logoNamePath from '../../assets/images/logo-name.svg';

interface BrandLogoProps {
  expand?: boolean;
}

const BrandLogo = ({
  expand = false,
}: BrandLogoProps) => {
  const isExpanded = expand ? logoNamePath : logoPath;
  return (
    <div className="logo h-6 w-28">
      <img
        src={isExpanded}
        alt="Brand Logo"
        className="h-8 w-auto"
        draggable={false}
      />
    </div>
  );
};

export default BrandLogo;
