import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/redux-store';
import { getSaveState } from '../../redux/survey/survey-status.selector';
import { SaveStatusEnum } from '../../redux/survey/survey.types';
import checkMark from '../../assets/icons/grayCheckMark.svg';
import lock from '../../assets/icons/lock.svg';
import loading from '../../assets/icons/loading.svg';

interface StatusConfig {
  text: string;
  icon: string;
}

const statusConfig: Record<SaveStatusEnum, StatusConfig> = {
  [SaveStatusEnum.Syncing]: {
    text: 'Syncing...',
    icon: loading,
  },
  [SaveStatusEnum.JustSynced]: {
    text: 'Just Synced',
    icon: checkMark,
  },
  [SaveStatusEnum.Synced]: {
    text: 'Synced',
    icon: lock,
  },
};

const SaveStatus: React.FC = () => {
  const saveStatus = useSelector((state: RootState) => getSaveState(state));

  const { text, icon } = statusConfig[saveStatus.status] || statusConfig[SaveStatusEnum.Synced];

  return (
    <div
      className="flex flex-row items-center justify-end h-6"
      style={{ width: '7.5rem', marginTop:"1px"}}
    >
      <div className="text-sm font-medium text-neutral-500">
        {text}
      </div>
      <img
        className="w-4 mx-2"
        src={icon}
        alt={text}
        draggable={false}
      />
    </div>
  );
};

export default SaveStatus;

