import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as ImageIcon } from '../../../assets/icons/image.svg';
import { useDeleteImageMutation, useGetSurveyQuery, useUploadImageMutation } from '../../../redux/survey/survey.endpoints';
import { SurveyStatus } from '../../../redux/survey/survey.types';
import { useTranslation } from "react-i18next";
import { ALLOWED_IMAGE_TYPE_STRING, ALLOWED_IMAGE_TYPES } from "../image-uploads.constants";
import AnalyticsManager from '../../../services/analytics/analytics.service';
import { AnalyticEventsEnum } from '../../../services/analytics/analytic-event-enum';
import TooltipIcon from "../../tooltip-icon/tooltip-icon.component";
import {InputSwitch, InputSwitchChangeEvent} from "primereact/inputswitch";
import '../question-image-upload/question-image-upload.css';

interface SurveyImageUploadProps {
  extSurveyId: string;
  src: string | null;
  viewImage?: boolean;
}

const SurveyImageUpload: React.FC<SurveyImageUploadProps> = ({ extSurveyId, src, viewImage }) => {
  const { t } = useTranslation();
  const { data } = useGetSurveyQuery(
    { ext_survey_id: extSurveyId },
    { skip: !extSurveyId }
  );

  const [uploadedImageLocal, setUploadedImageLocal] = useState<string | null>(src);
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadImage] = useUploadImageMutation();
  const [deleteImage, { isError: deleteImageError, isLoading: deleteImageLoading, isSuccess: deleteImageSuccess }] = useDeleteImageMutation();
  const [allowImage, setAllowImage] = useState(src !== null);

  useEffect(() => {
    const handleUpload = async() => {
      if (uploadedImage) {
        try {
          const formData = new FormData();
          formData.append('file', uploadedImage);
          formData.append('type', 'survey');
          formData.append('uuid', extSurveyId);
          await uploadImage({ formData: formData, ext_survey_id: extSurveyId }).unwrap();
          AnalyticsManager.trackEvent(AnalyticEventsEnum.UploadSurveyImage);
        } catch (error) {
          console.error('Upload Error:', error);
        }
      }
    };

    handleUpload();
  }, [uploadedImage, uploadImage, extSurveyId]);

  useEffect(() => {
    if (deleteImageSuccess) {
      setUploadedImage(null);
      setUploadedImageLocal(null);
    }
  }, [deleteImageSuccess]);

  useEffect(() => {
    deleteImageError && setAllowImage(true);
  }, [deleteImageError]);

  const handleImageUpload = (file: File) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImageLocal(reader.result as string);
        setUploadedImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'copy';
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files?.[0];
    if (file && ALLOWED_IMAGE_TYPES.includes(file.type)) {
      handleImageUpload(file);
    }
  };

  const handleImageRemove = () => {
    if (!uploadedImageLocal) {
      return;
    }
    void deleteImage({ image_id: extSurveyId, ext_survey_id: extSurveyId, type: "survey" });
  };

  const handleAllowImageToggle = ({ value }: InputSwitchChangeEvent) => {
    if (!value) {
      handleImageRemove();
    }

    setAllowImage(value);
  };

  const renderTitle = () => {
    if (data?.surveyStatus === SurveyStatus.Review && !uploadedImageLocal) {
      return null;
    }

    return (
      <div className="flex flex-row items-center mb-4">
        <div className="text-2xl mr-2">
          Survey cover image
        </div>
        { viewImage ? null : (
          <InputSwitch
            checked={allowImage}
            disabled={deleteImageLoading}
            onChange={handleAllowImageToggle}
            className={`custom-switch ${allowImage ? 'p-inputswitch-checked' : ''}`}
          />
        ) }
        <TooltipIcon
          targetClass="survey-image-toggle"
          message="Optional. Invisibly survey takers will see this image in their feed of surveys to take."
        />
      </div>
    );
  };

  const renderViewImage = () => {
    return (
      <div
        className="relative mb-10 flex h-72 w-full items-center justify-center overflow-hidden rounded-lg bg-white shadow-md"
      >
        {src ? (
          <img
            src={src}
            alt="Uploaded"
            className="object-cover"
          />
        ) : (
          <div className="text-gray-400">
            No Image Available
          </div>
        )}
      </div>
    );
  };

  const renderImageUpload = () => {
    if (!allowImage) {
      return null;
    }

    return (
      <div>
        {!uploadedImageLocal ? (
          <div
            className="relative mb-10 flex h-32 w-full cursor-pointer items-center justify-center overflow-hidden rounded-lg bg-white shadow-md"
            onClick={() => fileInputRef.current?.click()}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <span className="ml-2 flex items-center space-x-2 text-gray-400">
              <ImageIcon className='size-4 object-contain fill-black opacity-50' />
              <div className="flex">
                <span className="mr-1 underline">
                  Upload survey image
                </span>
                {' '}
                or drag and drop here
              </div>
            </span>
            <div className='absolute bottom-3 left-0 right-0 text-center text-gray-400 text-xs'>
              {t('createSurvey.imageSizeRecommendation')}
            </div>
            <input
              ref={fileInputRef}
              id="upload_survey_image"
              type="file"
              accept={ALLOWED_IMAGE_TYPE_STRING}
              className="absolute inset-0 hidden"
              onChange={(e) => handleImageUpload(e.target.files?.[0] as File)}
            />
          </div>
        ) : (
          <div
            className="relative mb-10 flex h-72 w-full items-center justify-center overflow-hidden rounded-lg bg-white shadow-md"
          >
            <img
              src={uploadedImageLocal}
              alt="Uploaded"
              className="object-cover"
            />
            <input
              id="upload_survey_image"
              type="file"
              accept={ALLOWED_IMAGE_TYPE_STRING}
              className="absolute inset-0 cursor-pointer opacity-0"
              onChange={(e) => handleImageUpload(e.target.files?.[0] as File)}
            />
            <div className="absolute right-4 top-4">
              <button
                className="rounded-2xl bg-white px-4 py-1 text-black"
                onClick={handleImageRemove}
              >
                Remove
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      {renderTitle()}
      {viewImage ? renderViewImage() : (
        data?.surveyStatus === SurveyStatus.Review ? (
          <div
            className={uploadedImageLocal ? 'relative mb-10 flex h-72 w-full items-center justify-center overflow-hidden rounded-lg bg-white shadow-md' : ''}
          >
            {uploadedImageLocal ? (
              <img
                src={uploadedImageLocal}
                alt="Review"
                className="object-cover"
              />
            ) : null}
          </div>
        ) :
          renderImageUpload()
      )}
    </div>
  );
};

export default SurveyImageUpload;
