/* eslint-disable consistent-return */
import authService from '../../services/auth/auth.service';
import ErrorLogService from '../../services/error-log/error-log.service';
import { createAppAsyncThunk } from '../redux.helpers';
import authApiService from '../../services/auth/auth.api-service';
import { setUser } from '../user/user.slice';
import NotificationService from '../../services/notification/notification.service';
import AnalyticsManager from '../../services/analytics/analytics.service';
import { AnalyticEventsEnum } from '../../services/analytics/analytic-event-enum';

interface EmailAuthProps {
  email: string;
  password: string;
}

interface GoogleAuthProps {
  credential: string;
}

export interface AppleCredProps {
  readonly firstName?: string;
  readonly idToken: string | null;
  readonly lastName?: string;
}

type AppleAuthProps = {
  readonly code: string;
  readonly id_token: string;
  readonly state?: string;
};

type AppleAuthResponse = {
  readonly authorization: AppleAuthProps;
};

interface ResetPasswordProps {
  readonly password: string;
  readonly token: string;
}

const defaultLoginErrorMessage = 'An error occurred when logging in, please try again later';

const checkIfEmailExists = createAppAsyncThunk('login/checkIfEmailExists', async(email: string) => {
  let emailExists = false;

  try {
    const data = { email: email.toLowerCase() };

    emailExists = await authApiService.checkEmailExists(data);
  } catch (error: any) {
    ErrorLogService.logError(error, 'login.async-thunk', 'initCheckEmailExists', [email]);
  }

  return emailExists;
});


const initEmailSignIn = createAppAsyncThunk<void, EmailAuthProps, { rejectValue: string }>(
  'login/initEmailSignIn',
  async({ email, password }: EmailAuthProps, { dispatch, rejectWithValue }) => {
    try {
      const user = await authService.signIn(email, password);
      AnalyticsManager.trackEvent(AnalyticEventsEnum.SignInWithEmail);
      dispatch(setUser(user));
    } catch (error) {
      ErrorLogService.logError(error, 'login.async-thunks', 'initEmailSignIn', [email]);

      const usableError = NotificationService.handleError(error, dispatch, defaultLoginErrorMessage);
      return rejectWithValue(usableError.message);
    }
  });


const initGoogleSignIn = createAppAsyncThunk<void, GoogleAuthProps>(
  'login/initGoogleSignIn',
  async({ credential }: GoogleAuthProps, { dispatch, rejectWithValue }) => {

    try {
      const user = await authService.signInWithGoogle(credential);
      dispatch(setUser(user));
      if (!user.onboarding) {
        AnalyticsManager.trackEvent(AnalyticEventsEnum.RegisteredUser);
      }
    } catch (error) {
      ErrorLogService.logError(error, 'login.async-thunks', 'initGoogleSignIn');
      NotificationService.handleError(error, dispatch, defaultLoginErrorMessage);
      rejectWithValue();
    }
  });


const initAppleSignIn = createAppAsyncThunk<void, AppleAuthProps>(
  'login/initAppleSignIn',
  async({ id_token }: AppleAuthProps, { dispatch, rejectWithValue }) => {

    try {
      const user = await authService.signInWithApple(id_token);
      dispatch(setUser(user));
      if (!user.onboarding) {
        AnalyticsManager.trackEvent(AnalyticEventsEnum.RegisteredUser);
      }
    } catch (error) {
      NotificationService.handleError(error, dispatch, defaultLoginErrorMessage);
      rejectWithValue();
    }
  });

const initEmailSignUp = createAppAsyncThunk<void, EmailAuthProps, { rejectValue: string }>(
  'login/initEmailSignUp',
  async({ email, password }: EmailAuthProps, { dispatch, getState, rejectWithValue }) => {
    const { CreateWithEmail, RegisteredUser } = AnalyticEventsEnum;
    try {
      const user = await authService.signUp(email, password, getState());
      AnalyticsManager.trackEvent(CreateWithEmail);
      user.signUpState = 'email';
      dispatch(setUser(user));
      NotificationService.handleSuccess(user, dispatch, 'Successfully signed up');
      AnalyticsManager.trackEvent(RegisteredUser);
    } catch (error) {
      ErrorLogService.logError(error, 'login.async-thunks', 'initEmailSignUp', [email]);
      const usableError = NotificationService.handleError(error, dispatch, 'An error occurred when signing up, please try again later');
      return rejectWithValue(usableError.message);
    }
  });

const userForgotPassword = createAppAsyncThunk<void, string, { rejectValue: string }>(
  'login/userForgotPassword',
  async(email: string, { dispatch, rejectWithValue }) => {
    try {
      await authService.forgotPassword(email);
      AnalyticsManager.trackEvent(AnalyticEventsEnum.SendResetLink);
    } catch (error) {
      ErrorLogService.logError(error, 'login.async-thunks', 'userForgotPassword', [email]);
      const usableError = NotificationService.handleError(error, dispatch, 'An error occurred when resetting your password, please try again later');
      return rejectWithValue(usableError.message);
    }
  });

const userResetPassword = createAppAsyncThunk<void, ResetPasswordProps, { rejectValue: string }>(
  'login/userResetPassword',
  async({ password, token }: ResetPasswordProps, { dispatch, rejectWithValue }) => {
    try {
      await authService.resetPassword(password, token);
      AnalyticsManager.trackEvent(AnalyticEventsEnum.SetNewPassword);
      NotificationService.handleSuccess(null, dispatch, 'Successfully reset your password');
    } catch (error) {
      ErrorLogService.logError(error, 'login.async-thunks', 'userResetPassword');
      const usableError = NotificationService.handleError(error, dispatch, 'An error occurred when trying to reset your password, please try again later');
      return rejectWithValue(usableError.message);
    }
  });

export {
  checkIfEmailExists, initAppleSignIn, initGoogleSignIn, initEmailSignIn, initEmailSignUp, userResetPassword, userForgotPassword,
};
