import LocalStorageService from './local-storage.service';

const KeyPrefix = '@INVISIBLY-SURVEYS:';

export const AsyncStorageKeys = {
  batchEvents: `${KeyPrefix}PDP_BATCH_EVENTS`,
  deviceId: `${KeyPrefix}DEVICE_ID`,
  deviceUserIdLinked: `${KeyPrefix}DEVICE_USER_ID_LINKED`,
};

export default class AsyncStorageService {
  static localStorage = new LocalStorageService();
}
