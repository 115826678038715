import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import Input from '../../input/input';
import { Form, Formik } from 'formik';
import { B2BButton } from '../../b2bbutton/b2bbutton';
import { SubTitle } from '../../typography/subtitle';
import { useTranslation } from 'react-i18next';
import { userForgotPassword } from '../../../redux/login/login.async-thunks';
import { ConnectedProps, connect } from 'react-redux';
import { ReactComponent as ConfirmIcon } from '../../../assets/icons/confirm.svg';
import './forgotPasswordDialog.css';
import { forgotPasswordSchema } from '../../../constants/validation.constants';
import AnalyticsManager from '../../../services/analytics/analytics.service';
import { AnalyticEventsEnum } from '../../../services/analytics/analytic-event-enum';

interface Props extends PropsFromRedux {
}

const ForgotPasswordDialog = ({ forgotPassword }: Props) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState<string>('');

  const handleSubmit = async(values: { email: string }) => {
    try {
      const response = await forgotPassword(values.email);
      if (!response.error) {
        setSubmittedEmail(values.email);
        setIsConfirm(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDone = () => {
    setIsVisible(false);
    setIsConfirm(false);
    setSubmittedEmail('');
  };

  const handleForgotPassword = () => {
    setIsVisible(true);
    AnalyticsManager.trackEvent(AnalyticEventsEnum.TapForgotPassword);
  };

  const renderForm = () => (
    <Dialog
      header={t('forgotPassword.title')}
      visible={isVisible}
      className='w-96'
      headerClassName='ml-6 text-black text-center'
      onHide={() => { if (!isConfirm) setIsVisible(false); }}
      draggable={false}
      resizable={false}
    >
      <Formik
        initialValues={{ email: '' }}
        onSubmit={handleSubmit}
        validationSchema={forgotPasswordSchema}
      >
        {({ isValid, dirty, isSubmitting }) => (
          <Form className='mt-2'>
            <div className='text-center'>
              <SubTitle
                className='leading-none !text-base'
                color='text-black'
                text={t('forgotPassword.helpText')}
              />
              <Input
                id={'email'}
                name={'email'}
                placeholder={t('forgotPassword.placeholder')}
                className='mt-4'
              />
            </div>
            <div className='mt-4'>
              <B2BButton
                disable={!isValid || !dirty || isSubmitting}
                expand={true}
                theme='dark'
                label={t('forgotPassword.submit')}
                type='submit'
                size='small'
              />
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );

  const renderConfirmMessage = () => {
    const successMessage = t('forgotPassword.confirm', { email: submittedEmail });
    return (
      <Dialog
        header={t('forgotPassword.confirmTitle')}
        visible={isVisible}
        className='w-96'
        headerClassName='text-black confirm-icon'
        onHide={() => { if (!isConfirm) setIsVisible(false); }}
        closable={false}
        draggable={false}
        resizable={false}
        icons={<ConfirmIcon />}
      >
        <div className='text-center'>
          <SubTitle
            className='leading-none'
            color='text-black !text-base'
            text={successMessage}
          />
          <div className='mt-4'>
            <B2BButton
              expand={true}
              theme='dark'
              label={t('forgotPassword.done')}
              onClick={handleDone}
              size='small'
            />
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <>
      <a
        style={{ cursor: 'pointer' }}
        className='pointer-events-auto text-sm font-normal leading-4 underline underline-offset-4'
        onClick={handleForgotPassword}
      >
        {t("forgotPassword.link")}
      </a>
      <div className=''>
        {isConfirm ? renderConfirmMessage() : renderForm()}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  forgotPassword: (email: string) => dispatch(userForgotPassword(email)),
});

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ForgotPasswordDialog);
