import { RadioButton } from 'primereact/radiobutton';
import { useEffect, useMemo, useState } from 'react';
import { useGetSurveyQuery } from '../../redux/survey/survey.endpoints';
import { SurveyStatus } from '../../redux/survey/survey.types';
import './radio-button.css';
import FirebaseService from "../../services/firebase/firebase.service";
import { CreateSurveyCopy } from "../../pages/create-survey/create-survey.types";
import TooltipIcon from "../tooltip-icon/tooltip-icon.component";

export enum RadioButtonGridType {
  Human, AI, Targeting, AnswerType
}

export interface RadioButtonGridProps {
  extSurveyId: string;
  title: string;
  id?: string;
  subtext?: string;
  buttons: { label: string; value: string }[];
  onClick?: (value: string) => void;
  initialValue?: string,
  disabledForZero?: boolean
  type: RadioButtonGridType
}

export const RadioButtonGrid = ({ extSurveyId, title, subtext, buttons, onClick, initialValue, id, disabledForZero, type }: RadioButtonGridProps) => {
  const { data } = useGetSurveyQuery(
    { ext_survey_id: extSurveyId },
    { skip: !extSurveyId }
  );
  const { aiSampleTooltip, humanSampleTooltip, targetingTooltip } = FirebaseService.getRemoteConfigJSON<CreateSurveyCopy>('CREATE_SURVEY_COPY');
  const tooltipText = useMemo(() => {
    switch (type) {
      case RadioButtonGridType.Human:
        return humanSampleTooltip;
      case RadioButtonGridType.AI:
        return aiSampleTooltip;
      case RadioButtonGridType.Targeting:
        return targetingTooltip;
      default: return '';
    }
  }, [type]);
  const [selectedValue, setSelectedValue] = initialValue ? useState(initialValue) : useState('');
  const isSelected = (button: { label: string, value: string }) => { return selectedValue === button.value; };

  const getContainerClass = (button: { label: string, value: string }) =>
    isSelected(button) ? 'outline-blue-700 text-custom-blue-2 font-bold' : 'outline-1 outline-gray-300';

  const getButtonClass = (button: { label: string, value: string }) =>
    isSelected(button) ? 'radio-button-checked' : 'radio-button-unchecked';

  const handleChange = (value: string) => {
    setSelectedValue(value);
    if (onClick) {
      onClick(value);
    }
  };

  useEffect(() => {
    if (initialValue) {
      setSelectedValue(initialValue);
    }
  }, [initialValue]);

  const description = subtext || '';

  return (
    <div>
      <div className="flex flex-row items-center pb-2">
        <p className='font-semibold .radio-title mr-2'>
          {title}
        </p>
        {tooltipText && (
          <TooltipIcon
            targetClass="radio-title"
            message={tooltipText}
          />
        )}
      </div>
      <div className="mb-1 flex flex-wrap gap-3">
        {buttons.map((button, index) => (
          <div
            key={index}
            className={`flex items-center rounded-lg p-3 outline ${getContainerClass(button)}`}
          >
            <label
              htmlFor={`button_${index}_${button.value}_${id}`}
              className={`mr-2 ${getContainerClass(button)}`}
            >
              {button.label}
            </label>
            <RadioButton
              inputId={`button_${index}_${button.value}_${id}`}
              value={button.value}
              onChange={() => handleChange(button.value)}
              checked={isSelected(button)}
              className={`${getButtonClass(button)}`}
              disabled={data?.surveyStatus === SurveyStatus.Review || (disabledForZero && button.value === '0')}
            />
          </div>
        ))}
      </div>
      {description && (
        <div className='text-sm text-gray-400 pt-1'>
          {description}
        </div>
      )}
    </div>
  );
};
