import {ReactElement} from "react";
import {B2BLink} from "../link/b2blink";
import {WebsiteRedirectLinks} from "../../constants/redirects.constants";

const Conditions = (): ReactElement => {
  return (
    <div className="text-xs leading-4 text-gray-400">
      By signing up you agree to the
      {' '}
      <B2BLink
        className="text-xs"
        label='Terms of Service'
        link={WebsiteRedirectLinks.TermsOfService}
        target='_self'
      />
      {' '}
      and
      {' '}
      <B2BLink
        className="text-xs"
        label='Privacy Policy'
        link={WebsiteRedirectLinks.PrivacyPolicy}
        target='_self'
      />
    </div>
  );
};

export default Conditions;
