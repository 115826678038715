import { Store, UnknownAction } from 'redux';
import { IState } from './redux-state';

class ReduxService {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  public Store: Store<IState, UnknownAction>;
  private static instance: ReduxService | undefined;

  static getInstance(): ReduxService {
    if (!ReduxService.instance) {
      ReduxService.instance = new ReduxService();
    }

    return ReduxService.instance;
  }

  public setReduxStore(store: Store<IState, UnknownAction>): void {
    this.Store = store;
  }
}

export default ReduxService.getInstance();
