/* eslint-disable @typescript-eslint/no-magic-numbers,@typescript-eslint/ban-ts-comment,no-empty-function */

// import DeviceInfo from 'react-native-device-info';

import { AnalyticEventsEnum } from './analytic-event-enum';
import { logger } from '../error-log/decorators/logger.decorator';
import ReduxService from '../../redux/redux.service';
import { currentEnv } from '../../constants/config';
import FreshpaintService from './freshpaint.service';
import { encryptionString } from './encryption.helpers';
import { getBrowser } from '../../helpers/env.helpers';
import ErrorLogService from '../error-log/error-log.service';
import { asyncTimeout } from '../../helpers/async-timeout';

class AnalyticsService {
  commonProperties = {
    browser: getBrowser(),
    userEnvironment: currentEnv,
  };

  constructor() {
    this.trackEvent = this.trackEvent.bind(this);
    this.callAnalyticPlatform = this.callAnalyticPlatform.bind(this);
  }

  async internalTrackEvent(eventName: AnalyticEventsEnum, eventValues: { [key: string]: string | number | null } = {}): Promise<void> {
    await asyncTimeout(1);

    if (eventValues.userData !== 'None' && (eventValues.userData as string)?.length > 0) {
      eventValues.userData = encryptionString(eventValues.userData as string);
    }

    if (!eventValues.application) {
      eventValues.application = AnalyticEventsEnum.ApplicationType;
    }

    // @ts-ignore
    this.callAnalyticPlatform(eventName, eventValues);
  }

  @logger()
  public trackEvent(eventName: AnalyticEventsEnum, eventValues: { [key: string]: string | number | null } = {}): void {
    void this.internalTrackEvent(eventName, eventValues);
  }

  @logger()
  public callAnalyticPlatform(eventName: string, properties: { [key: string]: string } = {}): void {
    const eventValues = {
      ...this.commonProperties,
      ...properties,
    };

    FreshpaintService.trackCustomEvent(eventName, eventValues);
  }

  public setUser(): void {
    const { anonymousUser, email, extUserId, fullName } = ReduxService.Store.getState().userState;

    ErrorLogService.setUser(extUserId, email, anonymousUser);
    FreshpaintService.identifyUser(extUserId, email, fullName);
  }
}

const AnalyticsManager = new AnalyticsService();

export default AnalyticsManager;
