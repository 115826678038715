/* eslint-disable camelcase */
import { IUserState } from '../../redux/user/user.initial-state';
import { User } from './user.types';

class UserParsingService {
  private static instance: UserParsingService | undefined;

  static getInstance(): UserParsingService {
    if (!UserParsingService.instance) {
      UserParsingService.instance = new UserParsingService();
    }

    return UserParsingService.instance;
  }

  parseUserApiData(user: User): Partial<IUserState> {
    const {
      anonymous_user, email, ext_user_id, first_name, fullname, last_name, profile_pic, token, uid, b2b_user, is_super_admin,
    } = user;
    return {
      anonymousUser: anonymous_user,
      email,
      extUserId: ext_user_id,
      firstName: first_name,
      fullName: fullname,
      isSuperAdmin: is_super_admin === true,
      lastName: last_name,
      jwe: uid,
      jwt: token,
      profilePic: profile_pic,
      role: b2b_user.role,
      companyName: b2b_user.company_name,
      companySize: b2b_user.company_size,
      setupProfile: b2b_user.setup_profile,
    };
  }
}

export default UserParsingService.getInstance();
