import {TieredMenu, TieredMenuPassThroughOptions} from 'primereact/tieredmenu';
import './tiered-dropdown.css';
import { useState } from 'react';
import { useDeleteAnswerMutation, useGetSurveyQuery } from '../../redux/survey/survey.endpoints';
import { setSaveStateWithDelay } from '../../redux/survey/survey-status.utils';
import { useDispatch } from 'react-redux';
import { SaveStatusEnum, SurveyStatus } from '../../redux/survey/survey.types';
import AnalyticsManager from '../../services/analytics/analytics.service';
import { AnalyticEventsEnum } from '../../services/analytics/analytic-event-enum';

interface TieredDropdownProps {
  extSurveyId: string;
  isFinalQuestion: boolean;
  isMultiSelect: boolean;
  questionId: string;
  currentQuestion: number;
  answerId: string;
  jumpToIndex: number;
  totalQuestion: number;
  totalAnswer: number;
  handleJumpTo: (jumpIndex: number, reset?: boolean) => void;
}
interface MenuItem {
  label: string,
  icon?: string,
  command?: () => void,
  items?: MenuItem[],
}
export const TieredDropdown = ({ extSurveyId, currentQuestion, jumpToIndex, questionId, totalAnswer, totalQuestion, answerId, handleJumpTo, isFinalQuestion, isMultiSelect }: TieredDropdownProps) => {
  const [selectedItem, setSelectedItem] = useState<string | null>(
    jumpToIndex !== -1 ? `Jump to ${jumpToIndex + 1}` : isFinalQuestion ? "End Survey" : null
  );
  const { data } = useGetSurveyQuery(
    { ext_survey_id: extSurveyId },
    { skip: !extSurveyId }
  );
  const dispatch = useDispatch();
  const [deleteAnswer] = useDeleteAnswerMutation();
  const [isDisplayingNested, setIsDisplayingNested] = useState(false);
  const pt: TieredMenuPassThroughOptions = {
    root: {
      className: `${isDisplayingNested ? 'right-[88px]' : 'right-0'}`,
    },
    submenu: {
      className: 'w-[140px] min-w-[140px]',
    },
  };
  const handleDelete = async() => {
    try {
      await deleteAnswer({ ext_survey_id: extSurveyId, question_id: questionId, answer_id: answerId });
    } catch (error) {
      console.error('Error deleting answer:', error);
    }
    setSaveStateWithDelay(
      dispatch,
      SaveStatusEnum.JustSynced,
      SaveStatusEnum.Synced,
      5000
    );
  };


  const handleItemClick = (index: number, label: string) => {
    if (data?.surveyStatus == SurveyStatus.Review) {
      return;
    }

    !selectedItem && AnalyticsManager.trackEvent(AnalyticEventsEnum.TapSetCondition);
    if (selectedItem === label) {
      handleJumpTo(-1, true);
      setSelectedItem(null);
    } else {
      handleJumpTo(index);
      setSelectedItem(label);
    }
    setIsDisplayingNested(false);
  };

  const getItemIcon = (label: string) => {
    return selectedItem === label ? 'pi pi-check' : '';
  };

  const generateItems = (total: number): MenuItem[] => {
    const jumpToItems = Array.from({ length: totalQuestion - currentQuestion - 2 }, (_, index) => ({
      label: `Jump to ${currentQuestion + index + 3}`,
      icon: getItemIcon(`Jump to ${currentQuestion + index + 3}`),
      command: () => handleItemClick(currentQuestion + index + 2, `Jump to ${currentQuestion + index + 3}`),
    }));

    const items: MenuItem[] = [];

    if (totalQuestion - 1 - currentQuestion !== 0 && !isMultiSelect) {
      items.push(
        {
          label: 'Set conditions',
          command: () => setIsDisplayingNested(!isDisplayingNested),
          items: [
            ...jumpToItems,
            ...(currentQuestion < totalQuestion - 1 ? [
              {
                label: 'End Survey',
                icon: getItemIcon('End Survey'),
                command: () => handleItemClick(total, 'End Survey'),
              },
            ] : []),
          ],
        }
      );
    }

    if (totalAnswer > 2) {
      items.push({
        label: 'Delete',
        command: handleDelete,
      });
    }

    return items;
  };

  return (
    <div className="tiered-menu">
      <TieredMenu
        model={generateItems(totalQuestion)}
        pt={pt}
      />
    </div>
  );
};
