import React, {useMemo, useRef} from 'react';
import gsap from 'gsap';
import {useGSAP} from "@gsap/react";

const PERCENTAGE_ANIMATION_DURATION = 1.5;
interface AnswerResultBarProps {
  answerLabel: string;
  hasImageAnswers?: boolean;
  imgSrc?: string | null;
  topAnswer: boolean;
  totalCount: number;
  userPercent: number;
}

const AnswerResultBar: React.FC<AnswerResultBarProps> = ({
  answerLabel,
  hasImageAnswers,
  imgSrc,
  topAnswer,
  totalCount,
  userPercent,
}) => {
  const barRef = useRef<HTMLDivElement | null>(null);
  const percentageRef = useRef<HTMLSpanElement | null>(null);
  const totalCountRef = useRef<HTMLSpanElement | null>(null);
  const percentage = useMemo(() => parseFloat((userPercent ?? 0).toFixed(2)), [userPercent]);
  useGSAP(() => {
    gsap.to(barRef.current, {
      duration: PERCENTAGE_ANIMATION_DURATION,
      width: `${percentage}%`,
    });
    gsap.to(percentageRef.current, {
      duration: PERCENTAGE_ANIMATION_DURATION,
      scrambleText: {
        chars:"0123456789",
        text: `${percentage}`,
      },
    });
  }, [percentage]);
  useGSAP(() => {
    gsap.to(totalCountRef.current, {
      duration: PERCENTAGE_ANIMATION_DURATION,
      scrambleText: {
        chars:"0123456789",
        text: `${totalCount}`,
      },
    });
  }, [totalCount]);

  const containerClasses = `${hasImageAnswers ? 'h-20' : 'h-14'} w-full rounded-2xl relative overflow-hidden transition-all ${
    topAnswer ? 'bg-custom-blue-1' : 'bg-gray-200'
  }`;

  const barClasses = `w-0 ${percentage >= 98 ? 'rounded-2xl' : 'rounded-l-2xl'}
    ${topAnswer ? 'bg-custom-blue-2' : 'bg-gray-300'}
    ${hasImageAnswers ? 'h-20' : 'h-14'}`
  ;

  const textClasses = `absolute inset-0 flex items-center justify-center 
    ${topAnswer ? 'text-white' : 'text-black'}
  `;

  const accessoryClasses = `absolute inset-0 flex items-center px-2
    ${imgSrc ? 'justify-between' : 'justify-end'}
  `;

  const percentageClasses = `${
    topAnswer ? 'text-white  rounded-full bg-white/[.1] px-2' : 'text-custom-blue-1 border rounded-full bg-custom-blue-gray px-2'
  }`;

  return (
    <div className="w-full rounded-lg py-2">
      <div className={containerClasses}>
        <div
          className={barClasses}
          ref={barRef}
        />
        <span className={textClasses}>
          <span className="overflow-hidden text-ellipsis whitespace-nowrap w-3/5 micro:w-1/5 text-center">
            {answerLabel}
          </span>
        </span>
        <div className={accessoryClasses}>
          {imgSrc && (
            <img
              className="size-16 rounded-lg"
              src={imgSrc}
              alt=""
            />
          )}
          <div className={percentageClasses}>
            <span ref={percentageRef}>
              {percentage}
            </span>
            {/**/}
            % (
            <span ref={totalCountRef}>
              {totalCount}
            </span>
            {/**/}
            )
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnswerResultBar;
