import user from '../../assets/icons/user.svg';
export interface ProfileAvatarProps {
  imgSrc?: string;
  onClick?: () => void;
}
export const ProfileAvatar = ({
  imgSrc = '',
  onClick,
}: ProfileAvatarProps) => {
  const cursorClass = onClick ? 'cursor-pointer' : 'cursor-default';
  return (
    <div
      className={`flex size-8 ${cursorClass} items-center justify-center overflow-hidden rounded-full`}
      onClick={onClick}
    >
      {imgSrc ? (
        <img
          src={imgSrc}
          alt="Profile Avatar"
          className="size-full object-cover"
        />
      ) : (
        <img
          src={user}
          alt="user"
          className="w-10"
          draggable={false}
        />
      )}
    </div>
  );
};
