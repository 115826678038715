import {CommonLinks} from "./link.constants";

export interface B2BLinkProps {
  label: string;
  link: CommonLinks | string;
  target?: string;
  className?: string;
  onClick?: () => void;
}

export const B2BLink = ({
  label,
  link,
  target = '_blank',
  className,
  onClick,
}: B2BLinkProps) => {
  return (
    <a
      className={`text-sm font-normal leading-4 underline underline-offset-4 ${className}`}
      href={link}
      target={target}
      onClick={onClick}
    >
      {label}
    </a>
  );
};
