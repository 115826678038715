import { ReactElement, useState } from 'react';
import SurveyAnswerList from "./survey-answer-list";
import SurveyPreviewFooter from "./survey-preview-footer";
import { Question, SurveyQuestionType } from "../../../redux/survey/survey.types";
import { UseSurveyPreview } from "../useSurveyPreview";
import { partial } from 'lodash';
import { Button } from 'primereact/button';
import plusBlack from "../../../assets/icons/Plus-black.svg";
import FreeformQuestionPreview from "./freeform-question-preview.component";

const { MultiSelect, Freeform } = SurveyQuestionType;
export interface PhoneContentsProps {
  readonly questions: Question[];
  readonly surveyPreviewProps: UseSurveyPreview;
}

const SurveyPreview = ({ questions, surveyPreviewProps }: PhoneContentsProps): ReactElement | null => {
  const [fakeLoading, setFakeLoading] = useState(false);
  const { fullscreenImage, setFullscreenImage, onPressNext, setActiveAnswerIds, activeAnswerIds, activeQuestionIndex } = surveyPreviewProps;

  if (!questions[activeQuestionIndex]) {
    return null;
  }

  const { title, answers, imageUrl, type } = questions[activeQuestionIndex];

  function handleAnswerClick(answerId: string) {
    if (fakeLoading) {
      return;
    }

    if (type !== MultiSelect) {
      setFakeLoading(true);
      setActiveAnswerIds([answerId]);
      handleDelayNext();
    } else {
      const newAnswerIds = activeAnswerIds.includes(answerId) ? activeAnswerIds.filter(id => id !== answerId) : [...activeAnswerIds, answerId];
      setActiveAnswerIds(newAnswerIds);
    }
  }

  function handleDelayNext() {
    setFakeLoading(true);
    setTimeout(() => {
      setFakeLoading(false);
      onPressNext();
    }, 200);
  }

  function renderQuestionImage(): ReactElement | null {
    if (!imageUrl) {
      return null;
    }

    return (
      <div className='flex justify-center -mb-2'>
        <img
          src={imageUrl}
          className='object-cover max-h-[200px] rounded-2xl'
          alt='question related image'
        />
      </div>
    );
  }

  function renderFullScreenImage(): ReactElement {
    return (
      <div className='flex size-full'>
        <div className={`top-12 right-4 absolute z-10`}>
          <Button
            onClick={partial(setFullscreenImage, undefined)}
            className='flex justify-center items-center bg-gray-300 bg-opacity-30 size-8 rounded-full'
          >
            <img
              src={plusBlack}
              alt='close'
              className='rotate-45'
              draggable={false}
            />
          </Button>
        </div>
        <img
          src={fullscreenImage}
          className='object-contain'
        />
      </div >
    );
  }

  if (fullscreenImage) {
    return renderFullScreenImage();
  }

  return (
    <div className='flex h-full flex-col justify-between gap-6 px-4 py-6'>
      <div className={`no-scrollbar flex flex-col gap-6 overflow-y-scroll ${type === Freeform ? 'grow' : ''}`}>
        <span className='text-center'>
          {title}
        </span>
        {renderQuestionImage()}
        {type === Freeform ? (
          <FreeformQuestionPreview />
        ) : (
          <SurveyAnswerList
            answers={answers}
            activeAnswerIds={activeAnswerIds}
            onAnswerClick={handleAnswerClick}
            onExpandClick={setFullscreenImage}
          />
        )}
      </div>
      <SurveyPreviewFooter
        onPressContinue={onPressNext}
        continueState={type !== MultiSelect ? 'hidden' : activeAnswerIds.length > 0 ? 'visible' : 'disabled'}
        totalQuestions={questions.length}
        activeIndex={activeQuestionIndex}
        isLoading={fakeLoading}
      />
    </div>
  );
};

export default SurveyPreview;
