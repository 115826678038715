/* eslint-disable max-len,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-argument,@typescript-eslint/ban-ts-comment,no-param-reassign */
import ErrorLogService from '../error-log/error-log.service';
import { logger } from '../error-log/decorators/logger.decorator';
import axios, { AxiosResponse } from 'axios';
import { InvisiblyApiResponse } from './api-response.types';
import {checkIsNetworkCallSuccess} from "../../helpers/response.helpers";


type InvisiblyAxiosResponse<T> = AxiosResponse<InvisiblyApiResponse<T>>;

interface IApiService {
  readonly init: () => void;
  readonly invokeDeleteRequest: <T>(URL: string, params: any) => Promise<T>;
  readonly invokeGetRequest: <T>(URL: string, params: any, headers: any) => Promise<T | null>;
  readonly invokeLoggedGetRequest: <T>(url: string, params: NonNullable<unknown>, expectNull: boolean) => Promise<T | null>;
  readonly invokeLoggedPostRequest: <T>(url: string, params: NonNullable<unknown>, successCodes: number[]) => Promise<T>;
  readonly invokeLoggedPutRequest: <T>(url: string, params: any) => Promise<T>;
  readonly invokePostRequest: <T>(url: string, params: NonNullable<unknown>, successCodes: number[]) => Promise<T>;
  readonly invokePostWithoutTokenRequest: <T>(url: string, params: any, headers: any) => Promise<T>;
  readonly invokePutRequest: <T>(url: string, params: any) => Promise<T>;
  readonly responseIsOk: (response: AxiosResponse) => boolean;
  readonly setAuthorizationToken: (token: string) => void;
  readonly invokeDownloadRequest: (url: string, fileName: string) => Promise<void>;
}

class InvisiblyApiService implements IApiService {
  private static instance: InvisiblyApiService | undefined;

  static getInstance(): InvisiblyApiService {
    if (!InvisiblyApiService.instance) {
      InvisiblyApiService.instance = new InvisiblyApiService();
    }

    return InvisiblyApiService.instance;
  }

  private initializeAxiosDefaults(): void {
    axios.defaults.timeout = 30000;
  }

  private parseUrl(url: string): string {
    return url.split('/')
      ?.pop()
      ?.replace(/-([a-z])/g, (value) => value[1].toUpperCase()) ?? '';
  }

  public setAuthorizationToken(token: string): void {
    if (token) {
      axios.defaults.headers.common.Authorization = `JWT ${token}`;
    }
  }

  @logger()
  public init(): void {
    void this.initializeAxiosDefaults();
  }

  public async invokeLoggedPutRequest<T>(url: string, params: any): Promise<T> {
    return await ErrorLogService.withTransactionLog(this.invokePutRequest<T>(url, params), this.parseUrl(url));
  }

  public async invokePutRequest<T>(url: string, params: any): Promise<T> {
    return await new Promise((resolve, reject) => {
      axios.put<InvisiblyApiResponse<T>>(url, params)
        .then((response: InvisiblyAxiosResponse<T>) => {
          const responseData = response?.data;

          if (checkIsNetworkCallSuccess(responseData, [])) {
            const responseItem = response?.data?.item;

            resolve(responseItem);
          } else {
            reject(responseData?.status);
          }
        })
        .catch((error: Error) => {
          reject(error);
          ErrorLogService.logError(error, 'invisibly-api.service', 'invokePUTRequest', [...arguments]);
        });
    });
  }

  public async invokeLoggedPatchRequest<T>(url: string, params: any): Promise<T> {
    return await ErrorLogService.withTransactionLog(this.invokePatchRequest<T>(url, params), this.parseUrl(url));
  }

  public async invokePatchRequest<T>(url: string, params: any): Promise<T> {
    return await new Promise((resolve, reject) => {
      axios.patch<InvisiblyApiResponse<T>>(url, params)
        .then((response: InvisiblyAxiosResponse<T>) => {
          const responseData = response?.data;

          if (checkIsNetworkCallSuccess(responseData, [])) {
            const responseItem = response?.data?.item;

            resolve(responseItem);
          } else {
            reject(responseData?.status);
          }
        })
        .catch((error: Error) => {
          reject(error);
          ErrorLogService.logError(error, 'invisibly-api.service', 'invokePUTRequest', [...arguments]);
        });
    });
  }

  public async invokeLoggedPostRequest<T>(url: string, params = {}, successCodes: number[] = []): Promise<T> {
    return await ErrorLogService.withTransactionLog(this.invokePostRequest<T>(url, params, successCodes), this.parseUrl(url));
  }

  public async invokePostRequest<T>(url: string, params = {}, successCodes: number[] = []): Promise<T> {
    return await new Promise((resolve, reject) => {
      axios.post(url, params)
        .then((response: InvisiblyAxiosResponse<T>) => {
          const responseData = response?.data;

          if (checkIsNetworkCallSuccess(responseData, successCodes)) {
            const responseItem = response?.data?.item;

            resolve(responseItem);
          } else {
            reject(responseData?.status);
          }
        })
        .catch((error: Error) => {
          reject(error);
          ErrorLogService.logError(error, 'invisibly-api.service', 'invokePostRequest', [...arguments]);
        });
    });
  }

  public async invokePostWithoutTokenRequest<T>(url: string, params: any, headers: any): Promise<T> {
    return await new Promise((resolve, reject) => {
      if (headers === null) {
        // eslint-disable-next-line no-param-reassign
        headers = {};
      }

      headers.Accept = 'application/json';
      headers['Content-Type'] = 'application/json';
      axios.post<InvisiblyApiResponse<T>>(url, params, { headers })
        .then((response: InvisiblyAxiosResponse<T>) => {
          // @ts-ignore
          const respJSON = response.json();

          resolve(respJSON.data);
        })
        .catch((error: Error) => {
          reject(error);
          ErrorLogService.logError(error, 'invisibly-api.service', 'invokePostWithoutTokenRequest', [...arguments]);
        });
    });
  }


  public async invokeLoggedGetRequest<T>(url: string, params = {}, expectNull = false): Promise<T | null> {
    return await ErrorLogService.withTransactionLog(this.invokeGetRequest<T>(url, params, expectNull), this.parseUrl(url));
  }

  public async invokeGetRequest<T>(url: string, params = {}, expectNull = false): Promise<T | null> {
    return await new Promise((resolve, reject) => {
      axios.get<InvisiblyApiResponse<T>>(url, { params })
        .then((response: InvisiblyAxiosResponse<T>) => {
          const responseData = response?.data;

          if (checkIsNetworkCallSuccess(responseData)) {
            resolve(responseData?.item);
          } else {
            expectNull && resolve(null);
            reject(null);
          }
        })
        .catch((error: Error) => {
          ErrorLogService.logError(error, 'invisibly-api.service', 'invokeGetRequest', [...arguments]);
          reject(error);
        });
    });
  }

  public async invokeDownloadRequest(url: string, fallbackFileName: string) {
    axios.get(url, { responseType: 'blob', withCredentials: true })
      .then((response: AxiosResponse) => {
        let responseFilename = '';
        if (response.headers['content-disposition']) {
          const fileNameRegex = response.headers['content-disposition'].match(/filename="([^"]+)"/);
          if (fileNameRegex && fileNameRegex.length > 1) {
            responseFilename = fileNameRegex[1];
          }
        }

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', responseFilename || fallbackFileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error: Error) => {
        ErrorLogService.logError(error, 'invisibly-api.service', 'invokeDownloadRequest', [...arguments]);
      });
  }
  /**
   *
   * @param URL
   * @param params
   * @param headers
   */
  public async invokeDeleteRequest<T>(URL: string, params: any): Promise<T> {
    return await new Promise((resolve, reject) => {
      axios.delete<InvisiblyApiResponse<T>>(URL, { params })
        .then((response: InvisiblyAxiosResponse<T>) => {
          resolve(response.data.item);
        })
        .catch((error: Error) => {
          ErrorLogService.logError(error, 'invisibly-api.service', 'invokeDeleteRequest', [...arguments]);
          reject(error);
        });
    });
  }

  public responseIsOk(response: AxiosResponse): boolean {
    if (response.data !== undefined && response.data !== null) {
      return true;
    }

    return false;
  }
}

export default new InvisiblyApiService();
