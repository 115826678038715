import { createApi } from '@reduxjs/toolkit/query/react';
import { apiUrl } from '../../constants/config';
import { axiosBaseQuery } from './baseQuery';
import { ApiResponses } from '../../types/apiResponses';
import { APITagType } from './invisibly-api.types';
import { ALL_API_TAG_TYPES } from './invisibly-api.constants';

export const invisiblyApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: `${apiUrl}/` }),
  // Need to add one endpoint in here, so that injecting in other places will work properly
  endpoints: (build) => ({
    getSuspensionReasons: build.query<ApiResponses.SuspensionReason[], void>({
      providesTags: () => {
        return [{
          id: 'LIST',
          type: APITagType.SuspensionReason,
        }];
      },
      query: () => ({
        method: 'get',
        url: 'suspend-reasons',
      }),
      transformResponse: (response) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return response.item;
      },
    }),
  }),
  reducerPath: 'invisiblyApi',
  refetchOnReconnect: true,
  tagTypes: ALL_API_TAG_TYPES,
});
