import { DataTable} from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';

import { ProgressSpinner } from 'primereact/progressspinner';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import dayjs from 'dayjs';
import { useState } from 'react';

import { AUDIENCE_LIST_INITIAL_PAGE, AUDIENCE_LIST_PAGE_SIZE, DATE_TIME_FORMAT } from '../../services/admin/audience/audience.constants';
import { useGetAdminSurveyAudiencesQuery } from '../../redux/admin/audience/audience.endpoints';
import { Audience } from '../../redux/admin/audience/audience.types';
import './data-table-list.css';
import {useSetAdminPageTitle} from "../../hooks/useSetPageTitle";

function AudienceListScreen() {
  useSetAdminPageTitle('Audiences');
  const [page, setPage] = useState(AUDIENCE_LIST_INITIAL_PAGE);
  const [pageSize, setPageSize] = useState(AUDIENCE_LIST_PAGE_SIZE);

  const { data, isLoading } = useGetAdminSurveyAudiencesQuery({ page, page_size: pageSize }, { refetchOnMountOrArgChange: true });

  const audiences = data?.audiences;
  const totalObjects = data?.totalObjects;

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    const nextPage = Math.ceil((event.first / pageSize) + 1);
    setPage(nextPage);
    setPageSize(event.rows);
  };

  const createdDateTemplate = (audience: Audience) => {
    const date = audience.createdAt;
    if (date)
      return dayjs(date).format(DATE_TIME_FORMAT);
    else
      return '';
  };


  return (
    <Card
      title="Audiences"
      className='m-4 rounded-lg drop-shadow-lg'
    >
      { data && (
        <div className="card">
          <DataTable
            stripedRows
            size="small"
            value={audiences}
            style={{ cursor:'pointer'}}
            tableStyle={{ minWidth: '50rem' }}
            filterDisplay='menu'
            className='border-2'
          >
            <Column
              field="createdAt"
              header="Created"
              body={createdDateTemplate}
              sortable
            />
            <Column
              field="totalUsers"
              header="Total Users"
              sortable
            />
            <Column
              field="title"
              header="Title"
              sortable
              filter
            />
            <Column
              field="criteria.Gender"
              header="Gender"
              sortable
              filter
            />
            <Column
              field="criteria.Pets"
              header="Pets"
              sortable
              filter
            />
            <Column
              field="criteria.Children"
              header="Children"
              sortable
              filter
            />
            <Column
              field="criteria.Education"
              header="Education"
              sortable
              filter
            />
            <Column
              field="criteria.Ethnicity"
              header="Ethnicity"
              sortable
              filter
            />
            <Column
              field="criteria.Employment"
              header="Employment"
              sortable
              filter
            />
            <Column
              field="criteria.Marital status"
              header="Marital status"
              sortable
              filter
            />
            <Column
              field="criteria.Place of origin"
              header="Place of origin"
              sortable
              filter
            />
            <Column
              field="criteria.Household income"
              header="Household income"
              sortable
              filter
            />
            <Column
              field="criteria.Registered to vote"
              header="Registered to vote"
              sortable
              filter
            />
            <Column
              field="criteria.Political affiliation"
              header="Political affiliation"
              sortable
              filter
            />
            <Column
              field="criteria.Age Group"
              header="Age"
              sortable
              filter
            />
          </DataTable>
          <Paginator
            first={(page-1) * pageSize}
            rows={pageSize}
            totalRecords={totalObjects}
            rowsPerPageOptions={[25, 50, 100, 500]}
            onPageChange={onPageChange}
          />

        </div>
      )}
      { isLoading && (
        <div className="m-4">
          <div className="grid">
            <div className="col">
              <ProgressSpinner />
            </div>
          </div>
        </div>
      )}

    </Card>
  );
}

export default AudienceListScreen;
