import {
  AllFeedback,
  AllFreeformAnswers,
  AnswerSource,
  Feedback, FreeformAnswer,
  SurveyAudienceCriteria,
} from "../../redux/survey/survey.types";

const { AI, All, Human, RTR } = AnswerSource;
const convertCriteriaToList = (criteria: SurveyAudienceCriteria): string[] => {
  return Object.entries(criteria).flatMap(([key, value]) => {
    return (value as string[]).map(val => `${key}: ${val}`);
  });
};

const feedbackListForFilter = (feedback: AllFeedback, sourceFilter: AnswerSource): Feedback[] => {
  const { aiAnswers, humanAnswers, rtrAnswers } = feedback;

  const allFeedback: Feedback[] = [];
  const showHuman = sourceFilter === All || sourceFilter === Human;
  showHuman && allFeedback.push(...humanAnswers);
  const showAI = sourceFilter === All || sourceFilter === AI;
  showAI && allFeedback.push(...aiAnswers);
  const showRTR = sourceFilter === All || sourceFilter === RTR;
  showRTR && allFeedback.push(...rtrAnswers);

  return allFeedback;
};

const freeformListForFilter = (answers: AllFreeformAnswers, sourceFilter: AnswerSource): FreeformAnswer[] => {
  const { aiUsers, humanUsers, rtrUsers } = answers;

  const allAnswers: FreeformAnswer[] = [];
  const showHuman = sourceFilter === All || sourceFilter === Human;
  showHuman && allAnswers.push(...humanUsers);
  const showAI = sourceFilter === All || sourceFilter === AI;
  showAI && allAnswers.push(...aiUsers);
  const showRTR = sourceFilter === All || sourceFilter === RTR;
  showRTR && allAnswers.push(...rtrUsers);

  return allAnswers;
};

export { convertCriteriaToList, feedbackListForFilter, freeformListForFilter };
