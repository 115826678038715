import {defaultNotificationState} from "./notification.initial-state";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IMessage} from "./notification.types";
import {addNotificationToQueue, removeNotificationFromQueue} from "./notification.operations";

export const notificationSlice = createSlice({
  initialState: defaultNotificationState,
  name: 'notification',
  reducers: {
    clearNotificationState() {
      return defaultNotificationState;
    },
    removeNotification(state, { payload }: PayloadAction<IMessage>) {
      state.messages = removeNotificationFromQueue(payload, state.messages);
    },
    showNotification(state, { payload }: PayloadAction<IMessage>) {
      state.messages = addNotificationToQueue(payload, state.messages);
    },
  },
  // eslint-disable-next-line sort-keys
  extraReducers: (builder) => {
  },
});

export const {
  clearNotificationState,
  removeNotification,
  showNotification,
} = notificationSlice.actions;

export default notificationSlice.reducer;
