/* eslint-disable */
// @ts-nocheck

/* This decorator is for class methods. Use the log decorator by placing @asyncLogger() above the method name to log with a try catch.
 Pass in a default value to return when an error is caught.
 // IMPORTANT: do not use on an arrow method
*/
export function asyncLogger(defaultReturn?: any): (target: object, methodName: string, descriptor: PropertyDescriptor) => PropertyDescriptor {
  return (_target: object, methodName: string, descriptor: PropertyDescriptor): PropertyDescriptor => {
    // originalMethod is the method executed
    const originalMethod: () => Promise<void> = descriptor.value;

    // Wraps the originalMethod in a try/ catch
    descriptor.value = async function(...args: any[]): Promise<any> {
      // tslint:disable-next-line:no-this-assignment
      const classObject: object = this;
      const modifiedMethod = async() => {
        let result: () => void;

        try {
          result = await originalMethod.apply(classObject, args);
        } catch (exception) {
          return defaultReturn || exception?.message;
        }

        return result;
      };

      return modifiedMethod();
    };

    return descriptor;
  };
}
