import { ReactElement } from 'react';
import BatterySvg from '../../../assets/icons/battery.svg';
import ServiceBarsSvg from '../../../assets/icons/service-bars.svg';
import WifiSvg from '../../../assets/icons/wifi.svg';

export interface PhoneOutlineProps {
  readonly children?: ReactElement | ReactElement[];
  readonly showButtons?: boolean;
  readonly showIsland?: boolean;
}

const PhoneOutline = ({ children, showIsland = true, showButtons = false }: PhoneOutlineProps): ReactElement => {
  function renderNotificationBar(): ReactElement {
    return (
      <div className='flex flex-row justify-between px-4 py-3'>
        <div className='flex w-[70px] font-sans text-xs'>
          9:41
        </div>
        {showIsland && <div className="h-4 w-16 self-center rounded-full border border-black bg-black" />}
        <div className='flex w-[70px] items-center justify-end gap-1'>
          <img
            src={ServiceBarsSvg}
            alt="wifi"
          />
          <img
            src={WifiSvg}
            alt="wifi"
          />
          <img
            src={BatterySvg}
            alt="wifi"
          />
        </div>
      </div>
    );
  }

  function renderSideButtons(): ReactElement | null {
    if (!showButtons) {
      return null;
    }

    return (
      <>
        <div className="absolute -right-1.5 top-20 h-10 rounded-md border-2 border-black" />
        <div className="absolute -left-1.5 top-16 h-6 rounded-md border-2 border-black" />
        <div className="absolute -left-1.5 top-32 h-12 rounded-md border-2 border-black" />
        <div className="absolute -left-1.5 top-48 h-12 rounded-md border-2 border-black" />
      </>
    );
  }

  /*
  inspiration
  https://preline.co/docs/devices.html
  https://tailwindflex.com/@prajwal/mobile-device-mockup-iphone-14-pro
  https://flowbite.com/docs/components/device-mockups/
   */
  return (
    <div className='flex'>
      <div className="relative h-[600px] w-[280px] rounded-2xl border-4 border-black bg-gray-100">
        {renderSideButtons()}
        {renderNotificationBar()}
        <div className="h-[552px] w-[272px] overflow-hidden rounded-b-2xl">
          <div className='size-full'>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneOutline;
