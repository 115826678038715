import localStorage from "redux-persist/es/storage";

export default class LocalStorage {
  /**
   *
   * @param key
   */
  public async retrieveData<T>(key: string, parse = false): Promise<T | undefined | string> {

    // TODO may need to add parameter to allow parsing objects
    const value = await localStorage.getItem(key);

    if (value !== null) {
      return parse ? (JSON.parse(value) as T) : value;
    }

    return undefined;

  }

  /**
   *
   * @param key
   * @param value
   */
  public async storeData<T>(key: string, value: T): Promise<void> {
    let storeValue = value;

    if (typeof value !== 'string') {
      // @ts-expect-error fallback to string
      storeValue = JSON.stringify(value);
    }

    await localStorage.setItem(key, storeValue as unknown as string);
  }

  public async storeMultiData(keyPairValue: [string, string][]): Promise<void> {
    for (const [key, value] of keyPairValue) {
      await localStorage.setItem(key, value);
    }
  }

  public async retrieveMultiData(keyPairValue: string[], parse = false): Promise<any[]> {
    const values: any[] = [];

    try {
      const pairs: string[][] = [];
      for (const kp of keyPairValue) {
        const value = await localStorage.getItem(kp);
        value && pairs.push([kp, value]);
      }

      if (values.length && parse) {
        pairs.forEach((pair: any[]) => {
          values.push(typeof pair[1] === 'string' ? JSON.parse(pair[1]) : pair[1]);
        });
      } else {
        values.push(...pairs.map((pair: any[]) => pair[1]));
      }
    } catch (error) {
      console.error(error, 'localStorageManager', 'retrieveMultiData');
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return values;
    }
  }

  public async removeMultiple(keyPairValue: any[]): Promise<void> {
    for (const kp of keyPairValue) {
      await localStorage.removeItem(kp);
    }
  }

  public async removeKey(keyPairValue: string): Promise<void> {
    await localStorage.removeItem(keyPairValue);
  }
}


