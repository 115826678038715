import './layout-login.css';
import { ReactNode } from 'react';

interface LayoutProps {
  imgComponent: ReactNode;
  formComponent: ReactNode;
}

const LayoutComponent = ({ imgComponent, formComponent }: LayoutProps) => {
  return (
    <div className="flex h-screen flex-col bg-white lg:flex-row">

      <div className="relative hidden w-full lg:block lg:w-1/2">
        {imgComponent}
      </div>

      <div className="w-full lg:w-1/2">
        {formComponent}
      </div>

    </div>
  );
};

export default LayoutComponent;