import {invisiblyApi} from "../api/invisibly-api";
import {SurveyMediaPlan, SurveyMediaPlanJSON} from "./media-plan.types";
import {InvisiblyApiResponse} from "../../services/api/api-response.types";
import SurveyService from "../../services/surveys/survey.service";
import {surveyEndpoints} from "./survey.endpoints";

const mediaPlanEndpoints = invisiblyApi.injectEndpoints({
  endpoints: (builder) => ({
    createMediaPlan: builder.mutation<SurveyMediaPlan, { ext_survey_id: string }>({
      query: ({ ext_survey_id }) => ({
        data: {ext_survey_id},
        method: 'post',
        url: 'b2b/media-plan',
      }),
      transformResponse: (response: InvisiblyApiResponse<SurveyMediaPlanJSON>) => {
        return SurveyService.parseMediaPlan(response.item);
      },
      async onQueryStarted({ ext_survey_id }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (!data) {
            return;
          }

          dispatch(surveyEndpoints.util.updateQueryData('getSurvey', { ext_survey_id }, (draft) => {
            const patch = draft;
            draft.extMediaPlanId = data.extMediaPlanId;
            Object.assign(patch, draft);
          }));
        } catch (error) {
          console.error('Error creating media plan', error);
        }
      },
    }),
    getMediaPlan: builder.query<SurveyMediaPlan, { ext_survey_id: string }>({
      query: ({ ext_survey_id }) => ({
        method: 'get',
        params: { use_ext_survey_id: true },
        url: `b2b/media-plan/${ext_survey_id}`,
        handleErrorGlobally: false,
      }),
      transformResponse: (response: InvisiblyApiResponse<SurveyMediaPlanJSON>) => {
        return SurveyService.parseMediaPlan(response.item);
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useCreateMediaPlanMutation, useGetMediaPlanQuery,
} = mediaPlanEndpoints;
