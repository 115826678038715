import { useDispatch } from "react-redux";
import { showNotification } from "../redux/notification/notification.slice";

interface UseCopyReturn {
  toClipboard: (successMessage: string, errorMessage: string) => void;
}

export const useCopy = (): UseCopyReturn => {
  const dispatch = useDispatch();

  const toClipboard = async(copyText: string, successMessage?: string, errorMessage: string = 'Unable to copy text. Please try again.') => {
    try {
      await navigator.clipboard.writeText(copyText);

      successMessage && dispatch(showNotification({ summary: successMessage, theme: 'success' }));
    } catch (err) {
      // Not convinced we want to log errors here but we need a try/catch because there's a bug when hot reloading.
      // https://medium.com/nerd-for-tech/navigator-clipboard-api-b96be187df5d
      errorMessage && dispatch(showNotification({ summary: errorMessage, theme: 'warning' }));
    }
  };

  return { toClipboard };
};

